import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "ROOT/src/store";
import * as Sentry from "@sentry/browser";

class storeAction {
  dataGet(arg0) {
      throw new Error("Method not implemented.");
  }
  error(arg0) {
      throw new Error("Method not implemented.");
  }
  dsClear() {
      throw new Error("Method not implemented.");
  }
  dsAdd(arg0, arg1, arg2) {
      throw new Error("Method not implemented.");
  }
  dsProcess() {
      throw new Error("Method not implemented.");
  }
  constructor(store) {
    this.store = store;
    this.customClassPole = {};
    this.customClass = null;
    this.actModule = null;
    this.actView = null;
    this.fields = {};
    this.listFields = {};
    this.app_strings = {};
    this.moduleTranslate = {};
    this.fieldsForValidation = new Array();
    this.doRoute = true;
    this.userPreferences = {};
    // LISTVIEW
    this.resizeStart = null;
    this.resizeWidthStart = null;
    this.activeResizeRow = false;
    this.resizeRow = null;
    this.tableWidth = null;
    this.detailPredefinedFields = null;
    //

    var self = this;
    document.onkeydown = (e) => {
      this.baseOnKeyDown(e);
    };
    window.onbeforeunload = function () {
      if (self.needSave()) {
        return "Do you really want to leave our brilliant application?";
      } else {
        return;
      }
    };

    // ___________________________________________________ .closest for IE
    if (!Element.prototype.matches)
      Element.prototype.matches = Element.prototype.msMatchesSelector;
    if (!Element.prototype.closest)
      Element.prototype.closest = function (selector) {
        var el = this;
        while (el) {
          if (el.matches(selector)) {
            return el;
          }
          el = el.parentElement;
        }
      };
      this.rest = undefined;
  }
}
import * as d from "./exportConfig";
const arrName = Object.keys(d);
const arr = arrName.map((k) => d[k]);
arr.forEach(function (f, k) {
  storeAction.prototype[arrName[k]] = f;
});

var sAction = new storeAction(store);

import * as c from "../_custom/exportConfig";
const customArrName = Object.keys(c);
const customArr = customArrName.map((k) => c[k]);
customArr.forEach(function (f, k) {
  sAction.customClassPole[customArrName[k]] = f;
});

import * as e from "../components/detailViewFields/exportConfig";
const fieldsArrName = Object.keys(e);
const fieldsArr = fieldsArrName.map((k) => e[k]);
fieldsArr.forEach(function (f, k) {
  sAction.fields[fieldsArrName[k]] = f;
});

import * as l from "../components/listEditableFields/exportConfig";
const listFieldsArrName = Object.keys(l);
const listFieldsArr = listFieldsArrName.map((k) => l[k]);
listFieldsArr.forEach(function (f, k) {
  sAction.listFields[listFieldsArrName[k]] = f;
});

class appConstructor {
  constructor(data) {
    data.renderReact = function () {
      if (sAction.param.sentryEnabled) {
        Sentry.init({ 
          dsn: sAction.param.sentryUrl,
          release: RELEASE_BUILD
        });
      }

      const App = sAction.appComponent;
      ReactDOM.render(
        <Provider store={store}>
          <App />
        </Provider>,
        document.querySelector(data.container)
      );
    };
    sAction.init(data);
  }
}
window.CORIPO = appConstructor;
if (window.Cypress || sAction.getStorage("debug")) {
  window.sAction = sAction;
}
export default sAction;
