export default function reportSelectMainModule(module) {
  var self = this;
  this.load();
  this.rest.get("defs/" + module, (defsData) => {

    var fields = {
      fields: [],
      relate: [],
    };
    let dynEnums = []

    let dsToProcess = [
      {
        method: "set",
        way: "reportWindow/columns",
        value: {},
        like: "reportWindowColumns",
      }
    ];

    defsData.forEachObject((field, key) => {
      if (field != null) {
        if (field.type == "relate") {
          if (field.searchable == true && field.name !== "currency_name") {
            fields.relate.push(field);
          }
        } else if (this.checkValidColumn(field)) {
          if (field && field.type == "AcmDynamicEnum") {
            dynEnums.push(field)
          }
          
          fields.fields.push(field);
        }
      }

      const k = key.toLowerCase();
      if (field && this.checkValidColumn(field)) {
        field["show"] = true;
        field["fieldModule"] = module;
        if (field["default"]) {
          dsToProcess.push({
            method: "add",
            way: "reportWindow/columns/active",
            value: { name: k, def: field },
            like: "reportWindowColumn",
          })
        } else {
          dsToProcess.push({
            method: "add",
            way: "reportWindow/columns/hidden",
            value: { name: k, def: field },
            like: "reportWindowColumn",
          })
        }
      }     
    });
    
    if(dynEnums.length > 0) {
      // get enum values / options
      this.rest.post('getDynEnumOptions', { fields: dynEnums }, returnData => {

        if (returnData.status) {
          returnData.message.data.forEachObject(dynFields => {
            let options = [];
            dynFields.options.forEachObject(option => {
              let label = JSON.parse(option.language_json)?.cs_CZ
              options.push({
                value: option.name,
                label: label
              });
            })
            let field = dynFields.field
            field.options = options

            let objIndex = fields.fields.findIndex((obj => obj.name === dynFields.name));
            fields.fields[objIndex].options = options

          })
        }

        saveFields(self, fields, dsToProcess);
        this.dsClear();
      }, false)

    } else {
      saveFields(self, fields, dsToProcess);
      this.dsClear();
    }
  });

  this.rest.get(
    "search/" + module,
    (data) => {
      this.dsClear();
      this.dsAdd("set", "reportWindow/savedSearch", []);
      data.data.forEach((search) => {
        if (search.category == "report") {
          this.dsAdd("add", "reportWindow/savedSearch", search);
        }
      });
      this.dsProcess();
    },
    false
  );

  function saveFields(self, fields, dsToProcess) {
    self.dsClear();

    dsToProcess.forEach(ds => {
      self.dsAdd(ds.method, ds.way, ds.value, ds.like);
    });

    self.dsAdd("set", "reportWindow/metadata", {}, "reportWindowMetadata");
    self.dsAdd("set", "reportWindow/module", module);
    self.dsAdd("set", "reportWindow/fields", fields.fields);
    self.dsAdd("set", "reportWindow/relate", fields.relate);
    self.dsAdd("set", "reportWindow/group", {}, "reportWindowGroup");
    self.dsAdd("set", "conf/load", false);
    self.dsAdd("set", "reportWindow/activeRelate", [
      { main: true, module: module, selected: true },
    ]);

    self.dsProcess();
  }

}
