import React from "react";

import Enum from "../Enum/Enum";
import MultiSelect from "../../formElements/MultiSelect";
import  sAction  from "sAction";

export default class Multienum extends Enum {
  multiEnumSave(data) {
    var values = "";
    if(data != null) {
      data = data.filter((el) => {
        return el !== '' && el !== null && el !== undefined;
      });
      values = data.join("^,^");
      values = values ? "^" + values + "^" : '';
    }
    this.save({ target: { value: values } });
  }
  multiOnKeyDown(e,value){
    const target = e.target
    var parent = target.closest(".acmMultiSelectSearchPanel");
    if(parent != null){
      this.onKeyDown(e,"last",false);
      if(e.keyCode === 9 &&e.shiftKey === false){
        this.multiEnumSave(value);
      }
    }
    if(target.classList.contains("acmMultiSelect")){
      this.onKeyDown(e,"first",false);
      if(e.shiftKey && e.keyCode === 9){
        this.multiEnumSave(value);
      }
    }
  }
  render() {
    const data = this.props.data;
    const relFilterPrefix = data.def.get("relFilterPrefix");
    const options = sAction.app_strings[data.def.get("options")];
    
    var optionsToSelect = [];
    for (var key in options) {
      if (options.hasOwnProperty(key)) {
        if(!relFilterPrefix || (relFilterPrefix && key.split('_%')[0] === relFilterPrefix)){
          var value = options[key];
          optionsToSelect.push({ value: key, label: value });
        }
      }
    }

    return (
      <MultiSelect
        ref="input"
        id={data.name}
        options={optionsToSelect}
        defaultValue={data.value}
        open={data.actEdit}
        valueFromProps={true}
     //   onChange={values => this.multiEnumSave(values)}
        onBlur={values => this.multiEnumSave(values)}
        onKeyDown={(e,value) => this.multiOnKeyDown(e,value)}
        className="detailViewMulti"
        readonly={this.props.readonly}
      />
    );
  }
}
