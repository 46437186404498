import { List } from "immutable";
import { DataConstructors } from "../../store/DataConstructors";

export default function prepareFilterStructure(filter) {
  let groups = [];

  filter.forEach((data, index) => {
    let filterList = [];

    if (data.filterFormat === "filter") {
      groups.push(data);
    } else {
      data.filters.forEach((filterRecord, index) => {
        filterList.push(new DataConstructors["filterParameter"](filterRecord));
      });

      data.filters = List(filterList);
      groups.push(new DataConstructors["filterGroup"](data));
    }
  });

  return List(groups);
}
