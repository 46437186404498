import React from "react";
import PureComponent from "../../pure";

import sAction from 'sAction';

import CustomLine from "./CustomLine";
import Button from "../../formElements/Button";
import CustomLinesHeader from "./CustomLinesHeader";
import CustomLinesTotal from "./CustomLinesTotal";

const { Map, List } = require('immutable');

export default class CustomDetailLines extends PureComponent {
    constructor(props) {
        super(props);

        this.state = { index: 0, customLines: [], defaultDiscount: null };
        this.keyArray = {};
    }

    addCustomLine(def, moduleData) {
        var defaultData = {};
        def.get('fields').toJS().forEachObject((field) => {
            if (def.get('default') && def.get('default').get(field)) {
                defaultData[field] = def.get('default').get(field);
            } else if (moduleData.get(field) && moduleData.get(field).get && moduleData.get(field).get('default')) {
                defaultData[field] = moduleData.get(field).get('default')
            }
        });
        def.get('default').toJS().forEachObject((value, column) => {
            defaultData[column] = value;
        });
        sAction.dsClear();
        sAction.dsAdd("set", this.props.way + '/customData/customLines/lines/' + this.state.index, defaultData);
        sAction.dsAdd("set", this.props.way + '/changes/forceChange', true);
        sAction.dsProcess();
        this.setState({ index: ++this.state.index, });
    }

    getTotal(moduleData, way, lines) {
        return (
            <CustomLinesTotal
                def={this.props.data.get('def')}
                way={way}
                lines={lines}
                moduleData={moduleData}
                info={this.props.data.get('info')}
            />
        );
    }

    moveElement(key, direction) {
        let way = this.props.way + '/customData/customLines/lines';

        let list = sAction.dataGet(way);
        const cnt = list.size;

        sAction.dsClear();

        if (direction === 'up') {
            if (key === 0 || key === '0') {
                return;
            }

            let pomProd = list.get(this.keyArray[key]);
            list = list.delete(this.keyArray[key]);
            list = list.insert(this.keyArray[key - 1], pomProd);

            sAction.dsAdd("set", way, list);
        } else if (direction === 'down') {
            if ((key + 1) >= cnt) {
                return;
            }

            let pomProd = list.get(this.keyArray[key]);
            list = list.delete(this.keyArray[key]);
            list = list.insert(this.keyArray[key + 1], pomProd);

            sAction.dsAdd("set", way, list);
        }

        sAction.dsAdd("set", "view/changes/forceChange", true);
        sAction.dsProcess();
    }

    componentDidUpdate() {
        this.getDefDiscData();
    }

    componentDidMount() {
        this.getDefDiscData();
    }

    getDefDiscData() {
        let accountId = sAction.dataGet("view/fields/acm_orders_accountsaccounts_ida/value");
        if(!accountId) accountId = sAction.dataGet("view/fields/acm_orders_accounts_name/def/id_value");

        const searchData = {
            module: 'Accounts',
            fields: ['id'],
            getFields: ['discount_e', 'discount_f'],
            value: accountId,
            limit: 1,
        };

        sAction.quickSearch(searchData, returnData => {
            if (this.state.defaultDiscount?.discount_e !== returnData[0].discount_e
                || this.state.defaultDiscount?.discount_f !== returnData[0].discount_f) {
                this.setState({ defaultDiscount: returnData[0] })
            }
        });

        setTimeout(() => { // repeat to save changes
            sAction.dsClear();
            let changes = 0;

            let lines = sAction.dataGet("view/customData/customLines/lines");
            lines.forEach((line, i) => {
                if (!line.get("cena_bez_dph_kus_defdisc") || line.get("cena_bez_dph_kus_defdisc") == "") {
                    let asCompany = sAction.dataGet("view/fields/for_company/value")
                    let defDiscount = asCompany === "eleman" ? this.state.defaultDiscount?.discount_e : this.state.defaultDiscount?.discount_f;
                    defDiscount = !defDiscount ? 0 : defDiscount
                    defDiscount = (100 - defDiscount) / 100; // vypocet defaultni slevy z firmy a vybrané za firmu

                    let calcVal = line.get("cena_bez_dph_kus")
                    if (line.get("cena_bez_dph_kus") * defDiscount) calcVal = (line.get("cena_bez_dph_kus") * defDiscount).toFixed(2);
                    sAction.dsAdd("set", `view/customData/customLines/lines/${i}/cena_bez_dph_kus_defdisc`, calcVal)
                    changes++;
                }
            });
            if (changes > 0) sAction.dsAdd("set", this.props.way + '/changes/forceChange', true);

            sAction.dsProcess();
        }, 2000);

    }

    processDataCustom(lines) {
        let returnData = [];
        if(!lines || lines.size <= 0) return new List(returnData);

        lines.forEach(data => {
            data = data.toJS();

            if ((!data?.cena_bez_dph_kus_defdisc    // updatovat jenom kdyz neni vyplneno
                || data.cena_bez_dph_kus_defdisc == "0.00"
                || data.cena_bez_dph_kus_defdisc == "")
                && data?.cena_bez_dph_kus !== null) {  // custom data process if field exists

                let asCompany = sAction.dataGet("view/fields/for_company/value")
                let defDiscount = asCompany === "eleman" ? this.state.defaultDiscount?.discount_e : this.state.defaultDiscount?.discount_f;
                console.log(">> processDataCustom defDiscount", defDiscount, asCompany)

                defDiscount = !defDiscount ? 0 : defDiscount
                defDiscount = (100 - defDiscount) / 100; // vypocet defaultni slevy z firmy a vybrané za firmu

                if (data.cena_bez_dph_kus * defDiscount) data.cena_bez_dph_kus_defdisc = (data.cena_bez_dph_kus * defDiscount).toFixed(2);
                // console.log(data.cena_bez_dph_kus_defdisc)
            }
            returnData.push(new Map(data));
        });
        return new List(returnData);
    }


    render() {
        const info = this.props.data.get('info');
        const way = this.props.way;
        let lines = this.props.data.get('lines');
        lines = this.processDataCustom(lines);
        const def = this.props.data.get('def');
        const acl = this.props.acl;
        const moduleData = this.props.data.get('moduleData');
        const timeline = this.props.data.get('timeline');
        var index = 0;
        var customLines = [];
        if (lines) {
            lines.forEach((line, key) => {
                if (line.get && line.get('deleted') !== 1 && line.get('deleted') !== '1') {
                    this.keyArray[index] = key;
                    customLines.push(
                        <CustomLine
                            data={line}
                            def={def}
                            key={index}
                            lineKey={index}
                            moduleData={moduleData}
                            showError={this.props.data.get('showError')}
                            way={way}
                            canEdit={acl.get('edit')}
                            timeline={timeline}
                            info={info}
                            moveElement={(key, direction) => { this.moveElement(key, direction) }}
                        />
                    );
                }
                index++;
            });
        }
        this.state.index = index;

        // if no data and no def, then return empty fragment -> probably rightPanel 
        if(!info && !def) {
            return (<></>);
        }

        var totalLine = null;
        if (def && def.get('total') && def.get('total').toJS().length > 0) {
            totalLine = this.getTotal(moduleData, way, lines);
        }

        let headerPom = (
            <CustomLinesHeader
                def={def}
                moduleData={moduleData}
                info={info}
            />
        );

        return (
            <div className='detailCard detailViewTabContent detailQuoteProdComponent'>
                <div className='subpanelsContainer' data-way={this.props.way + '/customData/customLines'}>
                    <div className="qGroupHeaderContainer header">
                        <div className="title">
                            <span className={"detailViewHeaderIcon icon-" + info.get('module')} />
                            <span className="detailViewName">
                                {sAction.translate('LBL_MODULE_NAME', info.get('module'))}
                            </span>
                        </div>
                    </div>
                    <div className="detailView">
                        <div className="detailViewTabContent customLines">
                            {headerPom}
                            {customLines}
                            {totalLine}
                            {acl.get('edit') && (
                                <div className='customLinesRowContainer flexRight viewActionPanelButtons'>
                                    <Button
                                        className="acmButton"
                                        onClick={e => this.addCustomLine(def, moduleData)}
                                    >
                                        <div className={"actionPanelButtonIcon icon-addIcon"} />
                                        {sAction.translate('LBL_ADD_CUSTOM_LINE')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
