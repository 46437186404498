import React from "react";
import PureComponent from "../../pure";
import AdminIframe from "../../admin/AdminIframe";
import sAction from "sAction";

export default class AccountsTurnover extends PureComponent {
  // _isAdmin = sAction.dataGet("conf/user/is_admin") == 1;

  setIframeHeight = () => {
    const iframe = document.getElementsByTagName("iframe")[0];
    if (!iframe) {
      return;
    }

    if (iframe.contentWindow) {
      let height;
      // try {
      //   height = iframe.contentWindow.document.body.scrollHeight;
      // } catch (e) {
      //   height = 0;
      // }

      height = 950; // fixed height se in plotly chart

      document.getElementById("reportContainer").style.height = height + "px";
    }
  };

  componentDidMount() {
    // if (this._isAdmin) {
      if (this.props.data.get("html")) {
        setTimeout(this.setIframeHeight, 250);
        setTimeout(this.setIframeHeight, 1500);
        setTimeout(this.setIframeHeight, 3000);
        setTimeout(this.setIframeHeight, 8000);
      }
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    this.componentDidMount();
  }

  render() {
    let { way, data, isInTab } = this.props;

    // if (!this._isAdmin) {
    //   return "";
    // }

    data = data.set("params", { recordId: data.get("recordId") });
    let render = <div id="reportContainer" className="detailIframeContainer">
      <AdminIframe way={way.replace("/customData","")} name={data.get("iframeName")} data={data} />
    </div>;

    if (!isInTab) {
      render = <div className='detailCard OppRowsContainer'>
        <div className='header'>
          <div className='title'>
            <div className='detailViewName'>
              {sAction.translate('LBL_UNAVAILABLE_OUTSIDE_TAB', 'Accounts')}
            </div>
          </div>
        </div>
        {/*<div style={{ padding: 25 }}>*/}
        {/*  {render}*/}
        {/*</div>*/}
      </div>;
    }

    return render;
  }
}
