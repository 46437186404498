
export default function acmExportPohoda(data) {
    this.load();
    this.rest.post("acmExportPohoda", data, resp => {
        this.unLoad();

        if (resp.status){
            this.alert(resp.message.text)
        }
        else{
            this.error(resp.errorMessage.text);
        }
    });
}
