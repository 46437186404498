import detailDefault from "../../detailDefault";
import React from "react";

import moment from "moment";

export default class detailAccounts extends detailDefault {
    load(sAction, data) {
        this.updateDetailApiVisibility(sAction, data);

    let sa_company = sAction.dataGet(data.prefix +"/fields/as_company/value") 
    sAction.dsClear();
    sAction.dsAdd("set", data.prefix+"/fields/seeky_activity/def/type", "HTMLText");
    sAction.dsAdd("set", data.prefix+"/fields/seeky_activity/def/readonly", true);

    if (sa_company == "^famatel^"){
      sAction.dsAdd("set", data.prefix + "/customData/dashboardVersion", "famatel");
    } else {
      sAction.dsAdd("set", data.prefix + "/customData/dashboardVersion", "eleman");
    }

    sAction.dsProcess();
    this.setMultiEnum(sAction, data);

    // throw new Error("Stop error...");
  }

    update(sAction, data) {
        switch (data.field) {
            case "billing_address_country":
                this.updateDetailApiVisibility(sAction, data)
                break;
            case "same_as_billing_adr":
                this.syncBillingAddress(sAction, data)
                this.checkIfCanGetAddress(sAction, data);
                break;
            case "billing_address_street":
            case "billing_address_city":
            case "billing_address_country":
            case "billing_address_state":
            case "billing_address_postalcode":
            case "billing_address_kraj":
            case "billing_address_okres":
                sAction.dsClear();
                sAction.dsAdd("set", data.prefix + "/fields/same_as_billing_adr/value", "0");
                sAction.dsAdd("set", data.prefix + "/changes/fields/same_as_billing_adr", "0");
                sAction.dsProcess();
                break;
            case "shipping_address_street":
            case "shipping_address_city":
            case "shipping_address_country":
            case "shipping_address_state":
            case "shipping_address_postalcode":
            case "shipping_address_kraj":
            case "shipping_address_okres":
                sAction.dsClear();
                sAction.dsAdd("set", data.prefix + "/fields/same_as_billing_adr/value", "0");
                sAction.dsAdd("set", data.prefix + "/changes/fields/same_as_billing_adr", "0");
                sAction.dsProcess();
                this.checkIfCanGetAddress(sAction, data);
                break;
            case "relation_enum":
                this.setMultiEnum(sAction, data);
                break;
            case "frequency_value":
            case "relation_rating":
            case "datelastcall_c":
            case "datelastmeeting_c":
                this.setSemaforColor(sAction, data);
            // zruseno v #17588 - relation_rating se nepouziva
            // case "frequency_value":
            //     this.syncFrequencyRating(sAction, data);
            // case "relation_rating":
            //     this.syncFrequencyRating(sAction, data);

                    // Na Elemanu neni zatim zadouci
            // case 'acm_address':
            //     this.getNearAccounts(sAction, data);
            //     break;
        }

        // this.addressesHelper(sAction, data, data)
    }


    // syncFrequencyRating(sAction, data) {
    //     let conversion = {
    //         "1": "30",
    //         "2": "45",
    //         "3": "60",
    //         "4": "90",
    //         "5": "180",
    //     }
    //     switch(data.field) {
    //         case "frequency_value":
    //             if (this.getKeyByValue(conversion, data.value)){
    //                 sAction.dsClear();
    //                 sAction.dsAdd("set", data.prefix + "/fields/relation_rating/value", this.getKeyByValue(conversion, data.value));
    //                 sAction.dsAdd("set", data.prefix + "/changes/fields/relation_rating", this.getKeyByValue(conversion, data.value));
    //                 sAction.dsProcess();
    //             }
    //             break;
    //         case "relation_rating":
    //             if (conversion[data.value]){
    //                 sAction.dsClear();
    //                 sAction.dsAdd("set", data.prefix + "/fields/frequency_value/value", conversion[data.value]);
    //                 sAction.dsAdd("set", data.prefix + "/changes/fields/frequency_value", conversion[data.value]);
    //                 sAction.dsProcess();
    //             }
    //             break;
    //     }
    // }

    setSemaforColor(sAction, data) {
        const color = this.decideColor(sAction, data);
        sAction.dsClear();
            sAction.dsAdd("set", data.prefix + "/fields/frequency_color/value", color);
            sAction.dsAdd("set", data.prefix + "/changes/fields/frequency_color", color);
        sAction.dsProcess();
    }


    decideColor(sAction, data) {
        //TODO lepsi by bylo o vyhodnoceni volat na BE, aby se rozhodovalo na stejnem miste jako pro cron a LH
        if (sAction.dataGet(data.prefix + "/fields/relation_enum/value") === 'inactive') {
            return 'black';
        }
        const lastCall = moment(sAction.dataGet(data.prefix + '/fields/datelastcall_c/value'));
        const lastMeeting = moment(sAction.dataGet(data.prefix + '/fields/datelastmeeting_c/value'));
        const today = moment();

        const diffCall = today.diff(lastCall, "days");
        const diffMeeting = today.diff(lastMeeting, "days");

        const frequency = parseInt(sAction.dataGet(data.prefix + "/fields/frequency_value/value"));

        // if (frequency === 0 || (!diffCall && !diffMeeting)) {
        if (frequency === 0) {
            return 'blue';
        }
        return (diffCall < frequency || diffMeeting < frequency)?'green':'red';
    }

    syncBillingAddress(sAction, data) {
        let same_as_billing_adr = sAction.dataGet(data.prefix + "/fields/same_as_billing_adr/value")
        if (same_as_billing_adr === "1" || same_as_billing_adr === 1) {
            sAction.dsClear();
            sAction.dsAdd("set", data.prefix + "/fields/shipping_address_street/value", sAction.dataGet(data.prefix + "/fields/billing_address_street/value"));
            sAction.dsAdd("set", data.prefix + "/fields/shipping_address_city/value", sAction.dataGet(data.prefix + "/fields/billing_address_city/value"));
            sAction.dsAdd("set", data.prefix + "/fields/shipping_address_country/value", sAction.dataGet(data.prefix + "/fields/billing_address_country/value"));
            sAction.dsAdd("set", data.prefix + "/fields/shipping_address_state/value", sAction.dataGet(data.prefix + "/fields/billing_address_state/value"));
            sAction.dsAdd("set", data.prefix + "/fields/shipping_address_postalcode/value", sAction.dataGet(data.prefix + "/fields/billing_address_postalcode/value"));
            sAction.dsAdd("set", data.prefix + "/fields/shipping_address_kraj/value", sAction.dataGet(data.prefix + "/fields/billing_address_kraj/value"));
            sAction.dsAdd("set", data.prefix + "/fields/shipping_address_okres/value", sAction.dataGet(data.prefix + "/fields/billing_address_okres/value"));

            sAction.dsAdd("set", data.prefix + "/changes/fields/shipping_address_street", sAction.dataGet(data.prefix + "/fields/billing_address_street/value"));
            sAction.dsAdd("set", data.prefix + "/changes/fields/shipping_address_city", sAction.dataGet(data.prefix + "/fields/billing_address_city/value"));
            sAction.dsAdd("set", data.prefix + "/changes/fields/shipping_address_country", sAction.dataGet(data.prefix + "/fields/billing_address_country/value"));
            sAction.dsAdd("set", data.prefix + "/changes/fields/shipping_address_state", sAction.dataGet(data.prefix + "/fields/billing_address_state/value"));
            sAction.dsAdd("set", data.prefix + "/changes/fields/shipping_address_postalcode", sAction.dataGet(data.prefix + "/fields/billing_address_postalcode/value"));
            sAction.dsAdd("set", data.prefix + "/changes/fields/shipping_address_kraj", sAction.dataGet(data.prefix + "/fields/billing_address_kraj/value"));
            sAction.dsAdd("set", data.prefix + "/changes/fields/shipping_address_okres", sAction.dataGet(data.prefix + "/fields/billing_address_okres/value"));
            sAction.dsProcess();
        }
    }

    addressesHelper(sAction, data, field){
        // const street = sAction.dataGet(data.prefix + "/fields/billing_address_street/value");
        // const city = sAction.dataGet(data.prefix + "/fields/billing_address_city/value");


        if (field.field === 'acm_address') {
            sAction.dsClear();

            const acm_address_street = sAction.dataGet(data.prefix + "/fields/acm_address/def/item/street");
            const acm_address_city = sAction.dataGet(data.prefix + "/fields/acm_address/def/item/obec");
            const acm_address_psc = sAction.dataGet(data.prefix + "/fields/acm_address/def/item/psc");
            const acm_address_kraj = sAction.dataGet(data.prefix + "/fields/acm_address/def/item/kraj");
            const acm_address_okres = sAction.dataGet(data.prefix + "/fields/acm_address/def/item/okres");

            if (acm_address_street){
                sAction.dsAdd("set", data.prefix + "/fields/billing_address_street/value", acm_address_street);
                sAction.dsAdd("set", data.prefix + "/changes/fields/billing_address_street", acm_address_street);
            }
            if (acm_address_city){
                sAction.dsAdd("set", data.prefix + "/fields/billing_address_city/value", acm_address_city);
                sAction.dsAdd("set", data.prefix + "/changes/fields/billing_address_city", acm_address_city);
            }
            if (acm_address_psc){
                sAction.dsAdd("set", data.prefix + "/fields/billing_address_postalcode/value", acm_address_psc);
                sAction.dsAdd("set", data.prefix + "/changes/fields/billing_address_postalcode", acm_address_psc);
            }
            if (acm_address_kraj){
                sAction.dsAdd("set", data.prefix + "/fields/billing_address_kraj/value", acm_address_kraj);
                sAction.dsAdd("set", data.prefix + "/changes/fields/billing_address_kraj", acm_address_kraj);
            }
            if (acm_address_okres){
                sAction.dsAdd("set", data.prefix + "/fields/billing_address_state/value", acm_address_okres);
                sAction.dsAdd("set", data.prefix + "/changes/fields/billing_address_state", acm_address_okres);
            }

            sAction.dsProcess();
        }
    }

    getNearAccounts(sAction, data) {
        let item = sAction.dataGet(data.prefix + '/fields/acm_address/def/item');
        let id = sAction.dataGet(data.prefix + '/id');
        const address = {
            x: item.get('gpsX'),
            y: item.get('gpsY'),
            cast_obce: item.get('cast_obce'),
            kraj: item.get('kraj'),
            obec: item.get('obec'),
            okres: item.get('okres'),
            psc: item.get('psc'),
            id: id,
        };

        sAction.rest.post('getNearAddresses', address, (returnData) => {
            if (returnData.status) {
                if (returnData.message.data.length > 0) {
                    let message = sAction.translate('LBL_NEAR_ACCOUNT_FOUND', 'Accounts');
                    let accounts = [];
                    returnData.message.data.forEachObject((value) => {
                        accounts.push(
                            (
                                <div style={{margin: 'auto'}}>
                                    <a href={'#detail/Accounts/' + value.id} target='_blank'>{value.name} | IČ: {value.ticker_symbol}</a>
                                </div>
                            )
                        );
                    });
                    message = (
                        <div style={{margin: 'auto'}}>
                            <div>
                                {message}
                            </div>
                            <br />
                            {accounts}
                        </div>
                    );
                    const data = {
                        // Potvrzení
                        header: sAction.translate("LBL_CONFIRM"),
                        buttons: [
                            // Pokračovat
                            { label: sAction.translate("LBL_CONTINUE", "Home"), callback: () => sAction.popupHide() },
                        ],
                    };

                    sAction.popup(message, data);
                }
            } else {
                sAction.error(sAction.translate(returnData.errorMessage.text));
            }
        });
    }

    //fce skryva/odkryva detail fieldy podle zeme, jestli je zeme CZECH REPUBLIC
    updateDetailApiVisibility(sAction, data) {
        let address;
        if (data.value) {
            address = data.value;
        } else {
            address = sAction.dataGet(data.prefix + "/fields/billing_address_country/value");
        }

        const updateFieldApiValue = (fieldName, fieldValue) => {
            if (sAction.dataGet(data.prefix + "/fields/"+fieldName) !== undefined) {
                sAction.dsAdd("set", data.prefix + "/fields/"+fieldName+"/def/showDetailApi", fieldValue);
            }
        }

        sAction.dsClear();
        if (!address || address === "CZECH REPUBLIC") {
            //zobrazi ikony
            updateFieldApiValue("name", "ApiName");
            updateFieldApiValue("account_name", "ApiName");
            updateFieldApiValue("ticker_symbol", "ApiICO");
            updateFieldApiValue("sic_code", "ApiDIC");
            updateFieldApiValue("billing_address_street", "ApiStreet");
            updateFieldApiValue("shipping_address_street", "ApiStreet");
            updateFieldApiValue("billing_address_postalcode", "ApiPSC");
            updateFieldApiValue("shipping_address_postalcode", "ApiPSC");
        }
        else if(address === "SLOVAKIA"){
            //skryje ikony
            updateFieldApiValue("name", "ApiNameSK");
            updateFieldApiValue("account_name", "ApiNameSK");
            updateFieldApiValue("ticker_symbol", "ApiICOSlovakia");
            updateFieldApiValue("sic_code", "");
            updateFieldApiValue("billing_address_street", "ApiStreet");
            updateFieldApiValue("shipping_address_street", "ApiStreet");
            updateFieldApiValue("billing_address_postalcode", "ApiPSC");
            updateFieldApiValue("shipping_address_postalcode", "ApiPSC");
        }
        else{
            //skryje ikony
            updateFieldApiValue("name", "ApiNameOthers");
            updateFieldApiValue("account_name", "");
            updateFieldApiValue("ticker_symbol", "");
            updateFieldApiValue("sic_code", "");
            updateFieldApiValue("billing_address_street", "");
            updateFieldApiValue("shipping_address_street", "");
            updateFieldApiValue("billing_address_postalcode", "");
            updateFieldApiValue("shipping_address_postalcode", "");
        }

        //Skryvani/odkryvani tabu Finstat kdyz je nebo neni zeme Slovensko
        const tabs = sAction.dataGet(data.prefix + "/tabs");
        if (tabs) {
            const finstatIndex = tabs.findIndex(i => i.name === "LBL_DETAILVIEW_PANEL_FINSTAT");
            if (finstatIndex !== -1) {
                if (address !== "SLOVAKIA") {
                    sAction.dsAdd("set", data.prefix + "/tabs/" + finstatIndex + "/hidden", true);
                } else {
                    sAction.dsAdd("set", data.prefix + "/tabs/" + finstatIndex + "/hidden", false);
                }
            }
        }
        sAction.dsProcess();
    }

    checkIfCanGetAddress(sAction, data) {
        if(
            (sAction.dataGet(data.prefix + "/fields/shipping_address_country/value") === "CZECH REPUBLIC" || 
                sAction.dataGet(data.prefix + "/fields/shipping_address_country/value") === "" ) &&
            sAction.dataGet(data.prefix + "/fields/shipping_address_street/value") !== "" &&
            sAction.dataGet(data.prefix + "/fields/shipping_address_postalcode/value") !== "" &&
            sAction.dataGet(data.prefix + "/fields/shipping_address_city/value") !== "" &&
            sAction.dataGet(data.prefix + "/fields/shipping_address_state/value") !== ""
        ) {
            let acmAddressGpsx = sAction.dataGet(data.prefix + "/fields/acm_address_gpsx/value");
            let acmAddressItemGpsx = sAction.dataGet(data.prefix + "/fields/acm_address/def/item/gpsX");
            if (!acmAddressGpsx && !acmAddressItemGpsx) {
                sAction.getGpsAddress(data);
            }
        }
    }

    setMultiEnumsOld(sAction, data){
        let asCompany = sAction.dataGet(data.prefix + "/fields/as_company/value");
        sAction.dsClear();

        let selectedProductGroup = sAction.dataGet(data.prefix + "/fields/product_group/value");
        if (selectedProductGroup && selectedProductGroup !== ""){
            selectedProductGroup = selectedProductGroup.split(",");

            // this will filter all elements from not selected company
            if (asCompany === "^eleman^" || asCompany === "^famatel^") {
                let filteredOutput = "";

                for (let i = 0; i < selectedProductGroup.length; i++) {
                    let prefix = selectedProductGroup[i].split("_%")[0];
                    if (prefix !== "^E" && asCompany === "^famatel^") {
                        filteredOutput += selectedProductGroup[i] + ",";
                    } else if (prefix !== "^F" && asCompany === "^eleman^") {
                        filteredOutput += selectedProductGroup[i] + ",";
                    } else if (prefix !== "^E" && prefix !== "^F") {
                        filteredOutput += selectedProductGroup[i] + ",";
                    }
                }

                // remove last ","
                filteredOutput = filteredOutput.length !== 0 ? filteredOutput.slice(0, -1) : filteredOutput;

                let multiEnumVal = sAction.dataGet(data.prefix + "/fields/product_group/value")

                if (multiEnumVal !== filteredOutput){
                    sAction.dsAdd("set", data.prefix + "/fields/product_group/value", filteredOutput);
                    sAction.dsAdd("set", data.prefix + "/changes/fields/product_group", filteredOutput);
                }
            }

        }

        if (asCompany === "^eleman^"){
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/options", "eleman_product_category_list");
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/ext1", "eleman_product_category_list");
        } else if (asCompany === "^famatel^") {
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/options", "famatel_product_category_list");
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/ext1", "famatel_product_category_list");
        } else {
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/options", "eleman_famatel_product_category_list");
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/ext1", "eleman_famatel_product_category_list");
        }

        sAction.dsProcess();

    }

    setMultiEnum(sAction, data){
        const relation = sAction.dataGet(data.prefix + "/fields/relation_enum/value");

        if(!relation || relation.length <= 0){
            // relation is empty
            return;
        }

        const relationPrefixSplit = relation.split("_%");
        if(relationPrefixSplit.length <= 1){
            // relation prefix is not valid
            return;
        }
        const relationPrefix = relationPrefixSplit[0];

        // filtering by prefix is done in src/components/defaultFields/Multienum/Multienum.js
        sAction.dsClear();
        sAction.dsAdd("set", data.prefix + "/fields/focus_enum/def/relFilterPrefix", relationPrefix);
        sAction.dsProcess();
        
        // filter selected values when relation is changed
        const selectedFocus = sAction.dataGet(data.prefix + "/fields/focus_enum/value");
        if(selectedFocus && selectedFocus.length > 0){
            const selectedFocusSplit = selectedFocus.split(",");
            // filter selected values with different prefixes
            const filteredSelectedFocus = selectedFocusSplit.filter(item => item.replace('^', '').split('_%')[0] === relationPrefix);
            if(filteredSelectedFocus.length !== selectedFocusSplit.length){
                sAction.dsClear();
                sAction.dsAdd("set", data.prefix + "/fields/focus_enum/value", filteredSelectedFocus.join(","));
                sAction.dsProcess();
            }
        }
    }
    
    beforeSave(sAction, data) {
        let acmAddressGpsx = sAction.dataGet(data.prefix + "/fields/acm_address_gpsx/value");
        let acmAddressItemGpsx = sAction.dataGet(data.prefix + "/fields/acm_address/def/item/gpsX");
        if (!acmAddressGpsx && !acmAddressItemGpsx) {
            sAction.getGpsAddress(data);
            return true;
        }
        return true;
    }

    getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

}
