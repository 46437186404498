import React, { Component } from 'react'
import PureComponent from '../../pure'

import  sAction  from "sAction";

import confProd from "./productLineConfig";
import Button from "../../formElements/Button";

class QGroupTotal extends PureComponent{

    addProdLine(itway){
        //this.setState({show:true});
        sAction.updateDetailCustom("addProdLine", {way:itway+"/lines"})
    }

    notSelectedAsCompany() {
        sAction.error("Není vyplněno pole za firmu. Nelze přidat produkty.")
    }

    render(){
    var data = this.props.data;
    const way = this.props.way;
    const readonly = this.props.readonly;

    const sums = sAction.calcSums(way, {type : "group", data : data});
    const conf = confProd.sums;
    const ind = this.props.ind;

    const self = this;

    let key = 0;

    var renderComp = [];
    if(!readonly){
        renderComp.push(<Button 
            className="acmAddRowBtn hoverGreen"
            key={"addBtn"}
            variant="text" 
            onClick={() => {
                if (sAction.dataGet('view/fields/as_company/value') && sAction.dataGet('view/fields/as_company/value') !== ""){
                    self.addProdLine(way)
                } else {
                    this.notSelectedAsCompany()
                }
            }}>
            <div className="buttonIcon icon-addRowIcon" />
        {sAction.translate("LBL_ADD_ROW", "Quotes")}
        </Button>);
        renderComp.push(
            <div key="firstFiller" style={{width:"34px"}} />
        );
    }
    else{
        renderComp.push(
            <div key="firstFiller" style={{width:"14px"}} />
        );
    }

    // renderComp.push(
    //     <div key="secondFiller" style={{ width:"33%"}} />
    // );

    conf.forEach( function(field){ 

        if(!field.visible){
            return;
        }
        if(field.type == "price"){
          
            // if(field.name == "total_discount_price"){
            //     renderComp.push(
            //         <div key="thirdFiller" style={{width:"15%"}} />
            //     );
            // }

            renderComp.push(
                <div style={{width:field.width}} className="amountsContainer" key={ind+"_"+field.name}>
                    <div>{sAction.translate(field.lbl, field.lblMod)}</div>
                    <div>{sAction.formatNumber(sums[field.name])}</div>
                </div>
            );
        }
        else if(field.type == "addBtn"){
            
        } else if(field.type === "space"){
            renderComp.push(
                <div key={key + "_thirdFiller"} style={{ width: field.width }} />
            ); 
            key++;
        }
    });
    

    return <div className="QTotalContainerFirstRow QTotalContainerRow">
        {renderComp}
    </div>;
    }
}

export default QGroupTotal