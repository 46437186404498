export default function accCreateQuote(data) {
    
    const params = {action: "acc_create_quote", record : data.id};

    this.load();
    this.rest.post("customAction", params, resp => {
        if(resp.errCode == "OK"){
            this.href("#detail/Quotes/"+resp.record);
        }
        else{
            this.unLoad();
            this.alert(resp.errMsg);
        }
        
    });
    
}
