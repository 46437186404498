export default function confrim(message, callback, callbackCancel = () => this.popupHide(), importance = "low" ) {
  let data = {
    // Potvrzení
    header: this.translate("LBL_CONFIRM"),
    buttons: [
      // Zrušit
      { label: this.translate("LBL_CANCEL", "Home"), callback: callbackCancel },

      // Pokračovat
      { label: this.translate("LBL_CONTINUE", "Home"), callback: callback },
    ],
  };

  switch(importance){
    case "high":
      data.color = "red"
      break;
  }

  this.popup(message, data);
}
