
import detailDefault from "../../detailDefault";
export default class detailOpportunities extends detailDefault{

    load(sAction, data) {
        sAction.dsClear();
        const sales_stage = sAction.dataGet(data.prefix+"/fields/sales_stage/value");
        if( sales_stage == "Closed Lost" ||
            sales_stage == "Closed canceled client" ||
            sales_stage == "Closed canceled me"
        ){
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", false);
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", true);
                sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", false);
        }
        else{
            if(sales_stage == "Closed Won"){
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", false);
                //$("#duvod_uzavreni_c").closest(".detailViwRow").css("visibility", "hidden");   
                //sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/visible", false);
            }
            else{
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", true);
                //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "none");
                //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").css("display", "none");  
            }
            sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", false);
        }

        if(sAction.getModuleName() == "Contacts" && data.prefix != "view"){
            const accData = sAction.dataGet("view/fields/account_name");
            const name = accData.get("value");
            const id = accData.getIn(["def","id_value"])
            if(id){
            sAction.dsAdd("set", data.prefix+"/fields/account_name/value", name);
            sAction.dsAdd("set", data.prefix+"/fields/account_name/def/id_value", id);
            sAction.dsAdd("set", data.prefix+"/changes/fields/account_id", id);
            }
        }

        sAction.dsProcess();
        this.setMultiEnums(sAction, data);
        this.checkCanSave(sAction, data);
    }

    update(sAction,data) {
        if(data.field == "account_id"){
            const name = sAction.dataGet(data.prefix+"/fields/name/value");
            if(name == null || name == ""){
                const nameAcc = sAction.dataGet(data.prefix+"/fields/account_name/value");
                sAction.dsClear();
                sAction.dsAdd("set",data.prefix+"/fields/name/value",nameAcc);
                sAction.dsAdd("set",data.prefix+"/changes/fields/name",nameAcc);
                sAction.dsProcess();
            }
        }
        else if(data.field == "sales_stage"){
            if( data.value == "Closed Lost" ||
                data.value == "Closed canceled client" ||
                data.value == "Closed canceled me"
            ){
                //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "flex");
                //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").css("display", "flex");  
                //$("#duvod_uzavreni_c").closest(".detailViwRow").css("visibility", "visible");

                sAction.dsClear();
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", false);
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", true);
                sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", false);

                
                sAction.dsProcess();
            }
            else{
                sAction.dsClear();
                if(data.value == "Closed Won"){
                    sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", false);
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "flex");
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").css("display", "flex");   
                    //$("#duvod_uzavreni_c").closest(".detailViwRow").css("visibility", "hidden");    
                }
                else{
                    sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", true);
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "none");
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").css("display", "none");    
                    
                }

                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", false);
                sAction.dsProcess();
                
            }           
        } else if (data.field == "as_company") {
            this.setMultiEnums(sAction, data);
        }

        this.checkCanSave(sAction, data);
    }

    setMultiEnums(sAction, data){
        let asCompany = sAction.dataGet(data.prefix + "/fields/as_company/value");
        sAction.dsClear();

        let selectedProductGroup = sAction.dataGet(data.prefix + "/fields/product_group/value");
        if (selectedProductGroup && selectedProductGroup !== ""){
            selectedProductGroup = selectedProductGroup.split(",");

            // this will filter all elements from not selected company
            if (asCompany === "^eleman^" || asCompany === "^famatel^") {
                let filteredOutput = "";

                for (let i = 0; i < selectedProductGroup.length; i++) {
                    let prefix = selectedProductGroup[i].split("_%")[0];
                    if (prefix !== "^E" && asCompany === "^famatel^") {
                        filteredOutput += selectedProductGroup[i] + ",";
                    } else if (prefix !== "^F" && asCompany === "^eleman^") {
                        filteredOutput += selectedProductGroup[i] + ",";
                    } else if (prefix !== "^E" && prefix !== "^F") {
                        filteredOutput += selectedProductGroup[i] + ",";
                    }
                }

                // remove last ","
                filteredOutput = filteredOutput.length !== 0 ? filteredOutput.slice(0, -1) : filteredOutput;

                let multiEnumVal = sAction.dataGet(data.prefix + "/fields/product_group/value")

                if (multiEnumVal !== filteredOutput){
                    sAction.dsAdd("set", data.prefix + "/fields/product_group/value", filteredOutput);
                    sAction.dsAdd("set", data.prefix + "/changes/fields/product_group", filteredOutput);
                }
            }

        }

        if (asCompany === "^eleman^"){
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/options", "eleman_product_category_list");
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/ext1", "eleman_product_category_list");
        } else if (asCompany === "^famatel^") {
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/options", "famatel_product_category_list");
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/ext1", "famatel_product_category_list");
        } else {
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/options", "eleman_famatel_product_category_list");
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/ext1", "eleman_famatel_product_category_list");
        }

        sAction.dsProcess();

    }

    checkCanSave =  (sAction, data, quiet = true) => {
        sAction.rest.post("checkOppCanSave", {
            id: sAction.dataGet("view/id"),
        }, (resp) => {
            sAction.dsClear();
            sAction.dsAdd("set", data.prefix + "/customData/canSave", resp?.status);
            sAction.dsProcess();

            let stage = sAction.dataGet(data.prefix + "/fields/sales_stage/value");
            if (stage === "Value Proposition" && !quiet && resp.status === false) {
                sAction.error(resp.errorMessage.text)
            }
        }, false);
    }

    beforeSave(sAction, data){
        let stage = sAction.dataGet(data.prefix + "/fields/sales_stage/value");
        let canSave = false;
        if(stage != "Value Proposition") {
            canSave = true;
        } else {

            let subpanels = sAction.dataGet(data.prefix + "/subpanels");
            if(subpanels) subpanels = subpanels.toJS();
            const quotes = subpanels.find( subpanel => subpanel.module === "Quotes")

            if(quotes && quotes.rows.records.length <= 0) {
                this.checkCanSave(sAction, data, false);
                canSave = sAction.dataGet(data.prefix + "/customData/canSave");
            } else {
                console.log(">> quote found")
            }
        }

        return canSave
    }


}
