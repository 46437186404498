import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class Varchar extends PureComponent {
    constructor(props) {
        super(props);
    }

    /**
     * @return {JSX.Element}
     * Vrací hodnotu kterou má element rendrovat
     */
    getValue() {
        return <div className="overflowEllipsis">{this.props.value}</div>;
    }

    /**
     * @param {boolean} readonlyState
     * @return {null|JSX.Element}
     * Vrací null nebo div, který obsahuje ikonku editovacího pera
     */
    displayEditPen(readonlyState) {
        let iconClassName = 'icon-EditField';
        if (this.props.def.get('edit') === true) {
            iconClassName = 'fieldEdited';
        }

        return readonlyState ? null : (
            <div
                onClick={() => this.props.edit()}
                className={'detailViewSpanEdit ' + iconClassName}
            />
        );
    }

    /**
     * @return {null}
     * Vrací styl
     */
    getStyle() {
        return null;
    }

    /**
     * @return {string}
     * Vrací classu viewFieldu
     */
    getContainterClass() {
        let containerClass = 'valueContainer';
        containerClass += this.getReadOnlyState() ? ' notAllowed' : ' hover';
        if (
            this.props.value === null || this.props.value === '' ||
            this.props.value === undefined || this.props.value === ' '
        ) {
            containerClass += ' nullValue';
        }
        containerClass += ' ' + this.props.customClass +
            ((this.props.def.get?.('required') && this.props.attemptedSave &&
                (this.props.value == null || this.props.value === '')) ? ' error' : '');

        return containerClass;
    }

    /**
     * @return {boolean}
     * Funkce zistí, jestli je pole readOnly
     */
    getReadOnlyState() {
        if ((this.props.acl !== undefined && this.props.acl.get('edit') === false) ||
            this.props.readonly === true || this.props.def.get('readonly') === true
        ) {
            return true;
        }

        return false;
    }

    /**
     * @return {{}|Object}
     * Vrací onClick funkci pole
     */
    getOnClick() {
        const attrs = {};
        if (!this.getReadOnlyState()) {
            attrs['onClick'] = () => this.props.edit();
        }

        return attrs;
    }

    render() {
        const attrs = this.getOnClick();

        return (
            <div>
                <div
                    className={this.getContainterClass()}
                    {...attrs}
                    data-test-field={this.props.name}
                    data-test-type={'field'}
                    data-test-field-type={this.props.def.get('type')}
                >
                    <span id={this.props.name}>
                        {this.getStyle()}
                        {this.getValue()}
                    </span>
                </div>
                {this.displayEditPen(this.getReadOnlyState())}
            </div>
        );
    }
}

Varchar.propTypes = {
    edit: PropTypes.func,
    prefix: PropTypes.any, // napr: view
    module: PropTypes.any,
    way: PropTypes.any, // napr: view/field/${field}
    percent: PropTypes.any, // TODO netusim co je, mozna pujde smazat
    key: PropTypes.any,
    rowWay: PropTypes.any,
    id: PropTypes.any,
    readonly: PropTypes.any,
    acl: PropTypes.any,
    mode: PropTypes.any,
    attemptedSave: PropTypes.any,
    name: PropTypes.any,
    value: PropTypes.any,
    actEdit: PropTypes.any,
    customStyle: PropTypes.any,
    customClass: PropTypes.any,
    def: PropTypes.any,
    events: PropTypes.any,
    selectForEdit: PropTypes.any,
    extraStyle: PropTypes.any,
    labelValue: PropTypes.any,
};
