
import React, { Component } from 'react'

import InputField from '../inputFieldClass'

export default class Phone extends InputField {
  constructor(){
    super();
    this.state = {
      state: "fine",
      // allows czech numbers with +420 ignore spaces
      reg: /^(([+]\d{3})([0-9 -](?:\s*\w){9}))$/,
      // allows only format +420 123 456 789:
      // reg: /^([+]\d{3}(?: \d{3}){3}$)/,
      // old regex:
      // reg: /^[+]?[0-9 -]{9,}$/,
    };
  }
}
