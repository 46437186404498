import React from "react";
import PureComponent from "../pure";

import WaveEffect from "../formElements/WaveEffect";
import MoreOptions from "../formElements/MoreOptions";
import sAction from "sAction";
import NewActivityButton from "./NewActivityButton";

import ListViewPagination from "../list/ListViewPagination";
import CheckBox from "ROOT/src/components/formElements/CheckBox";

export default class SubpanelHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.header = React.createRef();
    let parentID = sAction.dataGet(props.prefix+"/id")
    let currentDetail = sAction.dataGet("view/module");
    let thisSubData = 0;
    if (currentDetail === 'Accounts'){
      const relName = this.props.data.def.get('rel').get('relName');

      let lsData = sAction.getStorage("Accounts-subpanel-branch");
      if (lsData && lsData[relName]){
        thisSubData = lsData[relName]; // stav checkboxu pri nacteni
      }
      else if (!lsData){
        sAction.setStorage("Accounts-subpanel-branch",{}) // uz neni treba, pokud nechceme nejake defaultni hodnoty
      }
    }
    this.state = {
      showData:thisSubData, // vytahnout data pro specificky subpanel
      inModule:currentDetail, // v jakem detailview se nachazime
      parentID:parentID, //
    }
  }
  pagination(type) {
    const data = this.props.data;
    const listData = data.rows;
    const way = this.props.way;
    const paginationData = {
      offset: listData.offset,
      limit: listData.limit,
      rowCount: listData.rowCount,
      page: listData.page,
      prefix: way + "/rows",
      type: type,
    };
    sAction.listPagination(paginationData);
  }
  subpanelMaxSize() {
    var st = {};
    const way = this.props.way;
    let state = [];
    var container = this.header.current.closest(".subpanelContainer");
    if (container.classList.contains("maxSize")) {
      st.isOpen = "";
      this.setState(st);
      container.classList.remove("maxSize");
      this.state && (
        this.state["isMaxSize"] = false )
      sAction.dataSet(way + "/rows/limit", 10);
      sAction.reloadList(0, 1, way + "/rows");
    } else {
      container.classList.add("maxSize");
      state["isMaxSize"] = true;
      this.setState(state);
      sAction.dsClear();
      sAction.dsAdd("set",way + "/rows/limit", 100);
      sAction.dsAdd("set",way + "/rows/selected",[]);
      sAction.dsProcess();
      sAction.reloadList(0, 1, way + "/rows");
    }
  }
  openRelateListView() {
    const data = this.props.data;

    const defaultFilter = sAction.subpanelGenerateDefaultFilter(
      this.props.prefix,
      data
    );

    const paramData = {
      module: data.module,
      defaultFilter: defaultFilter,
    };
    sAction.openRelatePopup(paramData, (returnData) => {
      sAction.subppanelChangeRelate(
        returnData,
        data,
        this.props.prefix,
        this.props.way,
        0
      );
    });
  }
  openExportMenu() {
    const prefix = this.props.way + "/rows";
    const data = this.props.data;
    const params = {
      prefix: prefix,
      module: data.module,
    };
    if (data.module === "Activities" || data.module === "History") {
      sAction.exportActivitiesOrHistoryList();
    } else {
      sAction.exportList(params);
    }
  }
  closeMaxSize() {
    if(this.state) {
      var container = this.header.current.closest(".subpanelContainer");
      if (container.classList.contains("maxSize")) {
        container.classList.remove("maxSize");
      }
    }
  }

  subpanelNewRecord() {
    this.closeMaxSize();
    const data = this.props.data;
    const prefix = this.props.prefix;
    const way = this.props.way;
    sAction.subpanelNewRecord({ paramData: data, way, prefix });
  }
  getChildrenData(state) {
    const relName = this.props.data.def.get('rel').get('relName');
    let lsData = sAction.getStorage("Accounts-subpanel-branch");

    lsData[relName] = state?1:0;

    this.setState({showData:state}); // pro checkbox
    sAction.setStorage("Accounts-subpanel-branch", lsData); // pro stahovani dat

    let reloadData = {
      module : this.props.data.module,
      parentId : this.state.parentID,
      parentModule : this.props.data.def.get('rel').get('module'),
      prefix : this.props.prefix,
      relName : relName,
      way : this.props.way,
    };

    sAction.reloadSubpanel(reloadData); // natahneme nova data

    sAction.route() // bohuzel je treba reloadnout, jinak se podruhe neprenactou viditelne radky v subpanelu
  }

  optionsReloadSubpanel() {
    var data = this.props.data;
    const prefix = this.props.prefix;
    var module = data.module;
    sAction.reloadSubpanelByWay(prefix, module);
  }

  render() {
    const data = this.props.data;
    const prefix = this.props.prefix;
    const way = this.props.way;
    const records = this.props.records;
    const pageNumber = data.rows.page;
    const open = data.def.get("open");
    const readonly = data.def.get("readonly");
    const listData = data.rows;
    const more_options = this.props.data.more_options ? this.props.data.more_options.toJS() : null;
    let icon;
    const module = data.module;
    const menuGroups = sAction.dataGet("menu/groups");
    for (let group of menuGroups) {
      icon = group.icon;
      if (icon !== module) {
        icon = group.buttons.find((button) => button.name === module);
        icon = icon === undefined ? "object-connection" : icon.name;
      }

      if (icon === module) break;
    }

    if (module === "History") {
      icon = "history";
    }

    if (module && module.includes("mail")) {
      icon = "emails";
    }

    if (/user/i.test(module)) {
      icon = "Users";
    }

    var subpanelIconClass = "icon-subpanelClose";
    var subpanelIconClassTranslate = "LBL_MINIMIZE";
    if (open == false) {
      subpanelIconClass = "icon-subpanelOpen";
      subpanelIconClassTranslate = "LBL_MAXIMIZE";
    }
    let showActivities;
    var addOptions = [];
    if (data.module == "Activities" || data.module == "History") {
      showActivities = (
        <div
          className="subpanelHeaderRightButton icon-Activities"
          onClick={(e) => 
            {
              this.closeMaxSize();
              sAction.showActivitiesPanel(prefix);
            }}
        />
      );
    }
    let newRecordButton;
    if (readonly) {
      newRecordButton = null;
    } else if (data.module == "Activities") {
      newRecordButton = (
        <NewActivityButton
          data={data}
          parent={this.header.current ? this: ""}
          prefix={prefix}
          way={way}
          activities={[
            { module: "Meetings" },
            { module: "Calls" },
            { module: "Tasks" },
            {
              label: sAction.translate("LBL_COMPOSE_EMAIL_BUTTON_LABEL"),
              module: "Emails",
              icon: "icon-Emails",
            },
          ]}
        />
      );
    } else if (data.module == "History") {
      newRecordButton = (
        <NewActivityButton
          parent={this.header.current ? this : ""}
          data={data}
          prefix={prefix}
          way={way}
          activities={[{
            label: sAction.translate("LBL_MODULE_NAME_SINGULAR_NEW", "Emails"),
            module: "Emails",
            icon: "icon-Emails",
          }, { module: "Notes" }]}
        />
      );
    } else {
      if (sAction.hasAccess(data.module, "edit") == true && !(more_options?.create_new == false)) {
        addOptions.push({
          label: sAction.translate("LBL_CREATE_NEW"),
          value: "subpanelNewRecord",
          icon: "icon-addIcon",
        });
      }
    }
    let childRecordsButton = [];
    var options = [];
    if (data.module == "Activities" || data.module == "History") {
      options = [
        {
          label: sAction.translate("LBL_EXPORT_LIST"),
          value: "openExportMenu",
        },
      ];
      
    } else {
      if (this.state.inModule === 'Accounts') {
        childRecordsButton.push(
          <div title={sAction.translate("LBL_SHOW_HQ_DATA_BUTTON")}>
            <CheckBox
              defaultValue={this.state.showData ? 1 : 0}
              onChange={e => this.getChildrenData(e)}
            />
          </div>
        );
      }

      if (!readonly && !(more_options?.select_existing == false)) {
        addOptions.push({
          label: sAction.translate("LBL_ASSIGN"),
          value: "openRelateListView",
          icon: "icon-listview",
        });
      }
      if(sAction.hasAccess(data.module, "export")){
        options.push({
          label: sAction.translate("LBL_EXPORT_LIST"),
          value: "openExportMenu",
        });
      }
    }

    options.push({
      label: "Aktualizovat subpanel",
      value: "optionsReloadSubpanel",
    });
    const parentModule = data.def.getIn(["rel", "module"]) ?? "";
    const relName = data.def.getIn(["rel", "relName"]) ?? "";
    const subpanelNameKey1 = data.module
      ? "LBL_" + data.module.toUpperCase() + "_SUBPANEL_TITLE"
      : "";
    const subpanelNameKey2 =
      data.module && relName
        ? "LBL_" +
          relName.toUpperCase() +
          "_FROM_" +
          data.module.toUpperCase() +
          "_TITLE"
        : "";
    var subpanelName = sAction.translate(subpanelNameKey2, parentModule);
    if (subpanelName == subpanelNameKey2) {
      subpanelName = sAction.translate(subpanelNameKey1, parentModule);
    }
    if (subpanelName == subpanelNameKey1) {
      subpanelName = sAction.app_strings.moduleList[data.module] || data.module;
    }
    if (!subpanelName) {
      subpanelName = data.getIn(["def", "rel", "module"]) ?? "";
      console.log("Chybí jméno subpanelu !");
    }

    return (
      <div className="subpanelHeader" ref={this.header}>
        <div>
          <div
            className="subpanelHeaderLeftContainer"
            style={{ backgroundColor: this.props.iconColor }}
          >
            <span className="moveHolder icon-Sortable" />
            <div className={"subpanelIconContainer"}>
              <span className={"icon-" + icon + " subpanelIcon"} />
            </div>
          </div>
          <h3>{subpanelName}</h3>
          {sAction.getStorage("debug") && sAction.dataGet("conf/isAcmAdmin") && data.module !== "Activities" && data.module !== "History" &&
          (<div
              className="debugDbButton icon-database"
              onClick={() => sAction.generateDbLink({ action: "subpanel_table", subpanel: data.getIn(["def", "rel"])})}
          />)
          }
        </div>
        <div>
          {data.def.get("open") === true &&
            (listData.page != 1 || records !== 0) && (
              <React.Fragment>
                <ListViewPagination
                  offset={listData.offset}
                  limit={listData.limit}
                  rowCount={listData.rowCount}
                  rowTotalCount={listData.rowTotalCount}
                  page={listData.page}
                  prefix={way + "/rows"}
                  module={data.module}
                  isMaxSize={this.state &&
                    this.state.isMaxSize}
                />
                <div
                  onClick={() => this.subpanelMaxSize()}
                  className="subpanelHeaderRightButton icon-MaxSize"
                ></div>
              </React.Fragment>
            )}
          {data.module == "Activities" && newRecordButton}
          {data.module == "History" && newRecordButton}
          {(data.module !== "History" || data.module !== "Activities") && childRecordsButton}

          {addOptions.length != 0 && (
            <MoreOptions
              options={addOptions}
              onRight={true}
              fromRight={90}
              onClick={(e, val) => this[val](e)}
            >
              <div className="subpnaelheaderNewRecord subpanelHeaderRightButton icon-addIcon">
                <WaveEffect className="dark" />
              </div>
            </MoreOptions>
          )}
          {showActivities}
          <MoreOptions
            options={options}
            onRight={true}
            fromRight={70}
            onClick={(e, val) => this[val](e)}
          >
            <div className="subpanelHeaderRightButton icon-More" />
          </MoreOptions>
          <div
            onClick={() => sAction.toogleSubpanel(data, way, prefix)}
            isopen={this.state ? this.state.isOpen : ""} 
            className={subpanelIconClass + " toogleSubpanelButton"}
            title={sAction.translate(subpanelIconClassTranslate)}
          />
        </div>
      </div>
    );
  }
}
