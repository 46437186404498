import sAction from 'sAction';

// relate more detaile config for search
import relateMoreDetailConfig from '../../_custom/config/relateMoreDetailConfig';

export default function listGlobalSearch(searchData, prefix, callback = null) {
    var actFilter = null
    if (searchData && searchData.length !== 0) {
        actFilter = { 
            // "field": "*",
            "operator": "and",
            "operands": [],
            filterFormat: "filter"
        }
    }

    const relateDetailConfig = relateMoreDetailConfig();

    searchData.forEach(val => {
        if (val ?. val != "" && val ?. val != null && val ?. val != undefined) {
            let actOperand = {
                "operator": "or",
                "operands": []
            }

            // accounts address search -> shipping_address_city, shipping_address_street
            let accountsRelateFields = findAccountRelateField(prefix)
            if (accountsRelateFields && accountsRelateFields?.name) {
                if (accountsRelateFields.name === "parent_name") {
                    Object.keys(relateDetailConfig).forEach((module) => {
                        let fields = relateDetailConfig[module];
                        fields.forEach(field => {
                            actOperand.operands.push({
                                "field": "parent_name",
                                "type": "relate",
                                "value": val.val,
                                "parent_type": module,
                                "relField": field,
                                "relType": "cont",
                            })
                        })
                    })

                    actOperand.operands.push({
                        "field": "*", 
                        "type": "cont", 
                        "value": val.val
                    })

                } else {
                    Object.keys(relateDetailConfig).forEach((module) => {
                        let fields = relateDetailConfig[module];
                        fields.forEach(field => {
                            actOperand.operands.push({
                                "module": module,
                                "type": "relate",
                                "name": accountsRelateFields.name,
                                "relationship": [
                                    accountsRelateFields.link,
                                ],
                                "filter": {
                                    "operator": "and",
                                    "operands": [
                                        {
                                            "field": field,
                                            "type": "cont",
                                            "value": val.val
                                        }
                                    ]
                                }
                            })
                        })
                    })

                    actOperand.operands.push({
                        "field": "*", 
                        "type": "cont", 
                        "value": val.val
                    })
                }
            } else {
                actOperand = {
                    "field": "*",
                    "type": "cont",
                    "value": val.val
                }
            } 
            actFilter.operands.push(actOperand)
        }
    });

    var akce = {
        type: "SETFILTER",
        listViewPrefix: prefix,
        filter: actFilter
    }
    this.dataSetCustom(akce);
    this.dsClear();
    this.dsAdd('set', prefix + '/actFiltering', false);
    this.dsProcess();

    this.reloadList(0, 1, prefix);

    callback()
}

const findAccountRelateField = (prefix) => {
    const relateDetailConfig = relateMoreDetailConfig();
    const rows = sAction.dataGet(prefix + '/rows');
    let relateFields = [];

    rows.forEach(row => {
        if (row) {
            row = row.toJS();
        }
        Object.keys(relateDetailConfig).forEach((relModule) => {
            if ((row.def.module === relModule && row.def.type === "relate") || row.def.name === "parent_name") {
                relateFields.push({name: row.def.name, link: row.def.link})
            }
        })
    })

    let out = relateFields?.length > 0 ? relateFields[0] : null
    // vyjmka pro vazbu na nabidkach, padala querygille na nazvu vazby
    if(out?.link === 'billing_accounts') out.link = 'accounts'
    return out;
}
