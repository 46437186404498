export default function calcSums(way, params){
    var dph = params.data.dph;

    if(params.type == "group"){
        return calcGroupSums(params.data, this, dph);
    }

    var data = params.data;

    var total_cost_price = 0;
    var total_list_price = 0;
    var total_discount_amount = 0;
    var total_discount_price = 0;
    var total_tax = 0;
    var total_margin = 0;

    var self = this;

    data.groups.forEach(function(group){
        let pom = calcGroupSums(group, self, dph);

        total_cost_price += pom.total_cost_price;
        total_list_price += pom.total_list_price;
        total_discount_amount += pom.total_discount_amount;
        total_discount_price += pom.total_discount_price;
        total_tax += pom.total_tax;
        total_margin += pom.total_margin;
    });

    return {
        total_cost_price : total_cost_price,
        total_list_price : total_list_price,
        total_discount_amount : total_discount_amount,
        total_discount_price : total_discount_price,
        total_discount: total_discount_price,
        total_tax : total_tax,
        total_margin : total_margin,
        total: total_discount_price + self.parseNumber(data.shipping) + total_tax,
    };
}

function calcGroupSums(data, sAction, dph){
    var total_cost_price = 0;
    var total_list_price = 0;
    var total_discount_amount = 0;
    var total_discount_price = 0;
    var total_tax = 0;
    var total_margin = 0;

    data.lines.forEach(function(prod){
        if(prod.opce_c){
            return;
        }

        let line_discout_price = prod.quantity*sAction.parseNumber(prod.list_price);

        total_cost_price += prod.quantity*sAction.parseNumber(prod.cost_price);
        total_list_price += line_discout_price;

        let valAmount = 0;
        let discount = sAction.parseNumber(prod.discount_amount);
        let afterDiscount = prod.price_after_defdisc * (1 - (1 / 100 * discount));
        let discountAmount = (prod.list_price - afterDiscount) * prod.quantity      

        // if (prod.discount_select == 1) {
        // discount = (sAction.parseNumber(prod.list_price) / 100) * discount;
        // }
        valAmount = afterDiscount * prod.quantity;

        total_discount_amount += discountAmount;
        // total_discount_amount += prod.quantity*discount;

        line_discout_price = line_discout_price - (discountAmount);
        total_discount_price += valAmount;

        if(prod.tax_class != "Non-Taxable"){
            total_tax += (valAmount / 100) * dph;
        }

    });

    total_margin = total_discount_price - total_cost_price;

    return {
        total_cost_price : total_cost_price,
        total_list_price : total_list_price,
        total_discount_amount : total_discount_amount,
        total_discount_price : total_discount_price,
        total_tax : total_tax,
        total_margin : total_margin,
    };
}
