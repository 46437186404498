export default function importFileCsv(data, callback) {
    var input = document.createElement('input');
    input.type = "file";
    input.style = "display:none";

    if (data.accept) {
        // povolene typy souboru pro upload, napr. "image/png,image/jpeg,image/bmp,image/gif" nebo jen "image/*" nebo ".doc,.docx,application/msword" atd.
        input.setAttribute("accept", data.accept);
    }

    // input.multiple = true;
    input.onchange = (e) => {

        const sendData = {
            data: {
                module: data.module,
                id: data.id,
                files: e.target.files,
                payload: data.payload
            },
            action: "importFile",
            responseType: "json",
            success: response => {
                this.unLoad();
                callback(response);
            },
            progress: progress => {
            }
        }
        this.load();
        this.filesXhr(sendData);
    }

    setTimeout(function () {
        input.click();
    }, 200);

}