
import $ from "jquery";
import moment from "moment";
import detailDefault from "../../detailDefault";
export default class detailMeetings extends detailDefault {
    load(sAction, data) {
        var prefixInv = data.prefix + "/customData/invitees";

        if (data.view == "calendar" || data.view == "home") {
            prefixInv = data.prefix.replace("/detailView", "");
            prefixInv += "/invitees";
        }

        this.setDescription(sAction, data)
        /*
        if(data.view == "calendar"){
            prefixInv = "calendar/invitees"
        }
        */
        this.searchInvitees(sAction, data.prefix, prefixInv);

        if (data.view != "calendar" && data.view != "home") {
            const id = sAction.dataGet(data.prefix + "/id");
            if (!id) {
                const now = new Date();
                let mins = now.getMinutes();
                let rounded = Math.round(mins / 30) * 30;
                // Dvojty zapor aby se nepricitalo - JS u pricitani stringu nefunguje
                const today = this.getFormatedDate(now.getTime() - (1000 * 60 * (mins - rounded)));
                // - 30 kvuli defaultni delce 30 minut. Zapor kvuli tomu, ze se odcita 2x - vyhnu se pricitani stringu
                const end = this.getFormatedDate(now.getTime() - (1000 * 60 * (mins - rounded - 30)));

                //Kontrola zda nejsou ulozene hodiny a minuty kvuli duplikaci.

                const savedHours = sAction.dataGet(data.prefix+"/fields/duration_hours/value")

                let innitValueHours = 0;
                let innitValuesMinutes = 30;

                if(savedHours) {
                        innitValueHours = savedHours
                        innitValuesMinutes = sAction.dataGet(data.prefix+"/fields/duration_hours/def/minutesValue")
                }

                sAction.dsClear();
                sAction.dsAdd("set", data.prefix + "/fields/date_start/value", today)
                sAction.dsAdd("set", data.prefix + "/changes/fields/date_start", today)
                sAction.dsAdd("set", data.prefix + "/fields/date_end/value", end);
                sAction.dsAdd("set", data.prefix + "/changes/fields/date_end", end);
                sAction.dsAdd("set", data.prefix + "/fields/duration_hours/value", innitValueHours);
                sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/minutesValue", innitValuesMinutes);
                sAction.dsAdd("set", data.prefix + "/changes/fields/duration_hours", innitValueHours);
                sAction.dsAdd("set", data.prefix + "/changes/fields/duration_minutes", innitValuesMinutes);
                sAction.dsProcess();
            }
        }

        this.locationLink(sAction, data.prefix);

        const focus_enum = sAction.dataGet(data.prefix + '/fields/focus_enum/value');
        const relation_enum = sAction.dataGet(data.prefix + '/fields/relation_enum/value');

        // k cemu je potreba parent
        let location = sAction.dataGet(data.prefix + '/fields/location/value');
        const parent_id = sAction.dataGet(data.prefix + "/fields/parent_name/def/id_value");
        const parent_type = sAction.dataGet(data.prefix + "/fields/parent_name/def/parent_type_value");
        if (!location && parent_id && parent_type === "Accounts"){
            this.getAddress(sAction, data);
        }
        let parent_company = sAction.dataGet(data.prefix + '/fields/parent_company_name/value');
        if (!parent_company && parent_id && parent_type === "Accounts"){
            this.setAcc(sAction, data, parent_id, false)
        }
        if((!focus_enum || !relation_enum) && parent_type === 'Accounts' && parent_id){
            this.setRelationAndFocus(sAction, data, parent_id);
        }
        // END

        if(sAction.dataGet(data.prefix + "/fields/all_day/value") == "1"){
            sAction.dsClear();
            sAction.dsAdd("set", data.prefix + "/fields/date_start/def/readonly", true);
            sAction.dsAdd("set", data.prefix + "/fields/date_end/def/readonly", true);
            sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/readonly", true);
            sAction.dsProcess();
        }

        this.setMultiEnums(sAction, data);
    }

    beforeSave(sAction, data) {
        let status = sAction.dataGet(data.prefix + '/fields/status/value');
        let description = sAction.dataGet(data.prefix + '/fields/description/value');
        if (status === 'Held' && !description) {
            sAction.alert(sAction.translate('LBL_DESC_NOT_VALID', 'Meetings'))
            return false;
        }
        return true;
    }
    update(sAction, data) {
        // massUpdate = return, aby to nepadalo kvuli chybejicim fieldum
        if (data.prefix === 'rightPanel/data' && sAction.dataGet("rightPanel/content") === 'massUpdateView') {
            return;
        }

        if (data.field == "parent_id") {
            const parent_id = sAction.dataGet(data.prefix + "/fields/parent_name/def/id_value");
            this.setAcc(sAction, data, parent_id, false)
            this.setRelationAndFocus(sAction, data, parent_id);

            // console.log("UPDATE", data.prefix);
            var prefixInv = data.prefix + "/customData/invitees";
            if (data.view == "calendar" || data.view == "home") {
                var prefixInv = data.prefix.replace("/detailView", "").replace("/meetingView", "");
                prefixInv += "/invitees";
            }
            else if (data.view != "detail") {
                return;
            }
            this.searchInvitees(sAction, data.prefix, prefixInv);
            //checkni prez store(spesl funkce)


            // kontrola adresy, pokud uz je vyplnena, tak neprepisovat
            // podle issue https://gitlab.acmark.cz/coripo/eleman/-/issues/235 ji chteji prepisovat pri zmene firmy
            // let location = sAction.dataGet(data.prefix + '/fields/location/value');
            // if (!location){
            this.getAddress(sAction, data);
            // }
        } else if (data.field === 'status') {
            this.setDescription(sAction, data, true)
        } else if (data.field === 'date_end') {
                let start = sAction.dataGet(data.prefix + '/fields/date_start/value');
                start = new Date(start);
                let end = new Date(data.value);
                if (!this.checkValidDate(start, end, data, sAction)) {
                        end = new Date(start.getTime());
                }
                let diff = Math.abs(end - start);
                let mins = (Math.floor(diff / (1000 * 60))) % 60;
                let hours = Math.floor(diff / (1000 * 60 * 60));
                sAction.dsClear();
                sAction.dsAdd("set", data.prefix + "/fields/duration_hours/value", hours);
                sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/minutesValue", mins);
                sAction.dsAdd("set", data.prefix + "/changes/fields/duration_hours", hours);
                sAction.dsAdd("set", data.prefix + "/changes/fields/duration_minutes", mins);
                sAction.dsProcess();
        } else if (data.field === 'duration_hours' || data.field === 'date_start') {
                let hours = sAction.dataGet(data.prefix + "/fields/duration_hours/value");
                let mins = sAction.dataGet(data.prefix + "/fields/duration_hours/def/minutesValue");
                let start = sAction.dataGet(data.prefix + '/fields/date_start/value');

                start = new Date(start);
                let endDate = this.getFormatedDate(start.getTime() + (1000 * 60 * ((mins * 1) + (hours * 60))));

                sAction.dsClear();
                sAction.dsAdd("set", data.prefix + "/fields/date_end/value", endDate);
                sAction.dsAdd("set", data.prefix + "/changes/fields/date_end", endDate);
                sAction.dsProcess();
        }
        else if (data.field === 'all_day') {
            if(data.value == "1"){
                let start = sAction.dataGet(data.prefix + '/fields/date_start/value');
                let pomStart = moment(new Date(start));
                let end = sAction.dataGet(data.prefix + '/fields/date_end/value');
                if(!end){
                    end = start;
                }
                let pomEnd = moment(new Date(end));
                if(pomStart.format("YYYY-MM-DD") == pomEnd.format("YYYY-MM-DD")){
                    pomEnd.add(1, 'days');
                }
                pomStart.set({hour: 0, minute: 0, second: 0});
                pomEnd.set({hour: 0, minute: 0, second: 0});

                sAction.dsClear();
                let strEnd = pomEnd.format("YYYY-MM-DD HH:mm:ss");
                let strStart = pomStart.format("YYYY-MM-DD HH:mm:ss");
                
                sAction.dsAdd("set", data.prefix + "/fields/date_start/value", strStart);
                sAction.dsAdd("set", data.prefix + "/fields/date_start/def/readonly", true);
                sAction.dsAdd("set", data.prefix + "/changes/fields/date_start", strStart);
                
                sAction.dsAdd("set", data.prefix + "/fields/date_end/value", strEnd);
                sAction.dsAdd("set", data.prefix + "/fields/date_end/def/readonly", true);
                sAction.dsAdd("set", data.prefix + "/changes/fields/date_end", strEnd);            
                
                let duration_hours = pomEnd.diff(pomStart, 'hours');
                sAction.dsAdd("set", data.prefix + "/fields/duration_hours/value", duration_hours);
                sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/minutesValue", 0);
                sAction.dsAdd("set", data.prefix + "/changes/fields/duration_hours", duration_hours);
                sAction.dsAdd("set", data.prefix + "/changes/fields/duration_minutes", 0);
                sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/readonly", true);


                sAction.dsProcess();
                
            }
            else{
                sAction.dsClear();
                sAction.dsAdd("set", data.prefix + "/fields/date_start/def/readonly", false);
                sAction.dsAdd("set", data.prefix + "/fields/date_end/def/readonly", false);
                sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/readonly", false);
                sAction.dsProcess();
            }
        } else if (data.field == "as_company") {
            this.setMultiEnums(sAction, data);
        }
        this.locationLink(sAction, data.prefix);
    }

    detailViewLoad(sAction, data) {
        console.log("VIEW LOAD", data);
    }

    detailViewUpdate(sAction, data) {
        console.log("update Detail");
    }

    locationLink(sAction, prefix) {
        const location = sAction.dataGet(prefix + "/fields/location");
        const value = location?.value;
        if (value != null) {
            if (value.startsWith("http") || value.startsWith("www")) {
                sAction.dataSet(prefix + "/fields/location/def/type", "url")
            }
        }
    }

    searchInvitees(sAction, prefix, prefixInv) {
        // console.log("PARENT", prefix);
        const parent = sAction.dataGet(prefix + "/fields/parent_name");
        if (parent && parent.def && parent.def.get("parent_type_value") == "Accounts" && parent.def.get("id_value") != "") {
            sAction.dataSet("calendar/invitees/parentAccountId", parent.def.get("id_value"));
            sAction.searchInvitees({ parent: parent.toJS() }, prefixInv);
        }
    }

    getFormatedDate(timeStamp) {
        const date = new Date(timeStamp);
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        if (month < 10) {
            month = "0" + month
        }
        let day = date.getDate();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if (day < 10) {
            day = "0" + day;
        }
        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes
        }

        return (year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":00");
    }

    checkValidDate(start, end, data, sAction) {
        const startStamp = start.getTime();
        const endStamp = end.getTime();

        if (endStamp < startStamp) {
            sAction.dsClear();
            const endDate = this.getFormatedDate(startStamp);
            sAction.dsAdd("set", data.prefix + "/fields/date_end/value", endDate);
            sAction.dsAdd("set", data.prefix + "/changes/fields/date_end", endDate);
            sAction.dsProcess();
            sAction.error(sAction.translate('LBL_END_DATE_IS_HIGHER_THAN_START'));

            return false;
        } else {
            return true;
        }
    }

    setMultiEnums(sAction, data) {
        let asCompany = sAction.dataGet(data.prefix + "/fields/as_company/value");
        sAction.dsClear();

        let selectedProductGroup = sAction.dataGet(data.prefix + "/fields/product_group/value");
        if (selectedProductGroup && selectedProductGroup !== "") {
            selectedProductGroup = selectedProductGroup.split(",");

            // this will filter all elements from not selected company
            if (asCompany === "^eleman^" || asCompany === "^famatel^") {
                let filteredOutput = "";

                for (let i = 0; i < selectedProductGroup.length; i++) {
                    let prefix = selectedProductGroup[i].split("_%")[0];
                    if (prefix !== "^E" && asCompany === "^famatel^") {
                        filteredOutput += selectedProductGroup[i] + ",";
                    } else if (prefix !== "^F" && asCompany === "^eleman^") {
                        filteredOutput += selectedProductGroup[i] + ",";
                    } else if (prefix !== "^E" && prefix !== "^F") {
                        filteredOutput += selectedProductGroup[i] + ",";
                    }
                }
                // remove last ","
                filteredOutput = filteredOutput.length !== 0 ? filteredOutput.slice(0, -1) : filteredOutput;

                if (sAction.dataGet(data.prefix + "/fields/product_group/value") !== filteredOutput) {
                    sAction.dsAdd("set", data.prefix + "/fields/product_group/value", filteredOutput);
                    sAction.dsAdd("set", data.prefix + "/changes/fields/product_group", filteredOutput);
                }
            }
        }

        if (asCompany === "^eleman^") {
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/options", "eleman_product_category_list");
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/ext1", "eleman_product_category_list");
        } else if (asCompany === "^famatel^") {
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/options", "famatel_product_category_list");
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/ext1", "famatel_product_category_list");
        } else {
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/options", "eleman_famatel_product_category_list");
            sAction.dsAdd("set", data.prefix + "/fields/product_group/def/ext1", "eleman_famatel_product_category_list");
        }

        sAction.dsProcess();

    }

    presetParentType(sAction, data) {
        let parentType = sAction.dataGet(data.prefix+"/fields/parent_name/def/parent_type_value")
        if (!parentType){
            sAction.dsClear();
            sAction.dsAdd("set", data.prefix + "/fields/parent_name/def/parent_type_value", 'Accounts')
            sAction.dsProcess();
        }
    }

    getAddress(sAction, data) {
        const parent_id = sAction.dataGet(data.prefix + "/fields/parent_name/def/id_value");
        if (!parent_id){
            return
        }

        const postData = {
            recordId: parent_id,
        }
        // zeptame se na centralu firmy z tyka se
        sAction.rest.post("getAccountsGPS", postData, returnData => {
            if (returnData.status) { // ma centralu, tak vytvorime jmeno z centraly ... centrala se pak doplni v logic hooku
                sAction.dsClear();

                sAction.dsAdd("set", data.prefix + "/fields/location/value", returnData.message.data.address);
                sAction.dsAdd("set", data.prefix + "/fields/location_gpsx/value", returnData.message.data.address_gpsx);
                sAction.dsAdd("set", data.prefix + "/fields/location_gpsy/value", returnData.message.data.address_gpsy);
                sAction.dsAdd("set", data.prefix + "/fields/location_kraj/value", returnData.message.data.address_kraj);
                sAction.dsAdd("set", data.prefix + "/fields/location_obec/value", returnData.message.data.address_obec);
                sAction.dsAdd("set", data.prefix + "/fields/location_okres/value", returnData.message.data.address_okres);
                sAction.dsAdd("set", data.prefix + "/fields/location_psc/value", returnData.message.data.address_psc);
                sAction.dsAdd("set", data.prefix + "/fields/location_street/value", returnData.message.data.address_street);

                let locationItem = { // pro zobrazeni pred ulozenim
                    gpsx: returnData.message.data.address_gpsx,
                    gpsy: returnData.message.data.address_gpsy,
                    kraj: returnData.message.data.address_kraj,
                    obec: returnData.message.data.address_obec,
                    okres: returnData.message.data.address_okres,
                    psc: returnData.message.data.address_psc,
                }
                sAction.dsAdd("set", data.prefix + "/fields/location/def/item", locationItem);

                sAction.dsAdd("set", data.prefix + "/changes/fields/location", returnData.message.data.address);
                sAction.dsAdd("set", data.prefix + "/changes/fields/location_gpsx", returnData.message.data.address_gpsx);
                sAction.dsAdd("set", data.prefix + "/changes/fields/location_gpsy", returnData.message.data.address_gpsy);
                sAction.dsAdd("set", data.prefix + "/changes/fields/location_kraj", returnData.message.data.address_kraj);
                sAction.dsAdd("set", data.prefix + "/changes/fields/location_obec", returnData.message.data.address_obec);
                sAction.dsAdd("set", data.prefix + "/changes/fields/location_okres", returnData.message.data.address_okres);
                sAction.dsAdd("set", data.prefix + "/changes/fields/location_psc", returnData.message.data.address_psc);
                sAction.dsAdd("set", data.prefix + "/changes/fields/location_street", returnData.message.data.address_street);

                sAction.dsProcess();
            }
        }, false)
    }

    setDescription(sAction, data){

        let status = sAction.dataGet(data.prefix + '/fields/status/value');
        let descReq = sAction.dataGet(data.prefix + '/fields/description/def/required');
        let newDescReq = status === 'Held'

        if (newDescReq !== descReq){
            sAction.dsClear();
            sAction.dsAdd("set", data.prefix + "/fields/description/def/required", status);
            sAction.dsProcess();
        }
    }

    setAcc(sAction, data, parent_id, setName = true){
        const postData = {
            recordId: parent_id,
        }
        // zeptame se na centralu firmy z tyka se
        sAction.rest.post("getAccountsHQ", postData, returnData => {
            sAction.dsClear();
            if (returnData.status) {
                // muzeme ulozit primo na FE, jelikoz narozdil od cematu zde neni planovaci kalendar
                sAction.dsAdd("set", data.prefix + "/fields/parent_company_name/value", returnData.message.data.name);
                sAction.dsAdd("set", data.prefix + "/fields/parent_company_name/def/id_value", returnData.message.data.id);

                sAction.dsAdd("set", data.prefix + "/changes/fields/relate/parent_company_name", returnData.message.data.name);
                sAction.dsAdd("set", data.prefix + "/changes/fields/parent_company_id", returnData.message.data.id);
            }
            else{
                // pokud neni centrala tak nechame aktualne vybranou firmu coripo/eleman#299

                // const parent_name = sAction.dataGet(data.prefix + "/fields/parent_name/value");
                // const parent_name_id = sAction.dataGet(data.prefix + "/fields/parent_name/def/id_value");

                // if(parent_name && parent_name_id){
                    // sAction.dsAdd("set", data.prefix + "/fields/parent_company_name/value", '');
                    // sAction.dsAdd("set", data.prefix + "/fields/parent_company_name/def/id_value", '');

                    // sAction.dsAdd("set", data.prefix + "/changes/fields/relate/parent_company_name", '');
                    // sAction.dsAdd("set", data.prefix + "/changes/fields/parent_company_id", '');
                // }
            }
            sAction.dsProcess();
        }, false)
    }

    setRelationAndFocus(sAction, data, parent_id){
        sAction.rest.get(`getRelationAndFocus?accountID=${parent_id}`, (response) => {
            let relation_enum = '';
            let focus_enum = '';
            if(response.status){
                relation_enum = response.message.data.relation_enum;
                focus_enum = response.message.data.focus_enum;
            } else {
                console.error('Could not find data for setRelationAndFocus', response);
            }
            sAction.dsClear();
            sAction.dsAdd('set', `${data.prefix}/fields/focus_enum/value`, focus_enum);
            sAction.dsAdd('set', `${data.prefix}/changes/fields/focus_enum`, focus_enum);
            sAction.dsAdd('set', `${data.prefix}/fields/relation_enum/value`, relation_enum);
            sAction.dsAdd('set', `${data.prefix}/changes/fields/relation_enum`, relation_enum);
            sAction.dsProcess();
        }, false);
    }

}
