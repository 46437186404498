import PropTypes from 'prop-types';
import React, {Component} from 'react';
import sAction from 'sAction';
import moment from 'moment';
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            info: null,
            logId: Math.random().toString(16).slice(2).substring(0, 8),
            visibleJson: false,
            isPWA: false
        };
        this.clickStack = [];
        this.clickCnt = 0;
    }

    componentDidMount() {
        window.addEventListener("click", this.handleMouseClick);

        const isInStandaloneMode = () => (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
        if (isInStandaloneMode()) {
            this.setState({isPWA: true});
        }
    }

    handleMouseClick = (e) => {
        this.clickStack.push({
            time: moment().format('YYYY-MM-DD HH:mm:ss'),
            target: e.target.innerText ? e.target.innerText : e.target.outerText,
            targetHtml: e.target.innerHTML ? e.target.innerHTML : e.target.outerHTML,
            className: e.target.className,
            item: e.target.localName,
            url: window.location.href,
            order: this.clickCnt,
            click: {
                pageX: e.pageX,
                pageY: e.pageY
            }
        });

        if (this.clickStack.length > 20) {
            this.clickStack.shift();
        }
        this.clickCnt++;
    }

    componentDidCatch(error, info) { // Display fallback UI
        this.setState({hasError: true, error: error, info: info});
        // You can also log the error to an error reporting service
        this.logError(error, info);
        console.log('[ info ] logged fatal error', error, info);
        console.log('[ info ] Error logId: ', this.state.logId);
    }

    logError = (error, info) => {
        try { 
            // let store = sAction.store.getState().appReducer;
            let store = sAction.dataGet("conf");
            if (store) {
                store = store.toJS();
            }

            let networkStack = localStorage.getItem('networkStack');
            if (networkStack) {
                networkStack = JSON.parse(networkStack);
            }

            const localStorageData = {};
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                const lcItem = localStorage.getItem(key).trim();
                if (lcItem !== null && lcItem && lcItem.length > 0 && key !== 'networkStack') {
                    try {
                        localStorageData[key] = JSON.parse(lcItem);
                    } catch (e) {
                        console.log('[ info ] Error parsing local storage item', key, lcItem);
                        localStorageData[key] = lcItem;
                    }
                }
            }

            const data = {
                logId: this.state.logId,
                error: error.toString().replace(/'/g, '"'),
                info: info,
                stack: error.stack.replace(/\n {2}/g, ' '),
                message: error.message,
                url: window.location.href,
                pwa: this.state.isPWA,
                user: sAction.dataGet('conf/user/id'),
                browser: window.navigator.userAgent,
                resolution: window.screen.width + 'x' + window.screen.height,
                history: window.history,
                language: navigator.language,
                online: navigator.onLine,
                view: sAction.dataGet('servisCommon') ? "servis" : "coripo",
                clickTrace: this.clickStack,
                networkStack: networkStack,
                history: sAction.dataGet('history/urls'),
                // localStorage: localStorageData,
                store: store
            };

            console.log('[ info ] logged fatal error', data);

            if (!networkStack) {
                networkStack = [];
            }
            if (networkStack.length > 5) {
                networkStack.shift();
            }
            networkStack.push("[ error ] log id: " + this.state.logId);
            localStorage.setItem('networkStack', JSON.stringify(networkStack));

            sAction.rest.post('logError', data, (returnData) => {
                if (returnData.status) {
                    console.log('[ OK ] logError', returnData);
                } else {
                    console.log('[ error ] logError failed: ', returnData);
                }
            }, false);
        } catch (e) {
            console.log('[ error ] logError failed: ', e);
        }
    }

    refreshPage = () => {
        window.location.reload(false);
    };

    toggleJson = () => {
        this.setState({
            visibleJson: !this.state.visibleJson
        });
    };

    render() {
        if (this.state.hasError) {
            const error = this.state.error;
            const info = this.state.info;

            let networkStack = localStorage.getItem('networkStack');
            if (networkStack) {
                networkStack = JSON.parse(networkStack);
            }

            const localStorageData = {};
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                const lcItem = localStorage.getItem(key).trim();
                if (lcItem !== null && lcItem.length > 0) {
                    try {
                        localStorageData[key] = JSON.parse(lcItem);
                    } catch (e) {
                        console.log('[ info ] Error parsing local storage item', key, lcItem);
                    }
                }
            }

            const data = {
                logId: this.state.logId,
                error: error.toString().replace(/'/g, '"'),
                info: info,
                stack: error.stack,
                message: error.message,
                url: window.location.href,
                pwa: this.state.isPWA,
                user: sAction.dataGet('conf/user/id'),
                browser: window.navigator.userAgent,
                resolution: window.screen.width + 'x' + window.screen.height,
                history: window.history,
                language: navigator.language,
                online: navigator.onLine,
                view: sAction.param.isServis ? "servis" : "coripo",
                clickTrace: this.clickStack,
                // networkStack: networkStack,
                history: sAction.dataGet('history/urls'),
                // localStorage: localStorageData,
            };


            return (
                <div className="errorPage">
                    <a href="/"><img src="img/coripo_logo_dark.svg" alt="crm coripo logo" className="coripoLogo"/></a>

                    <div className="errorPageContent">

                        <img src="img/errorPageBackground.png" alt="crm coripo" className="errorPageBackground"/>

                        <h1>Nepodařilo se zpracovat data.</h1>
                        <h3 onClick={
                            this.toggleJson
                        }>
                            id: {
                            this.state.logId
                        } </h3>
                        <br/>

                        <pre className={'jsonOutput' + (this.state.visibleJson ? ' open' : '')}>{JSON.stringify(data, undefined, 4)}</pre>

                        <div className="errorPageButtonsGrid">
                            <button className="reloadBtn"
                                onClick={
                                    this.refreshPage
                            }>
                                <span className="iconfas-reload"></span>
                                Zkusit znovu
                            </button>
                            <a href={
                                    sAction.param.isServis ? "/servis" : "/"
                                }
                                className='backBtnLink'>
                                <button className="reloadBtn invertedBtn">
                                    <span className="iconfas-back"></span>
                                    Zpět
                                </button>
                            </a>
                        </div>
                    </div>

                    <img src="img/pomeranian_V3.svg" alt="crm coripo pes" className="errorPageImg"/>
                </div>
            );
            // use this for normal response
            // return this.props.children;
        }
        return this.props.children;
    }

}

export default ErrorBoundary;

ErrorBoundary.propTypes = {
    children: PropTypes.any
};
