export default function printFileConfirmQuotes(params) {
  //popup, kde se zeptame na typ faktury
  const { module } = params;

  if (this.dataGet(params.prefix+'/fields/lang_select/value')){
    params.selectedLang = this.dataGet(params.prefix+'/fields/lang_select/value');
  }

  this.popup('PrintSelectQuotes', params);
}
