import Varchar from '../Varchar/Varchar';
import React from 'react';

export default class AcmAddress extends Varchar {
    /**
     * @return {JSX.Element}
     */
    getValue() {
        const data = this.props;
        let value = data.value;
        const item = data?.def?.get('item');
        const obec = item?.get('obec') ?? '';
        // const cast_obce = item?.get('cast_obce') ?? '';
        const psc = item?.get('psc') ?? '';
        const kraj = item?.get('kraj') ?? '';
        let obecValue = '';
        if (obec) {
            obecValue += obec;
            if (psc) {
                obecValue += ' ' + psc;
            }
            if (kraj) {
                obecValue += '\n ' + kraj;
            }
        }

        const xVal = item?.get('gpsX') ?? '';
        const yVal = item?.get('gpsY') ?? '';

        if (value != null && value.trim() === '' && xVal != null && yVal != null) {
            if (!xVal && !yVal) {
                value = (<></>);
            } else {
                value = (
                    <ul>
                        <li>{'Lat: ' + yVal}</li>
                        <li>{'Lng: ' + xVal}</li>
                    </ul>
                );
            }
        } else {
            value = (
                <div className="acmAddressSpanContainer">
                    <span>{obecValue}</span>
                    <span>{value}</span>
                </div>
            );
        }

        return <div className="overflowEllipsis">{value}</div>;
    }
}
