import React from "react"
import sAction  from "sAction";
import DropDown from "../DropDown/DropDown"


export default class DetailApiButtons extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      address: ""
    }
  }


  ares = () => { //vyhledavani firmy podle ico
    sAction.ares({ ico: this.props.value }, this.props.prefix)
  }

  ares2 = () => { //vyhledavani firmy podle jmena
    sAction.ares({ name: this.props.value }, this.props.prefix)
  }

  finstat = () => { //vyhledavani firmy podle ico pro Slovensko
      console.log('DetailApiButtons Finstat', parent);
      sAction.finstat({ ico: this.props.value}, this.props.prefix);
  }
  finstat2 = () => { //vyhledavani firmy podle ico pro Slovensko
      console.log('DetailApiButtons Finstat2', parent);
      sAction.finstat({ name: this.props.value}, this.props.prefix)
  }

  isir = () => {
    sAction.isir({ ic: this.props.value }, this.props.prefix)
  }

  checkDPH = () => {
    sAction.checkDPH({ dic: this.props.value }, this.props.prefix)
  }

  checkDIC = () => {
    sAction.checkDIC({ dic: this.props.value }, this.props.prefix)
  }

  SearchOnGoogle = () => {
    window.open(
      "https://www.google.cz/search?q=" + encodeURI(this.props.value)
    )
  }

  SearchOnGoogleMaps = () => {
    const address = this.getAddress()
    window.open("https://www.google.cz/maps/place/" + encodeURI(address.text))
  }

  getAddress = () => {
    let valid = true
    //potrebujeme ulici, psc, mesto

    const n = this.props.name
    const type = n.slice(0, n.lastIndexOf("_")) // [billing|shipping|primary]_adress

    const f = sAction.dataGet(this.props.prefix + "/fields")

    //psc musi byt az posledni, jinak to vugtk nenajde
    const street = f.get(type + "_street").value
    let city = f.get(type + "_city").value
    //odstraneni cisla z mesta, napr Praha 10->Praha, overeni adresy jinak nefunguje
    if (city) city = city.replace(/\s+[0-9]+/, "")
    const postalcode = f.get(type + "_postalcode").value

    //nemuzeme overovat validitu, kdyz nezname ulici nebo mesto
    if (!street || !city) valid = false

    let address = street + ", " + city + ", " + postalcode

    //uprava pri chybejicim psc, jinak vugtk nic nenajde
    address = address.trim().replace(/^, |,$/g, "")

    return { valid, text: address }
  }

  // kontrola adresy, jestli ji vyhleda
  // http://www.vugtk.cz/euradin/ruian/rest.py
  checkAddress = () => {
    this.setState({ address: <div className="spinnerIcon" style={{  height: 17,
        width: 20}}/> })
    const address = this.getAddress()
    if (!address.valid) {
      this.setState({ address: <div className=" checkAdressIconFalse icon-detailCancel"/> })
      return
    }
    sAction.rest.post(
      "detailapi/checkaddressnew",
      encodeURI(address.text),
      data => {
        //json od nich neni vzdy validni - validace chyby
        //oprava: polozky nejsou vzdy oddeleny carkou
        data = data.replace(/"\s+"/g, "\",\"")
        //oprava: cislo popisne maji jako int, napr. 44a => "44a"
        data = data.replace(/\s+([0-9]+[a-z]+),/g, " \"$1\",")

        try {
          data = JSON.parse(data)
        } catch (e) {
          this.setState({ address: <div className=" checkAdressIconFalse icon-detailCancel"/> })
          return false
        }

        const count = Object.keys(data).length

        if (!count) {
          //nenalezeno
          this.setState({ address:  <div className="checkAdressIconFalse icon-detailCancel"/> })
        } else if (count === 1) {
          //ok
          this.setState({ address:  <div className="checkAdressIcon icon-detailConfrim"/> })
        } else {
          //nalezeno vice
          this.setState({ address: "\u2753" })
          //zobraz popup okno
          const n = this.props.name
          const type = n.slice(0, n.lastIndexOf("_")) // [billing|shipping|primary]_adress

          sAction.popup("ChooseAddressPopup", {
            data,
            type,
            callback: this.updateFields
          })
        }
      }
    )
  }
  callTo = () => {
    const value = this.props.value
    console.log(value);
  }

  updateFields = (data) => {
    sAction.updateFields(data, this.props.prefix)
  }

  render() {

    const prefix = this.props.prefix;
    const panelType = sAction.dataGet(prefix+"/type");
    let content = "";

    const allowedInRightPanel = ["phoneApi"] // array poli pro ktera se maji zobrazovat tlacitka i v rightPanelu ne jen v detailView
    if (allowedInRightPanel.includes(this.props.type) || panelType !== "rightPanel"){
      switch (this.props.type) {
        case "ApiName": //cz
          content = (
            <React.Fragment>
              <a onClick={this.ares2} className="icon-ares" tabIndex={0} title={sAction.translate("LBL_ARES_READ_FROM_ARES")}>
              </a>
              <a onClick={this.SearchOnGoogle} className="icon-google" title={sAction.translate("LBL_ARES_SEARCH_GOOGLE")} tabIndex={1}>
              </a>
            </React.Fragment>
          );
        break;
        case "ApiNameSK":
          content = (
            <React.Fragment>
              <a className="icon-finstat" onClick={this.finstat2} tabIndex={0} title={sAction.translate("LBL_ARES_READ_FROM_FINSTAT")}/>
              <a className="icon-google" onClick={this.SearchOnGoogle} title={sAction.translate("LBL_ARES_SEARCH_GOOGLE")} tabIndex={1}/>
            </React.Fragment>
          );
          break;
        case "ApiNameOthers":
          content = (
            <a className="icon-google" onClick={this.SearchOnGoogle} title={sAction.translate("LBL_ARES_SEARCH_GOOGLE")} tabIndex={1}/>
          )
          break;
        //IČO
        case "ApiICO":
          content = (
            <React.Fragment>
              <a onClick={this.ares} tabIndex={0} className="icon-ares" title={sAction.translate("LBL_ARES_READ_FROM_ARES")}/>
              <a onClick={this.isir} className="icon-isir" tabIndex={0} title="ISIR"/>
            </React.Fragment>
          )
          break;
        case "ApiICOSlovakia":
          content = (
            <a className="icon-finstat" onClick={this.finstat} tabIndex={0} title={sAction.translate("LBL_ARES_READ_FROM_FINSTAT")}/>
          )
          break
        //DIČ
        case "ApiDIC":
          content = (
            <React.Fragment>
              <a onClick={this.checkDIC} className="icon-dic" tabIndex={0} title={sAction.translate("LBL_CHECK_DIC", "Accounts")}/>
              <a onClick={this.checkDPH} className="icon-neplatce" tabIndex={0} title={sAction.translate("LBL_CHECK_DPH", "Accounts")}/>
            </React.Fragment>
          )
          break
        //ULICE
        //todo: mozna to bude treba brat jen podle te pripony _address_street, zatim nevim
        case "ApiStreet":
          content = (
            <React.Fragment>
              <a onClick={this.checkAddress} tabIndex={0} title={sAction.translate("LBL_CHECK_ADDRESS", "Accounts")}>
              {this.state.address == "" ? 
              <div className="icon-Question" />
              :
              this.state.address
              }
              </a>
              <a onClick={this.SearchOnGoogleMaps} tabIndex={0} title={sAction.translate("LBL_CHECK_MAP", "Accounts")}>
                <div className="icon-Location"/>
              </a>
            </React.Fragment>
          )
          break
        case "phoneApi":
          const value = this.props.value
          if(value == ""){
            content = ""
          }else{
            if(sAction.plugins["clicktocall"] !== undefined){
              content = <a className="icon-createCall" onClick={() => sAction.clickToCall(this.props.prefix,value)} tabIndex={0} title={sAction.translate("LBL_CALL_TO")}>
              </a>
            }else{
              content = <a className="icon-createCall" href={"callto:"+value} onClick={this.callTo} tabIndex={0} title={sAction.translate("LBL_CALL_TO")}>
              </a>
            }
          }
      }
    }
    return <div className="detailApiButtonContainer">{content}</div>
  }
}
