
export default function acmOrderExportPohoda(data) {
    let msg = "Odeslat objednávku do Pohody?"

    this.confrim(msg, () => {
        this.popupHide();
        this.load();

        this.rest.post("acmOrderExportPohoda", data, resp => {
            this.unLoad();

            if (resp.status) {
                this.alert(resp.message.text)
            }
            else {
                this.error(resp.errorMessage.text);
            }
        });

    });
    
}
