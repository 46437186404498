/**
 * Kontrola na vyplnenost povinnych fieldu
 * vraci true/false podle toho, jestli byly vyplneny vsechny povinne polozky...
 * @param prefix
 * @returns {boolean}
 */

export default function requiredFieldsCheck(prefix) {
  var fields = this.dataGet(prefix + "/fields");

  //ziskani poli ve skrytych tabech pro nasledene ignorovani pri overeni povinnych poli
  let ignoredFields = [];
  const tabs = this.dataGet(prefix + "/tabs");
  tabs.forEach((tab, index) => {
    if (tab.hidden) {
      tab.rows.forEach((row, index) => {
        row.forEach((line, index) => {
          ignoredFields.push(line);
        });
      });
    }
  });

  let isOK = true;
  fields.valueSeq().forEach(data => {
    if (!isOK) {
      return;
    }
    if (!data.def) return;
    if (ignoredFields.includes(data.name)) {
      return;
    }
    if (data.def.get("type") === "relate") {
      return;
    }
    if (
      data.def.get("required") === true &&
      (data.value === "" || data.value === null)
    ) {
      isOK = false;
      return;
    } else if (
      data.def.get("required") === true &&
      (data.value != "" || data.value != undefined) &&
      data.customClass == "error"
    ) {
      isOK = false;
      return;
    }
  });

  return isOK;
}

