export default function updateFields(data, prefix = "view") {
    this.dsClear();

    const existing_fields = [...this.dataGet(prefix + "/fields").keys()];
    const existing_fields_vals = this.dataGet(prefix + "/fields");

    let count = 0;
    data.forEachObject((value, key) => {
        count++;
        if (value !== "" && value != undefined && existing_fields.indexOf(key) !== -1) {
            if (key === "email1") {
                if (value){
                    const emailsWay = prefix + "/fields/email1/def/emails";
                    const emails = this.dataGet(emailsWay);
                    let email_i = 0;
                    value.forEach((email, i) => {
                        let email_exists = emails.find(e => e.get("email_address") === email);
                        if (!email_exists) {
                            this.dsAdd("set", emailsWay + "/" + (emails.size + email_i) + "/error", false);
                            this.dsAdd("set", emailsWay + "/" + (emails.size + email_i) + "/email_address", email);
                            email_i += 1;
                        }
                    });
                    this.dsAdd("set", prefix + "/fields/email1/value", true);
                    this.dsAdd("set", prefix + "/fields/email1/def/edit", true);
                    this.dsAdd("set", prefix + "/changes/fields/email1", true);
                }
            } else {
                if(!existing_fields_vals?.get(key)?.value || key === "name") {
                    this.dsAdd("set", prefix + "/fields/" + key + "/value", value);
                    this.dsAdd("set", prefix + "/changes/fields/" + key, value);
                }
            }
        } else {
            // console.log("ignoruji field " + key);
        }
        this.dsProcess();
    });

    this.dsAdd("set", "conf/load", false);
    this.dsAdd("set", prefix + "/changes/forceChange", true);

    const customData = this.dataGet(prefix + "/customData");
    if(!customData) {
        this.dsAdd("set", prefix + "/customData", {
            writeNoUpdate: false
        });
    } else {
        this.dsAdd("set", prefix + "/customData/writeNoUpdate", false);
    }

    this.dsProcess();
}
