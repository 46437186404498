import {Map} from 'immutable'

export default function openRelatePopup(paramData, callback) {
  this.popup("load");
  setTimeout(() => {
    if (paramData.module == "" || paramData.module == undefined) {
      console.log("není specifikován modul");
      return;
    }
    const restData = {
      limit: 25,
      offset: 0,
      order: [{
        module: paramData.module,
        field: "date_entered",
        sort: "asc",
      }],
      filter: {}
    };
    if (paramData.defaultFilter) {

      let actDefaultFilter = this.parseDefaultFilter(paramData.defaultFilter);

      if(actDefaultFilter.operands.length !== 0){
        restData.filter = actDefaultFilter;
      }

    }

    restData.listview_type = 'popup';

    // na odfiltrovani as company eleman / famatel prodku a stromu
    let productTree = this.dataGet("view/customData/productTree")
    productTree = productTree ? productTree.toJS() : null;

    if (productTree) {
      restData.callerId = productTree.callerId
      restData.callerModule = productTree.callerModule
      restData.asCompany = productTree.asCompany
    }

    this.rest.post("list/" + paramData.module, restData, data => {
      const id = this.getNewPopupId();

      this.dsClear();

      const prefix = "conf/popup" + id + "/data";

      if (paramData.module == "ProductTemplates") {
        var akceTree = {
          type: "PRODUCTCATEGORIESTREE",
          content: data.productCatogories,
          prefix: "conf/popup" + id + "/data/categories"
        };
        this.dsAddCustom(akceTree);
      }

      var content = this.listContent(data);

      var akce = {
        type: "PAGELOADLIST",
        page: paramData.module,
        content: content,
        listViewPrefix: prefix + "/listView",
        function: this
      };
      this.dsAdd("set", prefix + "/listView", {}, "ListView");
      this.dsAddCustom(akce);
      this.dsAdd("set", prefix + "/listView/type", "popup");
      this.dsAdd("set", prefix + "/header", this.translate("LBL_MODULE_NAME", paramData.module));
      this.dsAdd("set", prefix + "/callback", callback);
      this.dsAdd("set", prefix + "/listView/type", "popup");
      if (paramData.selectedActive != undefined) {
        this.dsAdd(
          "set",
          prefix + "/listView/selectedActive",
          paramData.selectedActive
        );
      }
      if (paramData.defaultFilter) {

      paramData.defaultFilter.forEachObject(element => {

          if (element.operandType != "relate") {
            this.dsAdd(
              "set",
              prefix + "/listView/defaultFilter/" + element.field,
              element
            );
          }
          else{
            var pomObj = {};
            pomObj[element.name] = element;
            this.dsAdd(
              "set",
              prefix + "/listView/defaultFilter",
              Map(pomObj)
            );
          }
        });
      }

      if(paramData.contentName){
        this.dsAdd("set", "conf/popup" + id + "/content", paramData.contentName);
      }else{
        this.dsAdd("set", "conf/popup" + id + "/content", "relateListView");
      }

      if(paramData.customData){
        paramData.customData.forEachObject((value,key) => {
          this.dsAdd("set", prefix + "/"+key, value);
        })
      }

      
      this.dsProcess();
    });
  }, 300);
}
