/**
 *
 * @param {*} item
 * @returns {*|string|string}
 */
export default function getStreetlabelAcmAddress(item) {
  if (!item) {
    return '';
  }
  if (item.ulice == null) {
    if (item.cislo_dom) {
      return item.cislo_dom ?? '';
    }
    return '';
  }

  let label = `${item.ulice} ` ?? '';
  if (item.cislo_dom !== '' && (item.cislo_orient !== '')) {
    label += `${item.cislo_dom}/${item.cislo_orient}`;
  } else {
    label += item.cislo_dom;
    label += item.cislo_orient;
  }
  return label.trim();
}
