export default function cenotvorbaChange(type, params){

    console.log(type, params)

    
    this.dsClear();
    if(type == "quote"){

        let pomVal = params.value;
        if(!pomVal){
            return;
        }

        const productData = this.dataGet(params.way+"/customData/productData");
        productData.groups.forEach(((group, gInd) => {
        
            this.dsAdd("set", params.way+"/customData/productData/groups/"+gInd+"/"+params.field, pomVal);
            if(params.field === "cenotvorba_discount" || 
            params.field === "cenotvorba_discount_select"
            ){
                cenotvorbaChangeGroup(this, group ,{way: params.way+"/customData/productData/groups/"+gInd , value:params.value, field:params.field});
            }  
        }));
    }
    else if(type == "group"){

        let pomVal = params.value;
        if(!pomVal){
            pomVal = "";
        }
        
        this.dsAdd("set", params.way+"/"+params.field, pomVal);

        if(pomVal !== ""){
            if(params.field === "cenotvorba_discount" || 
                params.field === "cenotvorba_discount_select"
            ){
                const group = this.dataGet(params.way);
                cenotvorbaChangeGroup(this, group, params);
            }
        }
        
    }

    this.dsProcess();
}

function cenotvorbaChangeGroup(sAction, group, params){
    //discount_amount
    //discount_select
    let pomField = "";
    if(params.field === "cenotvorba_discount"){
        pomField = "discount_amount"; 
    }
    else if(params.field === "cenotvorba_discount_select"){
        pomField = "discount_select"; 
    }

    group.lines.forEach((line, lInd) => {
        sAction.dsAdd("set", params.way+"/lines/"+lInd+"/"+pomField, params.value);
    });

}