import React from 'react'
import PureComponent from '../../pure'
import  sAction  from "sAction";

class InvRow extends PureComponent{

    removeInvitee(type, ind){
        sAction.confrim(sAction.translate("LBL_BUTTON_CONFIRM_REMOVE_INVITEE"), () => {
            let prefix = this.props.prefix + "/relatedInv/"+type;
            if(prefix.indexOf("view") === -1){
                sAction.dataDelete(prefix, ind);
            }
            else{
                sAction.updateDetailCustom("delItem", {way: prefix, index: ind});
            }
            sAction.popupHide();
        });
    }

    render(){
        const data = this.props.data;
        let icon = null;
        const ind = this.props.ind;
        const type = this.props.type;
        let name = <a href={`#detail/${type}/${data.id}`}>{data.name}</a>
        const isPopupOpen = sAction.getNewPopupId();
        let openRecordIcon = <div onClick={() => sAction.rightPanelDetail(type, data.id, {})} className="icon-eye listViewEyeIcon" />;
        if (isPopupOpen) {
          name = <a target={"_blank"} href={`#detail/${type}/${data.id}`}>{data.name}</a>
          openRecordIcon = <a href={`#detail/${type}/${data.id}`} target={'_blank'}><div className={"icon-newTab listViewEyeIcon"}/></a>
        }

        switch(type){
            case "Users":
                icon = <div className="icon-avatar calModuleIcon" />
                break;
            case "Contacts":
                icon = <div className="icon-Contacts calModuleIcon" />
                break;
            case "Leads":
                icon = <div className="icon-Leads calModuleIcon" />
                break;
            case "Accounts":
                icon = <div className="icon-accounts calModuleIcon" />
                break;
        }

        return (
            <tr>
                <td>
                    {icon}
                </td>
                <td>{sAction.app_strings["dom_meeting_accept_status"][data.accept_status]}</td>
                <td title={data.name}>
                  { ! this.props.prefix.includes('rightPanel') && openRecordIcon}
                  {name}
                </td>
                <td>
                    {data.title}
                </td>
                <td>
                    {data.email}
                </td>
                <td className={"phoneNumber"}>
                    {data.phone_work}
                    {data.phone_work != "" &&
                    <a className="invitesTableCallTo" href={"callto:"+data.phone_work} tabIndex="0" title={sAction.translate("LBL_CALL_TO")}><div style={{display:"inline"}} className="icon-Calls"></div></a>
                    }
                </td>
                <td className={"phoneNumber"}>
                    {data.phone_mobile}
                    {data.phone_mobile != "" &&
                    <a className="invitesTableCallTo" href={"callto:"+data.phone_mobile} tabIndex="0" title={sAction.translate("LBL_CALL_TO")}><div style={{display:"inline"}} className="icon-Calls"></div></a>
                    }
                </td>
                <td>
                    <a onClick={() => this.removeInvitee(type, ind)} className="calDeleteIcon" title={sAction.translate("LBL_DELETE")} ><div className="icon-deleteIcon"></div></a>
                </td>
            </tr>
        )
    }
}
export default InvRow
