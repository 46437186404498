import sAction from "../../../action";
export default class QProductStock {

    checkProductAvailability = (item = null, way = null) => {

        let quoteId = sAction.dataGet("view/id")
        let getGroups = sAction.dataGet("view/customData/productData/groups")
        let groups = (getGroups && Array.isArray(getGroups)) || !getGroups ? getGroups : getGroups.toJS()
        let linesArray = []

        groups?.forEach((group, group_i) => {
            group.lines.forEach((line, line_i) => {
                if (item && way === `view/customData/productData/groups/${group_i}/lines/${line_i}`){
                    line.actual_product_template_id = item.id
                }
                linesArray.push(line)
            })
        })

        sAction.rest.post('getProductStock', { lines: linesArray, quoteId: quoteId }, returnData => {
            if (!returnData.status) {
                sAction.error("Nastala chyba při načítání dostupnosti produktů.")
                return
            }

            sAction.dsClear()
            let i = 0
            groups?.forEach((group, group_i) => {
                group.lines.forEach((line, line_i) => {

                    let thisLinesArray = linesArray.filter(item => item.actual_product_template_id === line.actual_product_template_id)
                    let countThisQtyProducts = 0
                    thisLinesArray.forEach(item => {
                        countThisQtyProducts += parseInt(item.quantity)
                    })

                    const id = sAction.dataGet(`view/customData/productData/groups/${group_i}/lines/${line_i}/id`)
                    const product_template_id = sAction.dataGet(`view/customData/productData/groups/${group_i}/lines/${line_i}/product_template_id`)
                    const name = sAction.dataGet(`view/customData/productData/groups/${group_i}/lines/${line_i}/name`)
                    const no_template = sAction.dataGet(`view/customData/productData/groups/${group_i}/lines/${line_i}/no_template`)

                    if ((product_template_id || name) && no_template === "0" || no_template === null) {
                        if (id && id !== returnData.message.data[i].id) {
                            sAction.error("Nastala chyba při načítání dostupnosti produktů.")
                            return
                        }
                        // old way wich substracts selected qty from total stock
                        // sAction.dsAdd("set", `view/customData/productData/groups/${group_i}/lines/${line_i}/shown_qty_in_stock`, returnData.message.data[i].qty_in_stock - countThisQtyProducts)
                        sAction.dsAdd("set", `view/customData/productData/groups/${group_i}/lines/${line_i}/shown_qty_in_stock`, returnData.message.data[i].qty_in_stock)
                    }

                    i++
                })
            })
            sAction.dsProcess()

        }, false)


    }
}