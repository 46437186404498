import React from "react";
import sAction from "sAction";

// relate more detail config for detail
import relateMoreDetailConfig from '../../_custom/config/relateMoreDetailConfig';
import AcmAddress from "ROOT/src/components/detailView/detailViewFields/ValuesFields/AcmAddress/AcmAddress";

export default function DetailViewSpanValue(props) {
  const data = props.data;
  var value = null;

  // zobrazit vic informaci v naseptavaci (modul: [fields])
  const moreDetail = relateMoreDetailConfig();

  // _________________________ READONLY
  const readonly = props.readonly;
  const readonlyField = data.def.get("readonly");
  var readonlyState = false;
  if (props.acl != undefined && props.acl.get("edit") == false) {
    readonlyState = true;
  } else if (readonly === true && readonlyField === false) {
    readonlyState = false;  // enable override readonly if field is explicitly not readonly
  } else if (readonly == true) {
    readonlyState = true;
  } else if (readonlyField == true) {
    readonlyState = true;
  }
  // __________________________ READONLY END

  const recordModule = props.module;
  var rawValue = data.value;
  let nullValue = false;
  let style = null;
  switch (props.fieldType) {
    case "Multienum":
      var Multi = sAction.fields["Multienum"];
      value = (
        <Multi
          rowWay={props.rowWay}
          index={props.index}
          way={props.way}
          prefix={props.prefix}
          data={data}
          newRecord={props.newRecord}
          readonly={readonlyState}
        />
      );

      break;
    //_____________________________________________________________________________________
    case "Enum":
      try {
        if (data.def.get("customOptions")) {
          var customOptions = data.def.get("customOptions").toJS();
          customOptions.forEachObject((option) => {
            if (option.value == data.value) {
              value = option.label;
            }
          });
        } else {
          value = sAction.app_strings[data.def.get("options")][data.value];
          if (value == undefined) {
            rawValue = null;
          }
          if (
            data.def.get("colors") &&
            sAction.app_strings[data.def.get("options")] &&
            sAction.app_strings[data.def.get("colors")][data.value]
          ) {
            style = {
              backgroundColor:
                sAction.app_strings[data.def.get("colors")][data.value],
            };
          }
        }
      } catch (e) {
        console.log("nenalezen čísleník " + data.def.get("options"));
        value = null;
        rawValue = null;
      }
      break;
    //_____________________________________________________________________________________
    case "AcmDynamicEnum":
      try {
        const options =
          sAction.app_strings["dynamicEnum"][data.def.get("options")];
        value = options[data.value];
        if (value == undefined) {
          rawValue = null;
        }
      } catch (error) {
        console.log("nenalezen čísleník pro dynamický enum");
        value = null;
        rawValue = null;
      }
      break;
    // _______________________________________________________________
    case "Mc_lists":
      const options = data.def.get("options");
      value = options.get(data.value);
      if (value == undefined) {
        rawValue = null;
      }
      break;
    // _______________________________________________________________
    case "Url":
      var dvalue = data.value;
      var urls = [];
      if (dvalue) {
        urls = dvalue.split("|");
      }

      if (!dvalue) nullValue = true;

      value = [];
      urls.forEach((v, i) => {
        /*
        if (v && !v.startsWith("http")) {
          v = "http://" + v;
        }
        */

        value.push(
          <div key={i} className="breakAfter">
            <a
              href={v}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {v}
            </a>
          </div>
        );
      });
      value = <div>{value}</div>;

      break;
    //_____________________________________________________________________________________
    case "Datetimecombo":
      if (data.value != "" && data.value != null) {
        value = sAction.dateFormat(data.value);
      }

      break;
    //_____________________________________________________________________________________
    case "Datetime":
      if (data.value != "" && data.value != null) {
        value = sAction.dateFormat(data.value);
      }

      break;
    //_____________________________________________________________________________________
    case "Date":
      if (data.value != "" && data.value != null) {
        value = sAction.dateFormat(data.value);
      }

      break;
    //_____________________________________________________________________________________
    case "Relate":
      if (data.value != "" && data.value != null) {
        const module = data.def.get("module");
        const id = data.def.get("id_value");

        if (moreDetail[module]) {
          const searchData = {
            fields: ["id"],
            getFields: ["id", "name"],
            value: id,
            module,
          };

          if (moreDetail[module]) {
            searchData.getFields = searchData.getFields.concat(moreDetail[module])
          }

          setTimeout(() => {
            sAction.quickSearch(searchData, (retData) => {

              if (retData.length > 0) {  // pokud je vysledek
                let moreDetailLine = ""
                if (moreDetail[module] && moreDetail[module].length !== 0) {
                  moreDetailLine = " - "
                  moreDetail[module].forEach((e, i) => {
                    if (i != moreDetail[module].length - 1) {
                      moreDetailLine += `${retData[0][e]}, `
                    } else {
                      moreDetailLine += `${retData[0][e]}`
                    }
                  })
                }

                let prevName = sAction.dataGet(props.way + "/value");
                let relateMoreInfo = sAction.dataGet(props.prefix + "/customData/relateMoreInfo/" + data.name);

                if (!relateMoreInfo && !prevName.includes(moreDetailLine)) {
                  let customData = sAction.dataGet(props.prefix + "/customData");
                  sAction.dsClear();
                  sAction.dsAdd("set", props.way + "/value", prevName + moreDetailLine);
                  if(customData){
                    sAction.dsAdd("set", props.prefix + "/customData/relateMoreInfo", {
                      [data.name]: true
                    });
                  } else {
                    sAction.dsAdd("set", props.prefix + "/customData", {
                      relateMoreInfo: {
                        [data.name]: true
                      }
                    });
                  }
                  
                  sAction.dsProcess();

                }
              }
            });
          }, 0);
        }


        // const module = data.def.get("module");
        const isPopupOpen = Boolean(sAction.getNewPopupId());
        let relateLink = (<a onClick={(e) => {
          e.stopPropagation();
        }}
          href={
            "#detail/" +
            data.def.get("module") +
            "/" +
            data.def.get("id_value")
          }
        >
          {sAction.decodeHTMLEntities(data.value)}
        </a>
        );

        let openRecordIcon = (
          <div
            onClick={(e) =>
              props.show(e, {
                module: data.def.get("module"),
                id: data.def.get("id_value"),
              })
            }
            className="icon-eye detailViewFieldIconEye"
          />
        );

        if (isPopupOpen) {
          openRecordIcon = (
            <a
              href={`#detail/${data.def.get("module")}/${data.def.get(
                "id_value"
              )}`}
              target={"_blank"}
            >
              <div className={"icon-newTab detailViewFieldIconNewTab"} />
            </a>
          );
          relateLink = (
            <a
              href={
                "#detail/" +
                data.def.get("module") +
                "/" +
                data.def.get("id_value")
              }
              target={"_blank"}
            >
              {sAction.decodeHTMLEntities(data.value)}
            </a>
          );
        }

        if (sAction.hasAccess(module, "detail")) {
          value = (
            <React.Fragment>
              {data.value != "" && data.value != null && openRecordIcon}
              {relateLink}
            </React.Fragment>
          );
        } else {
          value = <span>{data.value}</span>;
        }
      } else {
        value = data.value;
      }

      break;
    //_____________________________________________________________________________________
    case "Currency":
      value = sAction.formatNumber(data.value);

      break;
    //_____________________________________________________________________________________
    case "Bool":
      var Bool = sAction.fields["Bool"];
      value = (
        <Bool
          prefix={props.prefix}
          way={props.way}
          data={data}
          readonly={readonlyState}
          newRecord={props.newRecord}
        />
      );

      break;
    //_____________________________________________________________________________________
    case "Html":
      const html = data.def.get("default");
      value = html;

      break;
    //_____________________________________________________________________________________
    case "Iframe":
      value = <iframe className="detailViewFieldIframe" src={data.value} />;

      break;
    //_____________________________________________________________________________________
    case "Text":
      try {
        value = data.value;
        if (value != null && value != "") {
          value = value.replace(/\n/g, "<br>");
          value = (
            <div
              className="textareaContent"
              dangerouslySetInnerHTML={{ __html: value }}
            />
          );
        }
      } catch (e) {
        console.log(
          "Nepodařilo se zpracovat hodnotu pole " +
          data.name +
          " \n hodnota: " +
          data.value
        );
        value = "";
      }

      break;
    //_____________________________________________________________________________________
    case "Currency_id":
      try {
        value =
          sAction.currencies[data.value ?? "-99"]["name"] +
          " (" +
          sAction.currencies[data.value ?? "-99"]["symbol"] +
          ")";
      } catch (e) {
        value = "";
      }

      break;
    //_____________________________________________________________________________________
    case "Radioenum":
      var Radioenum = sAction.fields["Radioenum"];
      value = (
        <Radioenum way={props.way} data={data} newRecord={props.newRecord} />
      );
      break;
    //_____________________________________________________________________________________
    case "Float":
    case "Decimal":
      const precision = data.def.get("precision");
      value = sAction.formatNumber(data.value);

      break;
    //_____________________________________________________________________________________
    case "Parent":
      let moduleName =
        sAction.app_strings["moduleList"][data.def.get("parent_type_value")];
      if (moduleName === undefined) {
        moduleName = "";
      } else {
        moduleName += ": ";
      }

      const module = data.def.get("parent_type_value");
      const id = data.def.get("id_value");

      if (moreDetail[module]) {
        const searchData = {
          fields: ["id"],
          getFields: ["id", "name"],
          value: id,
          module,
        };

        if (moreDetail[module]) {
          searchData.getFields = searchData.getFields.concat(moreDetail[module])
        }

        setTimeout(() => {
          sAction.quickSearch(searchData, (retData) => {

            if (retData.length > 0) {  // pokud je vysledek
              let moreDetailLine = ""
              if (moreDetail[module] && moreDetail[module].length !== 0) {
                moreDetailLine = " - "
                moreDetail[module].forEach((e, i) => {
                  if (i != moreDetail[module].length - 1) {
                    moreDetailLine += `${retData[0][e]}, `
                  } else {
                    moreDetailLine += `${retData[0][e]}`
                  }
                })
              }

              let prevName = sAction.dataGet(props.way + "/value");
              let relateMoreInfo = sAction.dataGet(props.prefix + "/customData/relateMoreInfo/" + data.name);

              if (!relateMoreInfo && !prevName.includes(moreDetailLine)) {
                let customData = sAction.dataGet(props.prefix + "/customData");
                sAction.dsClear();
                sAction.dsAdd("set", props.way + "/value", prevName + moreDetailLine);
                if(customData){
                  sAction.dsAdd("set", props.prefix + "/customData/relateMoreInfo", {
                    [data.name]: true
                  });
                } else {
                  sAction.dsAdd("set", props.prefix + "/customData", {
                    relateMoreInfo: {
                      [data.name]: true
                    }
                  });
                }
                
                sAction.dsProcess();
              }
            }
          });
        }, 0);
      }

      let parentLink = (
        <a
          onClick={(e) => {
            e.stopPropagation();
          }}
          key="parent"
          href={`#detail/${data.def.get("parent_type_value")}/${data.def.get(
            "id_value"
          )}`}
        >
          {data.value}
        </a>
      );

      let openParentIcon = (
        <div
          onClick={(e) =>
            props.show(e, {
              module: data.def.get("parent_type_value"),
              id: data.def.get("id_value"),
            })
          }
          className="icon-eye detailViewFieldIconEye"
        />
      );

      let newTabIcon = (
        <a
          key="parent2"
          onClick={(e) => {
            e.stopPropagation();
          }}
          href={`#detail/${data.def.get("parent_type_value")}/${data.def.get(
            "id_value"
          )}`}
          className="icon-newTab detailViewFieldIconEye"
          target="_blank"
        >
          {" "}
        </a>
      );

      const isPopupOpen = Boolean(sAction.getNewPopupId());
      if (isPopupOpen) {
        parentLink = (
          <a
            key="parentTabLink"
            target={"_blank"}
            href={`#detail/${data.def.get("parent_type_value")}/${data.def.get(
              "id_value"
            )}`}
          >
            {data.value}
          </a>
        );

        openParentIcon = (
          <a
            target={"_blank"}
            onClick={(e) => {
              e.stopPropagation();
            }}
            href={`#detail/${data.def.get("parent_type_value")}/${data.def.get(
              "id_value"
            )}`}
          >
            <div className={"icon-newTab detailViewFieldIconNewTab"} />
          </a>
        );
      }

      if (!data.def.get("id_value")) {
        openParentIcon = "";
        newTabIcon = "";
      }

      value = (
        <React.Fragment>
          <div className="detailviewParentModule">{moduleName}</div>
          {openParentIcon}
          {isPopupOpen ? "" : newTabIcon}
          {parentLink}
        </React.Fragment>
      );
      if ((data.value === "" || data.value === null) && moduleName === "") {
        value = null;
      }
      break;
    //_____________________________________________________________________________________
    case "HoursMinutes":
      const hours = data.value;
      const minutes = data.def.get("minutesValue");
      value = hours + ":" + minutes;
      rawValue = value;
      if (hours === null && minutes === null) {
        value = null;
      }
      break;
    //_____________________________________________________________________________________
    case "File":
      var File = sAction.fields["File"];
      value = (
        <File
          rowWay={props.rowWay}
          index={props.index}
          way={props.way}
          prefix={props.prefix}
          data={data}
          newRecord={props.newRecord}
          module={recordModule}
          readonly={readonlyState}
        />
      );

      break;
    //_____________________________________________________________________________________
    case "Files":
      var Files = sAction.fields["Files"];
      value = (
        <Files
          rowWay={props.rowWay}
          index={props.index}
          way={props.way + "/def/files"}
          prefix={props.prefix}
          changesWay={props.prefix + "/changes/files"}
          data={data}
          newRecord={props.newRecord}
          readonly={readonlyState}
        />
      );

      return value;
    //_____________________________________________________________________________________
    case "HTMLText":
    case "RichText":
      var HTMLText = sAction.fields["HTMLText"];
      value = (
        <HTMLText
          rowWay={props.rowWay}
          index={props.index}
          way={props.way}
          prefix={props.prefix}
          data={data}
          newRecord={props.newRecord}
          readonly={readonlyState}
          key={window.location.hash}
        />
      );
      return value;
    //_____________________________________________________________________________________
    case "Email":
      var EmailFieldView = sAction.fields["EmailFieldView"];
      const emails = data.def.get("emails");
      value = <EmailFieldView prefix={props.prefix} data={emails} />;
      if (!emails || !emails.size) nullValue = true;
      break;
    //_____________________________________________________________________________________
    case "Ss":
      var SSFieldView = sAction.fields["SSFieldView"];
      const selected = data.def.get("selected");
      if (selected != undefined && selected.size != 0) {
        value = <SSFieldView prefix={props.prefix} data={data.def} />;
      }
      break;
    // ____________________________________________________________________________________
    case "Address":
      value = data.value;
      break;

    case "Phone":
      let phoneNumber = data.value
      let phoneNumArr = []

      if (phoneNumber && phoneNumber.length >= 13 && phoneNumber.slice(0, 1) === '+') {
        phoneNumber = phoneNumber.replace(/ /g, '').replace('+', '')

        for (let i = 0; i < phoneNumber.length; i += 3) {
          phoneNumArr.push(phoneNumber.slice(i, i + 3))
        }

        if (phoneNumber && phoneNumber.length > 0) {
          phoneNumber = "+" + phoneNumArr.join(' ')
        }
      }

      value = phoneNumber;
      break;
    case "AcmAddress":
      value = (
          <AcmAddress
              edit={() => sAction.fieldToEdit({
                way: props.way,
                name: props.name,
                prefix: props.prefix,
                type: props.def.get('type'),
              })}
              show={(e, data) => show(e, data)}
              newRecord={false}
              {...(data.toObject())}
              {...props}
              data={data}
          />
      );
      break;
    case "Button":
      var Button = sAction.fields["Button"];
      value = <Button way={props.way} prefix={props.prefix} data={data} />;
      break;
    case "Image":
      var Image = sAction.fields["Image"];
      value = (
        <Image
          rowWay={props.rowWay}
          index={props.index}
          way={props.way}
          prefix={props.prefix}
          data={data}
        />
      );
      break;
    case "Name":
    case "Account_name":
      value = (
        <div className="overflowEllipsis">
          {sAction.decodeHTMLEntities(data.value)}
        </div>
      );
      break;
    case "Multirelate":
      var MultirelateView = sAction.fields["MultirelateView"];
      value = (
        <MultirelateView prefix={props.prefix} data={data} module={recordModule} show={props.show} />
      );
      break;
    default:
      value = <div className="overflowEllipsis">{data.value}</div>;
  }
  const dontDisplayEditPen = [
    "Bool",
    "Multienum",
    "Radioenum",
    "File",
    "Button",
    "Image",
  ];
  const dontEditAfterClick = [
    "Bool",
    "Multienum",
    "Radioenum",
    "File",
    "Button",
    "Image",
  ];
  const dontPaddingLeft = ["Multienum"];
  var attrs = {};
  var containerClass = "valueContainer";

  if (dontPaddingLeft.indexOf(props.fieldType) != -1) {
    containerClass = "valueContainerNoPadding";
  }

  if (
    dontEditAfterClick.indexOf(props.fieldType) == -1 &&
    readonlyState == false
  ) {
    attrs["onClick"] = () => props.edit();
    containerClass += " hover";
  } else if (readonlyState) {
    containerClass += " notAllowed";
  }
  if (
    rawValue == null ||
    rawValue == "" ||
    rawValue === undefined ||
    rawValue == " " ||
    nullValue
  ) {
    if (["Bool", "Button", "Image"].indexOf(props.fieldType) === -1) {
      containerClass += " nullValue";
    }
  }

  var iconClassName = "icon-EditField";
  if (data.def.get("edit") == true) {
    iconClassName = "fieldEdited";
  }

  containerClass += " " + props.data.customClass;

  return (
    <div>
      <div
        className={containerClass}
        {...attrs}
        data-test-field={data.name}
        data-test-type={"field"}
        data-test-field-type={props.fieldType}
      >
        <span id={data.name}>
          {style ? (
            <span style={style ? style : {}} className="colorList">
              {" "}
            </span>
          ) : null}
          {value}
        </span>
      </div>
      {dontDisplayEditPen.indexOf(props.fieldType) == -1 &&
        readonlyState == false && (
          <div
            onClick={() => props.edit()}
            className={"detailViewSpanEdit " + iconClassName}
          />
        )}
    </div>
  );
}
