import detailDefault from "../../detailDefault"

export default class detailacm_orders extends detailDefault {
    load(sAction, data) {
        let from_quote = sAction.dataGet(data.prefix + "/fields/from_quote/value")
        this.updateQProductsDefaultFilter(sAction, data);
        // doklad / name je read only cislovani z coripa nebo cislo z pohody
        // let name = sAction.dataGet(data.prefix + "/fields/name/value");
        // sAction.dsClear();
        // if ((name !== "" && name !== null) || (from_quote === "1" || from_quote === 1) ){
        // if (from_quote === "1" || from_quote === 1){
        //     sAction.dsAdd("set", data.prefix + "/fields/name/def/readonly", true);
        // } else {
        //     sAction.dsAdd("set", data.prefix + "/fields/name/def/readonly", false);
        // }
        // sAction.dsProcess();

    }

    update(sAction, data) {
        switch (data.field) {
            case "for_company":
                this.updateQProductsDefaultFilter(sAction, data);
                break;
        }
    }

    updateDetailLine(sAction, data) {
        switch (data.field) {
            case 'name':
                if (data.value.id) {
                    sAction.load();
                    let fields = {cena_bez_dph_kus: 'list_price', kod: 'mft_part_num', mj: 'mj'};
                    const searchData = {
                        module: 'ProductTemplates',
                        fields: ['id'],
                        getFields: Object.values(fields),
                        value: data.value.id,
                        limit: 999999,
                    };

                    sAction.quickSearch(searchData, returnData => {
                        sAction.dsClear();
                        fields.forEachObject((templateName, lineName) => {
                            data.saveField(returnData[0][templateName], lineName, true);
                        });
                        sAction.dsProcess();
                        sAction.unLoad();
                    });
                }
                break;
        }
    }

    updateQProductsDefaultFilter(sAction, data) {
        let getAsCompany = sAction.dataGet(data.prefix + "/fields/for_company/value")
        let prodDefaultFilter = null;
        if (getAsCompany === "eleman") {
            prodDefaultFilter = [
                {
                    module: "ProductTemplates",
                    field: "as_company",
                    value: "eleman",
                    type: "eq",
                },
            ];
        } else if (getAsCompany === "famatel") {
            prodDefaultFilter = [
                {
                    module: "ProductTemplates",
                    field: "as_company",
                    value: "famatel",
                    type: "eq",
                },
            ];
        } else {
            prodDefaultFilter = null;
        }

        sAction.dsClear();
        sAction.dsAdd('set', 'view/customData/customLines/defaultFilter', prodDefaultFilter);

        let lines = sAction.dataGet(data.prefix + "/customData/customLines/lines")

        if(lines && lines.length > 0){
            lines.forEach((line, ln_index) => {
                sAction.dsAdd('set', `view/customData/customLines/lines/${ln_index}/defaultFilter`, prodDefaultFilter);
            })
        }
        sAction.dsProcess();
    }
}
