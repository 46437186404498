import detailDefault from "../../detailDefault";

export default class detailContacts extends detailDefault {
  load(sAction, data) {
    this.updateDetailApiVisibility(sAction, data);
    if (sAction.dataGet(data.prefix + '/type') === 'popup') {
      this.autoFillAddress(sAction, data);
    }

    if(data.prefix.includes("boundModules")) {
      sAction.saveField({
        "way": data.prefix + "/fields/assigned_user_name",
        "name": "assigned_user_id",
        "fieldName": "assigned_user_name",
        "vname": "LBL_ASSIGNED_TO_NAME",
        "type": "relate",
        "value": {
            "id": sAction.dataGet("conf/user/id"),
            "name": sAction.dataGet("conf/user/name")
        },
        "cancelEdit": true
    }, true);
    }
  }

  update(sAction, data) {
    switch (data.field) {
      case "primary_address_country":
        this.updateDetailApiVisibility(sAction, data)
        break;
      case "account_id":
        if (data.value.id === "") break;
        sAction.load();
        let fields = {
          primary_address_street: 'shipping_address_street',
          primary_address_city: 'shipping_address_city',
          primary_address_state: 'shipping_address_state',
          primary_address_kraj: 'shipping_address_kraj',
          primary_address_postalcode: 'shipping_address_postalcode',
          primary_address_country: 'shipping_address_country',
          region: 'region'
        };
        const searchData = {
          module: 'Accounts',
          fields: ['id'],
          getFields: Object.values(fields),
          value: data.value.id,
          limit: 1,
        };
        sAction.quickSearch(searchData, returnData => {
          if (Object.keys(returnData).length !== 0) {
            sAction.dsClear();
            fields.forEachObject((templateName, lineName) => {
              sAction.dsAdd("set", `${data.prefix}/fields/${lineName}/value`, returnData[0]?.[templateName]);
              sAction.dsAdd("set", `${data.prefix}/changes/fields/${lineName}`, returnData[0]?.[templateName]);
            });
            sAction.dsProcess();
          }
          sAction.unLoad()
        });
        break;
      default:
        break;
    }
  }

  //fce skryva/odkryva detail fieldy podle zeme, jestli je zeme CZECH REPUBLIC
  updateDetailApiVisibility(sAction, data) {
    let address;
    if (data.value) {
      address = data.value;
    } else {
      address = sAction.dataGet(data.prefix + "/fields/primary_address_country/value");
    }

    const updateFieldApiValue = (fieldName, fieldValue) => {
      if (sAction.dataGet(data.prefix + "/fields/"+fieldName) !== undefined) {
        sAction.dsAdd("set", data.prefix + "/fields/"+fieldName+"/def/showDetailApi", fieldValue);
      }
    }

    sAction.dsClear();
    if (!address || address === "CZECH REPUBLIC") {
      //zobrazi ikony
      updateFieldApiValue("primary_address_street", "ApiStreet");
      updateFieldApiValue("primary_address_postalcode", "ApiPSC");
      updateFieldApiValue("alt_address_street", "ApiStreet");
      updateFieldApiValue("alt_address_postalcode", "ApiPSC");
    } else {
      //skryje ikony
      updateFieldApiValue("primary_address_street", "");
      updateFieldApiValue("primary_address_postalcode", "");
      updateFieldApiValue("alt_address_street", "");
      updateFieldApiValue("alt_address_postalcode", "");
    }
    sAction.dsProcess();
  }

  autoFillAddress(sAction, data) {
    
    // Primary address
    var fieldData = sAction.dataGet('view/fields');

    if(!fieldData){
      return;
    }
    
    sAction.dsClear();
    
    const fieldsToReplace = [
      //co se vezme => kam zapise
      ['shipping_address_street', 'primary_address_street'],
      ['shipping_address_city', 'primary_address_city'],
      ['shipping_address_postalcode', 'primary_address_postalcode'],
      ['shipping_address_state', 'primary_address_state'],
      ['shipping_address_kraj', 'primary_address_kraj'],
      ['shipping_address_country', 'primary_address_country'],

      ['shipping_address_street', 'alt_address_street'],
      ['shipping_address_city', 'alt_address_city'],
      ['shipping_address_postalcode', 'alt_address_postalcode'],
      ['shipping_address_state', 'alt_address_state'],
      ['shipping_address_kraj', 'alt_address_kraj'],
      ['shipping_address_country', 'alt_address_country'],
    ];

    fieldsToReplace.forEach(field => {
      const replacement = fieldData.getIn([field[0], "value"]);
      if (replacement) {
        if (sAction.dataGet(data.prefix + "/fields/"+field[1]) !== undefined) {
          sAction.dsAdd("set", data.prefix + "/fields/" + field[1] + "/value", replacement);
          sAction.dsAdd("set", data.prefix + "/changes/fields/" + field[1], replacement);
        }
      }
    });

    sAction.dsProcess();
  }
}
