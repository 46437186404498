export default function reloadListParams(data) {
  const list = data.list;
  const prefix = data.prefix;
  const savedSearch = data.savedSearch;
  const forceColumns = data.columns;
  const fieldWithPath = data.list?.openFilterData?.def.get("fieldWithPath");

  var offset = data.offset;
  if (offset == undefined) {
    offset = 1;
  }
  var page = data.page;
  if (page == undefined) {
    page = 1;
  }

  /*
    Sestavení filtru pro server, podle toho co má uživatel naklikáno přes možnosti filtrace u listview
  */
  var filterParameters = [];
  list.filter.forEach((group) => {

    if (group?.filterFormat !== "filter" && group.get("filterFormat") !== "filter") {
      var filters = [];
      var groupOperator = group.operator;
      group.filters.forEach((filter) => {
        let processFilter = true;

        if(filter.get("processRelate") === true){
          let newFilter = this.processFilterRelMoreDetail(filter, prefix);
          if(newFilter){
            filters.push(newFilter);
            processFilter = false;
          } else {
            // proces filter with relate failed -> fallback to default
            processFilter = true;
          }
        }

        if(processFilter){
          var value = filter.value;
          var type = filter.type;
          if (
            filter.fieldType == "date" ||
            filter.fieldType == "currency" ||
            filter.fieldType == "decimal" ||
            filter.fieldType == "int"
          ) {
            if (value.startsWith("..")) {
              type = "lessThanInclude";
              value = value.substring(2);
            } else if (value.endsWith("..")) {
              type = "moreThanInclude";
              value = value.slice(0, -2);
            } else if (value.indexOf("..") !== -1) {
              const explodeValue = value.split("..");
              value = explodeValue[0];
              type = "moreThanInclude";
              filters.push({
                field: filter.field,
                fieldModule: filter.fieldModule,
                fieldRel: filter.fieldRel,
                type: "lessThan",
                type: "lessThanInclude",
                value: explodeValue[1],
              });
            }
          }
          if (filter.multiValue == true && value.indexOf(";") !== -1) {
            groupOperator = "or";
            const explodeValue = value.split(";");
            explodeValue.forEach((val) => {
              filters.push({
                field: filter.field,
                fieldModule: filter.fieldModule,
                fieldRel: filter.fieldRel,
                type: "eq",
                value: val,
              });
            });
          } else {
            if (filter.field && type && value !== "") {
              if (type == "relation") {
                // podpora relate filtru z account turnover reportu example: site/coripoAdmin/views/AccountsTurnover/index.php:406
                let filterData = filter ? filter.toJS() : null;
                // let filterData = filter;
                var newFilter = {
                  module: filterData.fieldModule,
                  type: "relate",
                  name: filterData.field,
                  relationship: [filterData.fieldRel],
                  filter: {
                    operator: "and",
                    operands: [
                      {
                        field: "id",
                        type: "eq",
                        value: filterData.value,
                      },
                    ],
                  },
                };
              } else {
                var newFilter = {
                  field: filter.field,
                  fieldModule: filter.fieldModule,
                  fieldRel: filter.fieldRel,
                  type: type,
                  value: value,
                };
                if (filter.parent_type != null) {
                  newFilter.parent_type = filter.parent_type;
                }
              }
              filters.push(newFilter);
            }
          }
        }
      });
      var group = {
        operator: groupOperator,
        operands: filters,
      };
    }

    filterParameters.push(group);
  });

  var actFilter = null;
  if (filterParameters.length !== 0) {
    actFilter = {
      operator: "and",
      operands: filterParameters,
    };
  }

  if (prefix == "reportWindow/results") {
    actFilter = this.reportWindowCreateFilter();
  }

  // _________________________________________________________________________________________________

  /*
   Pokud se jedná o uložené vyhledávání a je na něm v rámci listview volána změna (order, pagination, filter)
   nastaví se "customParameters" na true, aby server věděl že má přepsat hodnoty z načteného pohledu
 */
  var customParameters = false;
  if (savedSearch == null) {
    customParameters = true;
  }
  // ______________________________________________________________________________________________________

  /*
   Pokud se jedná u uložené vyhledávání/pohled předá se ID pohledu do proměné "savedSearchId"
 */
  var savedSearchId = null;
  if (savedSearch != null) {
    savedSearchId = savedSearch;
  } else if (list.actSavedSearch !== null) {
    savedSearchId = list.actSavedSearch;
  }
  // _______________________________________________________________________________________

  /*
 nastavení limitu pro vysledky
 */
  var finalLimit = list.limit;
  if (list.type == "subpanel") {
    //  finalLimit = 10;
  }
  // _____________________________________

  /*
  Pokud je typ listview subpanel, vezmou se rows z definice subpanelu a pošlou se jako columns ..
  aby se vrátil stejný počet sloupců
 */
  var columns = null;
  if (list.type === "subpanel" || forceColumns == true) {
    columns = [];

    list.rows.forEach((row) => {
      columns.push({
        field: row.key.toLowerCase(),
        function: null,
        module: list.modul,
        width: row.width,
        type: row.def.get("type"),
        label: row.label,
        fModule: row.def.get("fieldModule"),
        fName: row.def.get("name"),
      });
    });
  }

  // _______________________________________________________________________________________
  /*
pokud jsou data v defaultFilter je potřeba je přidat do filtru k odeslání
*/
  if (list.defaultFilter.size != 0) {
    var actDefaultFilter = {
      operator: "and",
      operands: [],
    };
    list.defaultFilter.entrySeq().forEach((e) => {
      const filter = e[1];
      if (filter.operandType == "relate" && filter.parentId) {
        var value = "";
        try {
          value = filter.parentId.toJS();
        } catch (error) {
          value = filter.parentId;
        }
        const operand = {
          module: filter.module,
          type: "relate",
          relationship: filter.relationship,
          name: filter.name,
          filter: {
            operator: "and",
            operands: [{ field: "id", type: filter.type, value: value }],
          },
        };
        actDefaultFilter.operands.push(operand);
      } else if (filter.operandType != "relate") {
        try {
          const operand = {
            field: filter.get("field"),
            type: filter.get("type"),
            value: filter.get("value"),
          };
          actDefaultFilter.operands.push(operand);
        } catch (error) {
          const operand = {
            field: filter.field,
            type: filter.type,
            value: filter.value,
          };
          actDefaultFilter.operands.push(operand);
        }
      }
    });
    if (actDefaultFilter.operands.length !== 0) {
      if (actFilter != null) {
        actDefaultFilter.operands.push(actFilter);
      }
      actFilter = actDefaultFilter;
    }
  }
  // _______________________________________________________________________________________
  /*
   Pokud si člověk vybere pohled "Moje" přidá se do filtru podmínka na assgined_user_id
*/

  if (savedSearchId == "only_my") {
    var filter = {
      field: "assigned_user_id",
      type: "eq",
      value: "{%LOGIN_USER%}",
    };
    var actFilterWithRelate = {
      operator: "and",
      operands: [],
    };
    actFilterWithRelate.operands.push(filter);
    if (actFilter != null) {
      actFilterWithRelate.operands.push(actFilter);
    }
    actFilter = actFilterWithRelate;
  }
  /*
     Pokud existují nějaké funkce nad fieldem typu SUM,AVG,COUNT přidají se do dalšího parametru pro rest
   */
  var fieldFunction = {};
  var funcList = list.fieldFunction.get("parameters");
  if (funcList !== undefined && funcList !== null) {
    funcList.entrySeq().forEach((e) => {
      fieldFunction[e[0]] = e[1];
    });
  }
  // __________________________________________________________________________________________________
  /*
   Sestavení parametrů aktuálního listview pro server
 */
  var asc = list.asc;
  if (asc == 1) {
    asc = "ASC";
  } else {
    asc = "DESC";
  }
  var order = list.orderBy;
  var orderData = [];
  if (Array.isArray(order)) {
    order.forEach((o) => {
      orderData.push({
        field: o,
        sort: asc,
        module: list.modul,
      });
    });
  } else if (order !== undefined && order !== null) {
    orderData.push({
      field: fieldWithPath ? fieldWithPath : order,
      sort: asc,
      module: list.modul,
    });
  }

  const searchData = {
    limit: finalLimit,
    offset,
    saved_search_id: savedSearchId,
    columns,
    customParameters,
    fieldFunction,
    order: orderData,
    filter: actFilter,
    prefix: list.prefix,
  };
  return searchData;
  // ___________________________________________________
}
