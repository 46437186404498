export default function exportPMA(params) {
    
    const list = this.dataGet(params.prefix);
    var selected = list.selected;
    if (typeof selected == "object") {
        selected = selected.toJS();
    }
    var defs = {};
    list.rows.forEach(row => {
        const def = row.def.toJS();
        defs[def["fieldModule"] + "." + def["name"]] = def;
    });

    const data = {
        "name": "ExportPMA",
        "type": "xlsx",
    }

    // why is it even here
    var formData = this.objectToFormdata(defs, formData, "def");
    formData.append("module", list.modul);
    formData.append("exportType", data["type"]);

    this.popup()

    formData = {
        module: list.modul,
        exportType: "xlsx",
        selectedIds: params?.id.toJS(),
        action: "PmaExport",
        name: data["name"],
        type: "xlsx",
    }

    this.dsClear();
    this.dsAdd("set", "conf/popup/content", "fileDownload");
    this.dsAdd("set", "conf/popup/data/state", "load");
    this.dsProcess();

    this.filesXhr({
        action: "generateFileRequest",
        data: formData,
        success: ret => {

            if (ret.status == true) {
                this.dsClear();
                this.dsAdd("set", "conf/popup/data/state", "init")
                this.dsAdd("set", "conf/popup/data/token", ret.token)
                this.dsProcess();
            }
        }
    });

}
