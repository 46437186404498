import React from "react";

import InputField from "../inputFieldClass";
import Loader from "../../loader";
import sAction from "sAction";
import DropdownAnimation from 'ROOT/src/components/animation/DropdownAnimation';
import {Record, Map, List} from 'immutable';

export default class AcmAddress extends InputField {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      state: 'fine',
      value: null,
      id: null,
      loading: false,
      resultData: null,
      resultOpen: false,
      resultArrowIndex: 0,
    };
    this.searchInterval = null;
    this.input = React.createRef();
    this.inputObec = React.createRef();
    this.containerRef = React.createRef();
    this.isAdressSelected = true;
    this.emptyItemTemplate = {
      street: '',
      obec: '',
      cast_obce: '',
      kraj: '',
      okres: '',
      psc: '',
      x: '',
      y: '',
      gpsX: '',
      gpsY: '',
    };
    let microservicesScopes = sAction.dataGet('conf/user/microservice_access_token_allowed_scopes');
    if (Map.isMap(microservicesScopes) || Record.isRecord(microservicesScopes) || List.isList(microservicesScopes)) {
      microservicesScopes = microservicesScopes.toJS();
    }
    this.twoInputs = true;
    if (microservicesScopes.includes('maps-seznam-mapycz')) {
      this.twoInputs = false;
    }
  }

  executeScroll = () => {
    if (this.containerRef.current) {
      this.containerRef.current.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
    }
  };

  /**
   *
   * @param {*} event
   */
  keyUp(event) {
    const keyCode = event.keyCode;
    if (keyCode === 13 && event.ctrlKey === false) {
      if (this.state.resultOpen === true) {
        const item = this.state.resultData[this.state.resultArrowIndex];
        if (item != null) {
          this.selectItem(item, true, true);
          this.setState({resultOpen: false});
          this.input.current.value = item.name;
        }
      } else {
        this.save();
      }
    } else if (keyCode === 27) {
      this.cancel();
    } else if (keyCode === 40 || keyCode === 38) {
      this.changeArrowIndex(keyCode);
    } else if (keyCode !== 9 && keyCode !== 37 && keyCode !== 39) {
      this.waitForSearch();
    }
  }
  /**
   *
   */
  waitForSearch() {
    this.isAdressSelected = false;
    if (this.searchInterval != null) {
      clearInterval(this.searchInterval);
    }
    const self = this;
    this.searchInterval = setInterval(() => {
      self.search();
      clearInterval(self.searchInterval);
    }, 800);
  }

  /**
   *
   * @param {*} keyCode
   */
  changeArrowIndex(keyCode) {
    let resultArrowIndex = this.state.resultArrowIndex;
    if (keyCode === 40) {
      resultArrowIndex += 1;
    } else if (keyCode === 38) {
      resultArrowIndex -= 1;
    }

    if (resultArrowIndex < -1) {
      resultArrowIndex = -1;
    }
    if (this.state.resultData != null) {
      if (resultArrowIndex >= this.state.resultData.length) {
        resultArrowIndex = this.state.resultData.length;
      }
    }
    this.executeScroll();
    this.setState({resultArrowIndex});
  }
  search = () => {
    if (!this.input.current || !this._isMounted) {
      return;
    }
    const value = this.input?.current?.value ?? '';
    const cityValue = this.inputObec?.current?.value ?? '';
    if (value !== '' || value !== null) {
      const searchData = {
        query: value,
        city: cityValue,
      };
      const self = this;
      this.setState({loading: true});
      sAction.searchAcmAddress(searchData, (data) => {
        const transformed = [];

        if (data.length) {
          data.forEach((item) => {
            let name = '';
            if (item.type === 'poi') {
              name = item.name;
            }

            const current = {
              kraj: item.address.kraj ?? '',
              obec: item.address.city ?? '',
              okres: item.address.state ?? '',
              psc: item.address.zip ?? '',
              ulice: item.address.street ?? '',
              cislo_dom: item.address.cislo_dom ?? '',
              cislo_orient: item.address.cislo_orient ?? '',
              x: item.gpsX ?? '',
              y: item.gpsY ?? '',
              name: name,
            };
            transformed.push(current);
          });
          // transformed = transformed.sort(this.sortAddresses); // sort addresses by numbers, breaks maps
        }

        if (!this._isMounted) {
          return;
        }
        self.setState({
          resultArrowIndex: 0,
          resultData: transformed,
          resultOpen: true,
          loading: false,
        });
      });
    }
  };

  /**
   *
   * @param {*} a
   * @param {*} b
   * @returns {number}
   */
  sortAddresses(a, b) {
    const cisloDomA = a.cislo_dom.replace('ev. č. ', '');
    const cisloDomB = b.cislo_dom.replace('ev. č. ', '');
    if (cisloDomA !== '' && cisloDomB !== '') {
      if (+cisloDomA === +cisloDomB) {
        if (a.cislo_orient !== '' && b.cislo_orient !== '') {
          if (+a.cislo_orient < +b.cislo_orient) {
            return -1;
          } else {
            return 1;
          }
        }
      }
      if (+cisloDomA < +cisloDomB) {
        return -1;
      } else {
        return 1;
      }
    }
    if (cisloDomA !== '') {
      return -1;
    }
    if (cisloDomB !== '') {
      return 1;
    }
    return 0;
  }

  /**
   *
   * @param {*} item
   */
  selectItem(item) {
    let cancelEdit;
    let resultData = null;
    if (!item.x && !item.y) {
      // we selected only city, move to street input and complete location selection
      item = {...this.emptyItemTemplate, ...item}; // set default data for all values and selected from first input
      cancelEdit = false;
      this.isAdressSelected = false;
      resultData = null; // delete data, we want new search
      setTimeout(() => {
        if (this.input.current) {
          this.input.current.value = ''; // sometimes undefined is set, idk why, so we set default on ''
          this.input.current.focus();
        }
      }, 0); // set focus on street input after all state changes are completed
      return;
    } else {
      // we selected street => location is now set => stop edit location
      this.isAdressSelected = true;
      cancelEdit = true;
    }
    this.setState({
      name: sAction.getStreetlabelAcmAddress(item),
      resultData: resultData,
    });
    sAction.saveAcmAddressField(
        item,
        {name: this.props.name},
        this.props.prefix,
        this.props.way,
        cancelEdit,
    );
  }
  /**
   *
   */
  focus() {
    if (this.state.resultData != null) {
      this.setState({
        resultOpen: true,
      });
    }
  }

  /**
   *
   * @returns {null}
   */
  getSearchResult() {
    let refAttr = {};
    let searchResult = <div></div>;
    if (this.state.loading === true) {
      searchResult = (
          <div className="quickSearchResultContent">
            <div style={{height: '70%', top: '25px'}} className="quickSearchNoRecords">
              {<Loader/>}
            </div>
          </div>
      );
    }
    if (this.state.resultOpen === true) {
      const resultList = [];
      this.state.resultData?.forEach((item, index) => {
        refAttr = {};
        let lineClass = 'quickSearchResultLine';
        if ((index - 1) === this.state.resultArrowIndex || (index + 1) === this.state.resultArrowIndex) {
          refAttr = {
            ref: this.containerRef,
          };
        }
        if (index === this.state.resultArrowIndex) {
          lineClass += ' arrowSelect';
          refAttr = {
            ref: this.containerRef,
          };
        }

        resultList.push(
            <div
                tabIndex={index}
                onClick={() => this.selectItem(item)}
                key={index}
                className={lineClass}
                {...refAttr}
            >
              <div className={'quickSearchResultLineItem'}>
                {this.getLabel(item)}
              </div>
              <div className={'quickSearchResultLineItem'}>
                <span className={'itemInfo'}>{item.name}</span>
              </div>
            </div>,
        );
      });
      if (!resultList.length) {
        resultList.push(
            <div
                tabIndex={0}
                key="No-results"
                onClick={this.chooseLocation}
                className="quickSearchResultLineError"
            >
              {sAction.translate('LBL_UNKNOWN_ADDRESS')}
            </div>,
        );
      }
      searchResult =(
          <div className={'quickSearchResultContent'}>
            {resultList}
          </div>
      );
    }
    const open = this.state.resultOpen || this.state.loading;
    let containerClass = '';
    if (open) {
      // class must be set only when we have open DropDown, otherwise we display border under input
      containerClass = 'quickSearchResult';
    }

    return (
        <DropdownAnimation open={open} className={containerClass}>
          {searchResult}
        </DropdownAnimation>
    );
  }

  chooseLocation = () => {
    this.save();
    setTimeout(() => {
      document.querySelector('.icon-Location').click();
    }, 1000);
  };

  componentDidMount() {
    this._isMounted = true;
  }

  deleteValue = () => {
    sAction.saveAcmAddressField(
        {...this.emptyItemTemplate},
        {name: this.props.name},
        this.props.prefix,
        this.props.way,
    );
    this.isAdressSelected = true;
    super.cancel();
  };

  /**
   *
   */
  cancel() {
    this.isAdressSelected = true;
    super.cancel();
  }

  save = () => {
    if (this.isAdressSelected) {
      super.save();
    } else {
      super.cancel();
    }
  };

  componentWillUnmount() {
    this._isMounted = false;

    if (!this.isAdressSelected) {
      const item = this.props.def.get('item');
      const data = {
        x: item.get('gpsX'),
        y: item.get('gpsY'),
      };
      this.selectItem(data);
    }
  }

  /**
   *
   * @param {*} item
   * @returns {string}
   */
  getLabel(item) {
    let label = sAction.getStreetlabelAcmAddress(item);
    label = label ? `${label ?? ''}, ${item.obec ?? ''} ${item.psc ?? ''}` : `${item.obec ?? ''} ${item.psc ?? ''}`;
    return label;
  }

  /**
   *
   * @param {*} e
   */
  onBlur(e) {
    const relTarget = e.relatedTarget;
    if (relTarget == null) {
      this.cancel();
    } else {
      const parent = relTarget.closest(
          'div[data-fieldname=\'' + this.props.name + '\']',
      );
      if (parent == null) {
        this.cancel();
      }
    }
  }
  render() {
    const newRecord = this.props.newRecord;
    const containerClass = 'inputEditContainer';
    let item = null;

    let value = this.props.value;
    let obecValue = '';
    if (newRecord && (value === '' || value === undefined || value === null)) {
      value = '';
    } else {
      item = this.props.def.get('item');
      const obec = item?.get('obec') ?? '';
      // const cast_obce = item.get('cast_obce');
      // const psc = item.get('psc');
      // const kraj = item.get('kraj');
      if (obec) {
        obecValue += obec;
        // if (psc) {
        //     value += ' '+psc;
        // }
        // if (kraj) {
        //     value += ' '+kraj;
        // }
      }
    }
    const searchResult = this.getSearchResult();

    const containerClassField = 'inputContainer acmAddressField';

    let underline = false;
    let secondInput = null;
    let primaryInputDefault = `${value ?? ''} ${obecValue ?? ''}`;

    if (this.twoInputs) {
      primaryInputDefault = value ?? '';
      underline = true;
      secondInput = (
          <input
              type="text"
              className="acmAddressCity underline"
              onKeyDown={(e) => this.onKeyDown(e, 'last')}
              onKeyUp={(event) => this.keyUp(event)}
              placeholder={sAction.translate('LBL_ACM_ADDRESS_CITY')}
              defaultValue={obecValue}
              ref={this.inputObec}
          />
      );
    }
    primaryInputDefault = primaryInputDefault.trim();
    return (
        <div className={containerClass} data-fieldname={this.props.name}>
          <div className={containerClassField}>
            <input
                type="text"
                className={'acmAddressStreet' + (underline ? ' underline' : '')}
                onKeyDown={(e) => this.onKeyDown(e, 'last')}
                onKeyUp={(event) => this.keyUp(event)}
                placeholder={sAction.translate('LBL_ACM_ADDRESS_ADDRESS')}
                defaultValue={primaryInputDefault}
                ref={this.input}
            />
            {secondInput}
            {searchResult}
          </div>
          {!newRecord && (
              <div className="buttonContainer">
                <div
                    tabIndex="2"
                    onClick={() => this.save()}
                    className="inputEditButton"
                >
                  <div className={'icon-detailConfrim'} />
                </div>
                <div
                    tabIndex="2"
                    onClick={() => this.deleteValue()}
                    className=" inputEditButton"
                >
                  <div className={'icon-detailCancel'} />
                </div>
                <div tabIndex={2} className="inputEditButton"
                     onClick={() => {
                       sAction.openOpenStreetMapPopup(this.props.value ?? '', item, this.props.readonly, this.props.name,
                           this.props.prefix, this.props.way);
                     }}
                >
                  <div className="icon-Location"/>
                </div>
              </div>
          )}
        </div>
    );
  }
}
