import React, { Component } from 'react'
import PureComponent from '../../pure'
import  sAction  from "sAction";
import TextField from "@material-ui/core/TextField";
import Relate from "../../formElements/Relate"
import CheckBox from "../../formElements/CheckBox"
import Select from '../../formElements/Select'
import confProd from "./productLineConfig";
import {Draggable} from "react-beautiful-dnd";
import Image from "./Image";
import QProductStock from "./QProductStock";

class QProduct extends PureComponent{
    constructor(props) {
        super(props);

        this.state = {
            userDiscount: null,
        };
        this.checkStock = new QProductStock()
        const timer = null

        this.minMarginPercent = 30;
    }

    componentDidUpdate(){
        clearTimeout(this.timer)

        this.timer = setTimeout(() => {
            this.checkStock.checkProductAvailability()
        }, 1500)
    }

    valChange(way, val){
        sAction.updateDetailCustom("field", {way:way, value: val});
        this.checkStock.checkProductAvailability()

        switch (way.split("/").pop()) {
            case "price_after_defdisc":
                if(sAction.dataGet("conf/user/business_representative") === true){
                    this.checkMargin(val, way);
                }
                const newWay = way.replace("price_after_defdisc", "manual_price_after_defdisc");
                sAction.dataSet(newWay, 1);
                break;
            case "list_price":
                this.updateQProductDefDiscount(val, way);
                if(sAction.dataGet("conf/user/business_representative") === true){
                    this.checkMargin(val, way);
                }
                break;
        }
    }

    componentDidMount(){
        let userDiscountEnabled = sAction.dataGet('conf/user/add_discount_enable');
        let userDiscount = parseFloat(sAction.dataGet('conf/user/additional_discount'));
        if (userDiscountEnabled == "1"){
            this.setState({userDiscount: userDiscount});
        }
    }

    updateQProductDefDiscount(listPrice, way) {
        const defDiscount = sAction.dataGet("view/customData/productData/defaultDiscount");
        const discount = parseFloat(defDiscount);
        const discountPrice = listPrice - (listPrice * discount / 100);
        const defDiscWay = way.replace("list_price", "price_after_defdisc");

        sAction.dataSet(defDiscWay, discountPrice);
    }

    checkMargin(val, way){
        // something like ../actual_product_template_id
        const productTemplateWay = way.split("/").slice(0, -1).join("/") + "/actual_product_template_id";
        const productTemplateId = sAction.dataGet(productTemplateWay);

        let priceAfterDisc = sAction.dataGet(way.split("/").slice(0, -1).join("/") + "/price_after_defdisc");
        const discount = sAction.dataGet(way.split("/").slice(0, -1).join("/") + "/discount_amount");
        priceAfterDisc = priceAfterDisc - (priceAfterDisc * discount / 100);

        const searchData = {
            fields: ["id"],
            getFields: ["id", "name", "cost_price", "list_price"],
            value: productTemplateId,
            module : "ProductTemplates"
        };

        sAction.quickSearch(searchData, data => {
            if(data.length > 0){
                var line = data[0];
                const costPrice = line.cost_price;
                const margin = (priceAfterDisc - costPrice) / priceAfterDisc * 100;
                if(margin < this.minMarginPercent){
                    sAction.error(sAction.translate("LBL_MIN_MARGIN_ERROR", "Quotes") + " ( " + val.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " )");
                }
            }
        });
    }

    uploadImage = (val) => {
        const way = this.props.way + "/" + this.props.ind + "/picture";
        sAction.updateDetailCustom("field", {way: way, value: val});
    }

    productCallback = (way, key, item) => {
        if (item.id != undefined && item.id != "") {
            let accountId = sAction.dataGet("view/fields/billing_account_name/def/id_value");
            let as_company = sAction.dataGet("view/fields/as_company/value");

            //product template has been selected.
            sAction.getProductTemplate(item.id, 'prodName', way, key, accountId, as_company);
        } else {
            sAction.updateDetailCustom("prodName", {way: way + '/' + key, item: item, template: false});
        }

        setTimeout(() => {
            this.checkStock.checkProductAvailability(item, way + '/' + key)
        }, 1000);

        // Stary kod. Teoreticky nepotrebny. Smazat. Nechavam ho tu pro jistotu kvuli pokazenymu mergi. - Dan
        // if (item.id != undefined && item.id != "") {
        //     //product template has been selected
        //     const searchData = {
        //         fields: ["id"],
        //         getFields: ["id", "name", "cost_price", "list_price", "discount_price", "currency_id", "description", "mft_part_num", "mft_part_num_int"],
        //         value: item.id,
        //         module : "ProductTemplates"
        //     };
        //
        //     sAction.quickSearch(searchData, data => {
        //         if(data.length > 0){
        //             var line = data[0];
        //             //const currency_id = sAction.dataGet(way.split("/productData/", 1)[0]+"/currency_id");//TTT cesta natvrdo
        //             const prefix = way.split("/")[0];
        //
        //             const currency_id = sAction.dataGet(prefix+"/fields/currency_id/value");
        //             // let convRate = sAction.dataGet(prefix+"/fields/kurz/value");
        //
        //             line.cost_price = sAction.currencyConvert(line.cost_price, line.currency_id, currency_id/*, convRate*/);
        //             line.list_price = sAction.currencyConvert(line.list_price, line.currency_id, currency_id/*, convRate*/);
        //             line.discount_amount = sAction.currencyConvert(line.discount_amount, line.currency_id, currency_id/*, convRate*/);
        //             sAction.updateDetailCustom("prodName", {way:way, item: line, template: true});
        //         }
        //     });
        // }
        // else{
        //     sAction.updateDetailCustom("prodName", {way:way, item: item, template: false});
        // }
    }

    prodInfo(params){
        window.open("#detail/Products/"+params.id, '_blank')
    }

    blurDescription(way, val){
        //this.setState({descFocused : false});
        if (sAction.dataGet(way) !== val) {
            this.valChange(way, val);
        }
    }

    setDescription(productIndex) {
        let line = sAction.dataGet(`${this.props.way}/${productIndex}`)
        line = line.set("descFocused", !line.descFocused)
        sAction.dataSet(`${this.props.way}/${productIndex}`, line)
    }

    render(){
    const conf = confProd.fields;

    var data = this.props.data;
    const way = this.props.way;
    const ind = this.props.ind;
    const itway = way+"/"+ind;
    const readonly = this.props.readonly;
    const isRightPanel = way?.includes("rightPanel");

    const dataProd = {value : data.name, id_value: data.id, searchFields : ["name", "mft_part_num", "mft_part_num_int"]};
    const module = "ProductTemplates";

    var taxClassOptions =[];
    sAction.app_strings.tax_class_dom.forEachObject((lbl, key) => {
        taxClassOptions.push({value: key, label: lbl});
    });

    var curId = this.props.currency_id;
    if(!curId){
        curId = "-99";
    }
    const discountOptions = [{value: "0", label: sAction.currencies[curId].symbol}, {value: "1", label: "%"}];
    const discountVal = data.discount_select;


    let buttons = [];
    if(data.id && !readonly){
        buttons.push({id: "fullForm", className: "icon-expandIcon qProductProdNameIcon",  onClick: this.prodInfo, params: {id : data.id}});
    }

    let descClass = "productLineDescription";
    const keyPre = Date.now();

    var renderComp = [];

    var self = this;

    var relateClass = "qProductProdNameField";

    if(readonly){
        relateClass += " readonlyRel";
    }

    // dostane defaultFilter z detailQuotes.js po zmene pole as_comany
    let getDefaultFilter = sAction.dataGet("view/customData/productData/defaultFilter")
    let defaultFilter = getDefaultFilter;

    if (getDefaultFilter && !Array.isArray(getDefaultFilter)){
        defaultFilter = getDefaultFilter.toJS();
    }

    conf.forEach( function(field){
        if(!field.visible){
            return;
        }

        switch(field.type){
            case "number":
                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            id={field.name}
                            type={!isRightPanel ? "number" : "text"} // v rightpanelu neni misto, tak aby se tam vubec neco zobrazilo
                            defaultValue={data[field.name]}
                            autoComplete="of"
                            disabled={field.disabled || readonly}
                            key={keyPre+"_"+field.name}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => self.valChange(itway+"/"+field.name, ev.target.value)}
                        />
                    </div>
                );
                break;
            case "relateProd":
                renderComp.push(
                    <div className={relateClass} style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <Relate
                            newRecord={false}
                            data={dataProd}
                            module={module}
                            buttons={buttons}
                            key={keyPre+"_"+field.name}
                            InputProps={{readOnly: readonly}}
                            callback={(item) => self.productCallback(way, ind, item)}
                            defaultFilter={defaultFilter}
                        />
                    </div>
                );
                break;
            case "text":
                let textVal = data[field.name];
                let customTextClass = "";
                let textLabel = "";
                let canDisableStock = true;
                let loading = false;
                if (field.name == "product_availability") {
                    textLabel = sAction.translate('LBL_STOCK_REST', 'Quotes');
                    customTextClass = "product_availability "

                    if (data.qty_in_stock !== null){
                        let stockLeft = " ";

                        if (data.shown_qty_in_stock !== null){
                            stockLeft = data.shown_qty_in_stock;
            
                            if(stockLeft < 0){
                                customTextClass += "highlight_red ";
                            } else if (stockLeft < 5) {
                                customTextClass += "highlight_orange ";
                            } else if (stockLeft >= 5) {
                                customTextClass += "highlight_green ";
                            }
                            loading = false;
                        } else {
                            loading = true;
                        }
                        textVal = stockLeft;
                    }

                    if (data.qty_in_stock === null 
                    || data.qty_in_stock === "" 
                    || data.no_template === "1" 
                    || data.no_template === 1){
                        textVal = "--";
                        customTextClass = "product_availability ";
                        loading = false;
                    }
                }
                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name} className="inputContainer">
                        {loading &&
                            <div className="icon-container">
                                <div className='loaderWrapper'>
                                    <i className="loader"></i>
                                </div>
                                <p className="notFound" title='Dostupnost nebyla nalezena'>?</p>
                            </div>
                        }
                        <TextField
                            margin="normal"
                            variant="outlined"
                            className={"quoteProductField " + customTextClass}
                            id={field.name}
                            type="text"
                            autoComplete="of"
                            title={textLabel}
                            disabled={(field.disabled || readonly) && canDisableStock}
                            defaultValue={textVal}
                            key={keyPre+"_"+data[field.name]}
                            onBlur={(ev) => self.valChange(itway+"/"+field.name, ev.target.value)}
                        />
                    </div>
                );
                break;
            case "tax_class":
                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <Select
                            open={false}
                            name={field.name}
                            options={taxClassOptions}
                            defaultValue={data[field.name]}
                            disabled={field.disabled || readonly}
                            key={keyPre+"_"+field.name}
                            onChange={(ev) => self.valChange(itway+"/"+field.name, ev.target.value)}
                        />
                    </div>
                );
                break;
            case "discount_select":
                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <Select
                            open={false}
                            name={field.name}
                            options={discountOptions}
                            defaultValue={discountVal}
                            disabled={field.disabled || readonly}
                            key={keyPre+"_"+field.name}
                            onChange={(ev) => self.valChange(itway+"/"+field.name, +ev.target.value)}
                        />
                    </div>
                );
            break;
            case "price":
                let val = 0;
                let customPriceClass = "";
                field.disabled = true;

                if (field.name == "list_price"){
                    val = sAction.parseNumber(data.list_price);
                    field.disabled = (data.no_template == "1" || data.no_template === undefined) ? false : true;
                    if(sAction.dataGet("conf/user/business_representative") === true){
                        field.disabled = false;
                    }
                }
                else if(field.name == "discount_price"){
                    let price = data.list_price;
                    if (data.price_after_defdisc) {
                        price = data.price_after_defdisc;
                    }
                    let discount = sAction.parseNumber(data.discount_amount);
                    // if(data.discount_select){
                    //     discount = (sAction.parseNumber(price)/100)*discount;
                    // }
                    discount = (sAction.parseNumber(price) / 100) * discount;
                    val = sAction.parseNumber(price) - discount;
                }
                else if(field.name == "total_price"){
                    let price = data.list_price;
                    if (data.price_after_defdisc){
                        price = data.price_after_defdisc;
                    }
                    let discount = sAction.parseNumber(data.discount_amount);
                    // if(data.discount_select){
                    //     discount = (sAction.parseNumber(price)/100)*discount;
                    // }
                    discount = (sAction.parseNumber(price) / 100) * discount;
                    val = (sAction.parseNumber(price) - discount)*data.quantity;
                }
                else if (field.name == "price_after_defdisc"){
                    let list_price = data.list_price;

                    if (data.price_after_defdisc){
                        val = data.price_after_defdisc;
                    } else {
                        val = sAction.parseNumber(list_price);
                    }
                
                    if(sAction.dataGet("conf/user/business_representative") === true){
                        field.disabled = false;
                    }
                }
                else if (field.name == "discount_amount"){
                    if (self.state.userDiscount && sAction.parseNumber(data[field.name]) > self.state.userDiscount){
                        customPriceClass = "highlight_red";
                        val = sAction.parseNumber(data[field.name]);

                    } else {
                        val = sAction.parseNumber(data[field.name]);
                    }        
                    field.disabled = false;          
                }
                else{
                    val = sAction.parseNumber(data[field.name]);
                }

                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                    <TextField
                        className={"priceInput quoteProductField " + customPriceClass}
                        margin="normal"
                        variant="outlined"
                        id={field.name}
                        type="text"
                        key={keyPre+"_"+field.name}
                        autoComplete="of"
                        disabled={field.disabled || readonly}
                        defaultValue={sAction.formatNumber(val)}
                        onBlur={(ev) => self.valChange(itway+"/"+field.name, sAction.parseNumber(ev.target.value))}
                    />
                    </div>
                );
                break;
            case "checkbox":
                renderComp.push(
                    <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                        <CheckBox
                            name={field.name}
                            defaultValue={data[field.name]}
                            disabled={field.disabled || readonly}
                            onChange={(value) => self.valChange(itway+"/"+field.name, +value)}
                        />
                    </div>
                );
                break;
        }

    });

    var descComp = null;
    if(data.descFocused){
        descComp = <>
            <Image picture={data.picture} onChange={this.uploadImage} way={itway} />
            <TextField
        className={descClass}
        multiline={true}
        type="text"
        margin="normal"
        variant="outlined"
        rows={10}
        key={keyPre + "_description"}
        onBlur={(ev) => this.blurDescription(itway+"/description", ev.target.value)}
        defaultValue={data.description}
        autoFocus={true}
    />
        </>
    }
    else{
        descComp = <div>{data.description}</div>
    }
    var prodlinesIcons = <div className="qProductLineButtonContainer">
            <div className="prodLineIcon"/>
            <div className="prodLineIcon"/>
    </div>;

    if(!readonly){
        prodlinesIcons = <div className="qProductLineButtonContainer">
        <div className="icon-deleteIcon" onClick={() => {sAction.updateDetailCustom("delItem", {way: way, index: ind}); 
            this.checkStock.checkProductAvailability(); 
        }}/>
        <div className="icon-editDesc" onClick={() => this.setDescription(ind)}/>
        </div>;
    }

        return (
            <Draggable draggableId={`${this.props.groupIndex}-${ind}`} index={ind}>
                {provided => (<div {...provided.draggableProps} ref={provided.innerRef}>
                        <div className="qProductLine">
                            {!readonly ? <div className="qProductLineButtonContainer">
                                <div className="icon-Sortable draggable hoverBlue" {...provided.dragHandleProps}
                                     onMouseDown={() => document.activeElement.blur()}/>
                            </div> : null }
                <div className="qProductLineButtonContainer">
                    <div
                      onClick={() => sAction.rightPanelDetail("Products", data.id)}
                      className={data.id || data.dragged ? "icon-eye hoverBlue" : ""}
                      style={{margin: "auto", "marginLeft": "2px", "width": "19px", fontSize: 14, visibility: !isRightPanel ? 'visible' : 'hidden'}}>
                    </div>
                    {data.picture &&
                    <div
                      onClick={() => sAction.popup("image",{url:sAction.param.files+"image/"+data.picture})}
                      className="icon-preview hoverBlue"
                      style={{margin: "auto", "marginLeft": "2px", "width": "19px", fontSize: 13}}>
                    </div>}
                </div>
                            {prodlinesIcons}
                            {renderComp}
                        </div>
                        <div className="quoteDescContainer">
                            {descComp}
                        </div>
                    </div>
                )}
            </Draggable>);
    }
}

export default QProduct