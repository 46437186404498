
export default function relateMoreDetailConfig(){

    // zobrazit vic informaci v naseptavaci, listview a vyhledavni na relate (modul: [fields])
    // zatím bude kompletně fungovat jenom pro Accounts, kvůli listView (backend)
    const moreDetail = {
        Accounts: ["shipping_address_city", "shipping_address_street"]
    }

    return moreDetail;
}