import detailDefault from "../../detailDefault";

export default class detailUsers extends detailDefault {
  load(sAction, data) {
    const userIsAdmin = sAction.dataGet('conf/user/is_admin')
    if (userIsAdmin){
      sAction.dataSet(`${data.prefix}/fields/additional_discount/def/readonly`, false)
      sAction.dataSet(`${data.prefix}/fields/add_discount_enable/def/readonly`, false)
    }

    console.log(sAction.dataGet(`${data.prefix}/fields/add_discount_enable/value`))
    
    if (sAction.dataGet(`${data.prefix}/fields/add_discount_enable/value`) == 1){
      sAction.dataSet(`${data.prefix}/fields/additional_discount/def/visible`, true)
    } else {
      sAction.dataSet(`${data.prefix}/fields/additional_discount/def/visible`, false)
    }

  }

  update(sAction, data) {
    switch (data.field) {
      case "user_name":
        let sendData = {
          id: sAction.dataGet(data.prefix + "/id"),
          user_name: data.value
        };
        sAction.rest.post("checkUsername", sendData, (returnData) => {
          if (returnData && returnData.found) {
            let text = sAction.translate("ERR_USER_NAME_EXISTS_1", "Users") + " '" + data.value + "' " + sAction.translate("ERR_USER_NAME_EXISTS_2", "Users");
            sAction.error(text);
            sAction.saveField({
              name: "user_name",
              value: "",
              way: data.prefix + "/fields/user_name"
            });
          }
        });
        break;
      case "add_discount_enable":
        if (sAction.dataGet(`${data.prefix}/fields/add_discount_enable/value`) == 1) {
          sAction.dataSet(`${data.prefix}/fields/additional_discount/def/visible`, true)
        } else {
          sAction.dataSet(`${data.prefix}/fields/additional_discount/def/visible`, false)
        }
    }
  }
}
