export default {
    fields: [
        {
            name: "quantity",
            type: "number",
            lbl: "LBL_LIST_QUANTITY",
            lblMod: "Quotes",
            visible: true,
            disabled: false,
            width: 5.5
        },
        {
            name: "name",
            type: "relateProd",
            lbl: "LBL_LIST_PRODUCT_NAME",
            lblMod: "Quotes",
            visible: true,
            disabled: false,
            width: 22
        },
        // {
        //     name: "mft_part_num_int",
        //     type: "text",
        //     lbl: "LBL_MFT_PART_NUM_INT",
        //     lblMod : "ProductTemplates",
        //     visible: true,
        //     disabled: false,
        //     width: 7
        // },
        {
            name: "mft_part_num",
            type: "text",
            lbl: "LBL_LIST_LBL_MFT_PART_NUM_KOD",
            lblMod : "Products",
            visible: true,
            disabled: false,
            width: 7
        },
        // {
        //     name: "tax_class", 
        //     type: "tax_class",
        //     lbl: "LBL_LIST_TAXCLASS",
        //     lblMod : "Quotes",
        //     visible: true,
        //     disabled: false,
        //     width: 8
        // },
        // {
        //     name: "cost_price",
        //     type: "price",
        //     lbl: "LBL_LIST_COST_PRICE",
        //     lblMod : "Quotes",
        //     visible: true,
        //     disabled: false,
        //     width: 9
        // },
        {
            name: "list_price",
            type: "price",
            lbl: "LBL_LIST_LIST_PRICE",
            lblMod: "Quotes",
            visible: true,
            disabled: false,
            width: 12
        },
        {
            name: "price_after_defdisc",
            type: "price",
            lbl: "LBL_LIST_PRICE_AFTER_DISCOUNT",
            lblMod: "Quotes",
            visible: true,
            disabled: true,
            width: 12
        },
        {
            name: "discount_amount",
            type: "price",
            lbl: "LBL_LIST_DEAL_TOT_PERC",
            lblMod: "Quotes",
            visible: true,
            disabled: false,
            width: 11
        },
        // {
        //     name: "discount_select",
        //     type: "discount_select",
        //     lbl: "",
        //     lblMod: "Products",
        //     visible: true,
        //     disabled: false,
        //     width: 5
        // },
        {   // cena za MJ
            name: "discount_price",
            type: "price",
            lbl: "LBL_LIST_DISCOUNT_PRICE",
            lblMod: "Quotes",
            visible: true,
            disabled: true,
            width: 10
        },
        {
            name: "total_price",
            type: "price",
            lbl: "LBL_LIST_TOTAL_PRICE",
            lblMod: "Quotes",
            visible: true,
            disabled: true,
            width: 12
        },
        {
            name: "delivery_date",
            type: "text",
            lbl: "LBL_DELIVERY_DATE",
            lblMod: "Products",
            visible: true,
            disabled: false,
            width: 9
        },

        // TODO + Dostupnost 
        {
            name: "product_availability",
            type: "text",
            lbl: "LBL_LIST_AVAILABILITY",
            lblMod: "Quotes",
            visible: true,
            disabled: true,
            width: 8
        },

        {
            name: "stock",
            type: "checkbox",
            lbl: "LBL_LIST_OPCE",
            lblMod: "Quotes",
            visible: false,
            disabled: false,
            width: 1
        }
    ],
    sums: [
        {
            type: "addBtn",
            visible: true,
            width: "21%",
        },
        {
            type: "space",
            visible: true,
            width: "40%",
        },
        {
            name: "total_list_price",
            type: "price",
            lbl: "LBL_SUBTOTAL",
            lblMod: "Quotes",
            visible: true,
            width: "12%"
        },
        {
            type: "space",
            visible: true,
            width: "14%",
        },
        {
            name: "total_discount_amount",
            type: "price",
            lbl: "LBL_LIST_DEAL_TOT",
            lblMod: "Quotes",
            visible: true,
            width: "11%"
        },
        {
            type: "space",
            visible: true,
            width: "22%",
        },
        {
            name: "total_discount_price",
            type: "price",
            lbl: "LBL_NEW_SUB",
            lblMod: "Quotes",
            visible: true,
            width: "12%"
        },
        {
            type: "space",
            visible: true,
            width: "6%",
        },
    ]
};
