export default function parseDefaultFilter(defaultFilter, addCustomFilter = null){
    // prevede default filter na filter format pro backed 
    // + přidá custom filter addCustomFilter ve filter formatu pro backe
    let actDefaultFilter = {
        operator: 'and',
        operands: [],
    };

    if(defaultFilter){
        console.log(defaultFilter)

        // quick fix for custom eleman filters with orgroups for other cases use coripo master filtering
        // NOTE: could by simplified and merged, but it works 
        // (coripo filtering) mainly because of opportunity close popup! (defaultFilter.subpanels....)
        if (defaultFilter?.filter) {
            // Added code for orGroup filtering - old from eleman
            let withOrGroup = defaultFilter.filter(item => item.hasOwnProperty('orGroup'))
            let withoutOrGroup = defaultFilter.filter(item => !item.hasOwnProperty('orGroup'))
            withOrGroup.sort((a, b) => a.orGroup - b.orGroup)

            // group orGroups by id into orGroups
            let lastGroupId = null;
            let groupArray = [];
            let logicGroups = [];
            withOrGroup.forEach(item => {
                if(lastGroupId === item.orGroup){
                    groupArray.push(item);
                } else {
                    if(groupArray.length) logicGroups.push({type: "or", data: groupArray});
                    groupArray = [item];
                    lastGroupId = item.orGroup;
                }
            })
            if(groupArray.length) logicGroups.push({type: "or", data: groupArray});
            logicGroups.push({type: "and", data: withoutOrGroup});

            logicGroups.forEach(group => {
            let logicFilter = {
                operator: group.type,
                operands: [],
            }
            if(withOrGroup.length === 0){
                logicFilter = actDefaultFilter;
            }

            group.data.forEachObject(e => {
                const filter = e;
                if(filter.operandType == "relate" && filter.parentId){
                    const operand = {
                        module:filter.module,
                        type:"relate",
                        relationship:filter.relationship,
                        name:filter.name,
                        filter:{
                            operator:"and",
                            operands:[
                                {
                                    field:"id", 
                                    type:filter.type, 
                                    value:filter.parentId
                                }
                            ]
                        }
                    }
                    logicFilter.operands.push(operand);
                } else if(filter.operandType != "relate"){
                    try{
                        const operand = {
                            field:filter.get("field"),
                            type:filter.get("type"),
                            value:filter.get("value"),
                        }
                        logicFilter.operands.push(operand);
                    } catch(error){
                        const operand = {
                            field:filter.field,
                            type:filter.type,
                            value:filter.value,
                        }
                        logicFilter.operands.push(operand);
                    }
                }
            })
                if(withOrGroup.length === 0){
                    actDefaultFilter = logicFilter;
                } else {
                    actDefaultFilter.operands.push(logicFilter);
                }
            })
        } else {

            // simple filtering from coripo master
            defaultFilter.forEachObject((e) => {
                const filter = e;
                if (filter.operandType == 'relate' && filter.parentId) {
                    const operand = {
                        module: filter.module,
                        type: 'relate',
                        relationship: filter.relationship,
                        name: filter.name,
                        filter: {
                            operator: 'and',
                            operands: [
                                {field: 'id', type: filter.type, value: filter.parentId},
                            ],
                        },
                    };
                    actDefaultFilter.operands.push(operand);
                } else if (filter.operandType != 'relate') {
                    try {
                        const operand = {
                            field: filter.get('field'),
                            type: filter.get('type'),
                            value: filter.get('value'),
                        };
                        actDefaultFilter.operands.push(operand);
                    } catch (error) {
                        const operand = {
                            field: filter.field,
                            type: filter.type,
                            value: filter.value,
                        };
                        actDefaultFilter.operands.push(operand);
                    }
                }
            });
        }
    }

    if (addCustomFilter){
        actDefaultFilter.operands.push(addCustomFilter);
    }

    return actDefaultFilter;
} 