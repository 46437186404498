export default function getGpsAddress (data, saveRecord = null){
  const sendData = {
    street: this.dataGet(data.prefix + "/fields/shipping_address_street/value"),
    city: this.dataGet(data.prefix + "/fields/shipping_address_city/value"),
    state: this.dataGet(data.prefix + "/fields/shipping_address_state/value"),
    postalcode: this.dataGet(data.prefix + "/fields/shipping_address_postalcode/value"),
    country: this.dataGet(data.prefix + "/fields/shipping_address_country/value"),
  };
  this.rest.post("getGpsAddress", sendData, (returnData) => {
    if (!returnData.status) {
      console.log("Error: " + returnData.errorMessage.text);
      if (returnData.errorMessage.text.length > 0) {
        this.error(returnData.errorMessage.text);
      }
      return;
    }
    let gpsData = returnData.message.data[0];

    if(gpsData){
      let gpsDataItemObject = {
        gpsX: gpsData.acm_address_gpsx,
        gpsY: gpsData.acm_address_gpsy,
        street: gpsData.acm_address,
        obec: gpsData.acm_address_obec,
        okres: gpsData.acm_address_okres,
        kraj: gpsData.acm_address_kraj,
        cast_obce: gpsData.acm_address_cast_obce,
        psc: gpsData.acm_address_psc,
      }

      this.dsClear()
      this.dsAdd("set", data.prefix + "/fields/acm_address/def/item", gpsDataItemObject);

      this.dsAdd("set", data.prefix + "/fields/acm_address/value", gpsData.acm_address);
      this.dsAdd("set", data.prefix + "/fields/acm_address_street/value", gpsData.acm_address);
      this.dsAdd("set", data.prefix + "/fields/acm_address_kraj/value", gpsData.acm_address_kraj);
      this.dsAdd("set", data.prefix + "/fields/acm_address_psc/value", gpsData.acm_address_psc);
      this.dsAdd("set", data.prefix + "/fields/acm_address_okres/value", gpsData.acm_address_okres);
      this.dsAdd("set", data.prefix + "/fields/acm_address_obec/value", gpsData.acm_address_obec);
      this.dsAdd("set", data.prefix + "/fields/acm_address_cast_obce/value", gpsData.acm_address_cast_obce);
      this.dsAdd("set", data.prefix + "/fields/acm_address_gpsy/value", gpsData.acm_address_gpsy);
      this.dsAdd("set", data.prefix + "/fields/acm_address_gpsx/value", gpsData.acm_address_gpsx);

      this.dsAdd("set", data.prefix + "/changes/fields/acm_address", gpsData.acm_address);
      this.dsAdd("set", data.prefix + "/changes/fields/acm_address_street", gpsData.acm_address);
      this.dsAdd("set", data.prefix + "/changes/fields/acm_address_kraj", gpsData.acm_address_kraj);
      this.dsAdd("set", data.prefix + "/changes/fields/acm_address_psc", gpsData.acm_address_psc);
      this.dsAdd("set", data.prefix + "/changes/fields/acm_address_okres", gpsData.acm_address_okres);
      this.dsAdd("set", data.prefix + "/changes/fields/acm_address_obec", gpsData.acm_address_obec);
      this.dsAdd("set", data.prefix + "/changes/fields/acm_address_cast_obce", gpsData.acm_address_cast_obce);
      this.dsAdd("set", data.prefix + "/changes/fields/acm_address_gpsy", gpsData.acm_address_gpsy);
      this.dsAdd("set", data.prefix + "/changes/fields/acm_address_gpsx", gpsData.acm_address_gpsx);
      this.dsProcess();
    }

    if (saveRecord) saveRecord(data);

  }, false);

}
