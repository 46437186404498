import sAction from "sAction"
// relate more detaile config for search
import relateMoreDetailConfig from '../../_custom/config/relateMoreDetailConfig';

export default function processFilterRelMoreDetail(filterData, prefix) {
    if (! filterData) {
        return filterData;
    }
    filterData = filterData.toJS();
    const relateDetailConfig = relateMoreDetailConfig();
    const rows = this.dataGet(prefix + '/rows');

    if (! rows) {
        return null;
    }
    let relateFields = [];

    rows.forEach(row => {
        if (row) {
            row = row.toJS();
        }
        Object.keys(relateDetailConfig).forEach((relModule) => {
            if ((row.def.module === relModule && row.def.type === "relate") || row.def.name === "parent_name") {
                relateFields.push({name: row.def.name, link: row.def.link})
            }
        })
    })

    let isRelateFilter = false;
    relateFields.forEach((relateField) => {
        if (filterData.field === relateField.name) {
            let link = relateField.link;
            // vyjmka pro vazbu na nabidkach, padala querygille na nazvu vazby
            if (link === 'billing_accounts') 
                link = 'accounts';
            

            filterData.relLink = link;
            filterData.moreDetail = true;
            isRelateFilter = true;
        }
    })

    if (isRelateFilter) {
        filterData = processFilter(filterData, prefix);
    }

    return filterData;
}

function processFilter(filterData, prefix) {
    if (! filterData) 
        return null;
    
    const relateDetailConfig = relateMoreDetailConfig();
    let actFilter = {
        "operator": "and",
        "operands": [],
        filterFormat: "filter"
    }
    let actOperand = {
        "operator": "or",
        "operands": []
    }

    if (filterData.fieldType === "relate" || filterData.fieldType === "parent") {
        
        // accounts address search -> shipping_address_city, shipping_address_street
        let accountsRelateFields = findAccountRelateField(prefix)
        if (accountsRelateFields && accountsRelateFields?.name) {
            if(accountsRelateFields.name === "parent_name"){

                Object.keys(relateDetailConfig).forEach((module) => {
                    let fields = relateDetailConfig[module];
                    fields.forEach(field => {
                        actOperand.operands.push({
                            "field": "parent_name",
                            "type": "relate",
                            "value": filterData.value,
                            "parent_type": module,
                            "relField": field,
                            "relType": "cont",
                        })
                    })
                })
        
                actOperand.operands.push({
                    "field": filterData.field, 
                    "type": "cont", 
                    "value": filterData.value
                })

            } else {

                Object.keys(relateDetailConfig).forEach((module) => {
                    let fields = relateDetailConfig[module];
                    fields.forEach(field => {
                        actOperand.operands.push({
                            "module": module,
                            "type": "relate",
                            "name": filterData.field,
                            "relationship": [
                                filterData.relLink,
                            ],
                            "filter": {
                                "operator": "and",
                                "operands": [
                                    {
                                        "field": field,
                                        "type": "cont",
                                        "value": filterData.value
                                    }
                                ]
                            }
                        })
                    })
                })
        
                actOperand.operands.push({
                    "field": filterData.field, 
                    "type": "cont", 
                    "value": filterData.value
                })

            }
        } 

    } else {
        actOperand = {
            "field": filterData.field,
            "type": "cont",
            "value": filterData.value
        }
    } actFilter.operands.push(actOperand)
    return actFilter;
}


const findAccountRelateField = (prefix) => {
    const relateDetailConfig = relateMoreDetailConfig();
    const rows = sAction.dataGet(prefix + '/rows');
    let relateFields = [];

    rows.forEach(row => {
        if (row) {
            row = row.toJS();
        }
        Object.keys(relateDetailConfig).forEach((relModule) => {
            if ((row.def.module === relModule && row.def.type === "relate") || row.def.name === "parent_name") {
                relateFields.push({name: row.def.name, link: row.def.link})
            }
        })
    })

    let out = relateFields?.length > 0 ? relateFields[0] : null
    // vyjmka pro vazbu na nabidkach, padala querygille na nazvu vazby
    if(out?.link === 'billing_accounts') out.link = 'accounts'
    return out;
}
