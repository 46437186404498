export default function updateQProducts(groupsData, type, ways, accountId = null, as_company = null) {
    this.load();
    const getFields = ['id', 'mj', 'name', 'cost_price', 'list_price', 'discount_price', 'currency_id', 'description', 'product_type', 'percents', 'inherit', 'picture', 'category_id', 'price_after_defdisc', 'qty_in_stock', 'no_template', 'actual_product_template_id', 'mft_part_num', 'manual_price_after_defdisc'];
    const data = {
        getFields: getFields,
        accountId: accountId,
        groups: groupsData,
        as_company: as_company,
    };
    this.rest.post('updateQProducts', data, returnData => {
        if (returnData['status']) {
            var groups = returnData['products'];

            this.dataSet("view/customData/productData/defaultDiscount", parseFloat(returnData['defaultDiscount']));

            groups.forEach((lines, i) => {     
                const prefix = ways[i].split("/")[0];
                const currency_id = this.dataGet(prefix + "/fields/currency_id/value");
                const fullWay = ways[i] + "/";

                for(let i = 0; i < lines.length; i++){
                    let line = lines[i];

                    // toto delalo problem s ulozenim pokazedem ulozeni se prevedla mena znovu az do 0,-
                    // line.cost_price = line.cost_price, line.currency_id, currency_id/*, convRate*/);
                    // line.list_price = line.list_price, line.currency_id, currency_id/*, convRate*/);
                    // line.discount_amount = line.discount_amount, line.currency_id, currency_id/*, convRate*/);
                    // line.price_after_defdisc = line.price_after_defdisc, line.currency_id, currency_id/*, convRate*/);
                    line.cost_price = line.cost_price
                    line.list_price = line.list_price
                    line.discount_amount = line.discount_amount
                    line.price_after_defdisc = line.price_after_defdisc
            
                    this.updateDetailCustom(type, { way: fullWay + i, item: line, template: true });
                }
            });
           
        }

        this.unLoad();
    });
}
