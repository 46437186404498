
import detailDefault from "../../detailDefault";
import QProductStock from "../../../components/detailView/Quotes/QProductStock";
import moment from "moment";
export default class detailQuotes extends detailDefault{
    load(sAction,data) {
        let parentAccountId = sAction.dataGet(data.prefix + "/fields/billing_account_id/value");
        if(!parentAccountId){
            parentAccountId = sAction.dataGet(data.prefix + "/fields/billing_account_name/def/id_value");
        }
        let id = sAction.dataGet("view/id")
        let asCompany = sAction.dataGet("view/fields/as_company/value")
        let module = "Quotes"
        sAction.dsClear();
        sAction.dsAdd('set', data.prefix + '/fields/shipping_contact_name/def/defaultFilter/0/parentId', parentAccountId ? parentAccountId : ' ');
        sAction.dsAdd('set', 'view/customData/productTree', {
            callerId: id,
            asCompany: asCompany,
            callerModule: module,
        });
        const cancellationReason = sAction.dataGet(data.prefix + "/fields/cancellation_reason/value");
        if(cancellationReason && cancellationReason.length > 0){
            sAction.dsAdd("set", data.prefix + "/fields/cancellation_reason/def/visible", true);
        } else {
            sAction.dsAdd("set", data.prefix + "/fields/cancellation_reason/def/visible", false);
        }
        this.updateQProductsDiscount(sAction, data);
        sAction.dsAdd("set", data.prefix + "/fields/description/def/readonly", false);
        sAction.dsProcess();
        this.updateQProductsDefaultFilter(sAction, data);
        this.setDefaultValues(sAction, data);

        const billingAccountId = sAction.dataGet(data.prefix + "/fields/billing_account_id/value");
        const billingAccountIdValue = sAction.dataGet(data.prefix + "/fields/billing_account_name/def/id_value");
        if(this.isNewVersion(sAction) && (billingAccountId || billingAccountIdValue)){
            // id is new version disable changing account
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/billing_account_id/def/readonly', true);
            sAction.dsAdd('set', data.prefix + '/fields/billing_account_name/def/readonly', true);
            sAction.dsProcess();
        }

        this.updateOzAssignedTo(sAction, data);
    }
    update(sAction,data) {
        switch (data.field) {
            case "danova_sazba":
                sAction.dataSet(data.prefix + "/customData/productData/dph", data.value);
                break;
            case "currency_id":
                this.changeCurrencyValues(sAction, data);
                break;
            case "billing_account_id":
                sAction.dsClear();
                sAction.dsAdd('set', data.prefix + '/fields/shipping_contact_name/def/defaultFilter/0/parentId', data.value.id ? data.value.id : ' ');
                this.updateQProductsDiscount(sAction, data);
                sAction.dsProcess();
                this.updateCompanyDiscount(sAction, data);
                this.updateOzAssignedTo(sAction, data);
                break;
            case "kurz":

                const currency_id = sAction.dataGet(data.prefix+"/fields/currency_id/value");
                if (currency_id && currency_id !== "-99") {
                    // kdyz se meni kurz v eurech napr. z 25 Kc na 20 Kc - nabidka se prepocita dle defaultni meny
                    sAction.dataSet(data.prefix+"/fields/kurz/value", data.prevValue);

                    // this.changeCurrencyValues(sAction, {prefix: data.prefix, value: "-99"});
                    sAction.saveField({
                        way: data.prefix + "/fields/currency_id",
                        name: "currency_id",
                        value: "-99"
                    }, true);

                    sAction.dataSet(data.prefix+"/fields/kurz/value", data.value);

                    // this.changeCurrencyValues(sAction, {prefix: data.prefix, value: currency_id});
                    sAction.saveField({
                        way: data.prefix + "/fields/currency_id",
                        name: "currency_id",
                        value: currency_id
                    }, true);
                }

                break;
            case "cenotvorba_discount":
            case "cenotvorba_discount_select":
                sAction.cenotvorbaChange("quote", {way: data.prefix, value: data.value, field:data.field});
                break;
            case "as_company":
                this.updateCompanyDiscount(sAction, data);
                this.updateQProductsDefaultFilter(sAction, data);
                
                let id = sAction.dataGet("view/id")
                let asCompany = sAction.dataGet("view/fields/as_company/value")
                let module = "Quotes"
                sAction.dsClear();
                sAction.dsAdd('set', 'view/customData/productTree', {
                    callerId: id,
                    asCompany: asCompany,
                    callerModule: module,
                });
                sAction.dsProcess();
                this.removeProducts(sAction, data)
                break;
            case "manual_discount":
                sAction.cenotvorbaChange("quote", {way: data.prefix, value: data.value, field: "cenotvorba_discount"});
                break;
        }
    }

    setDefaultValues(sAction, data){
        let id = sAction.dataGet(`${data.prefix}/id`);
        if (!id){
            let from = moment().format("YYYY-MM-DD");
            let to = moment().add(21, 'days').format("YYYY-MM-DD");
            sAction.dsClear();
            sAction.dsAdd('set', `${data.prefix}/fields/date_quote_from/value`, from);
            sAction.dsAdd('set', `${data.prefix}/changes/fields/date_quote_from`, from);

            sAction.dsAdd('set', `${data.prefix}/fields/date_quote_expected_closed/value`, to);
            sAction.dsAdd('set', `${data.prefix}/changes/fields/date_quote_expected_closed`, to);
            sAction.dsProcess();
        }
    }
    removeProducts(sAction, data){
        if (!this.checkProducts(sAction, data).hasProducts){
            return
        }
        let newValue = data.value

        sAction.dsClear();
        sAction.dsAdd('set', `${data.prefix}/fields/as_company/value`, data.prevValue);
        sAction.dsAdd('set', `${data.prefix}/changes/fields/as_company`, data.prevValue);
        sAction.dsProcess();

        sAction.confrim("Při změně firmy se <b>odstraní</b> všechny vybrané produkty. <br>Přejete si pokračovat?", () => {
            sAction.popupHide();

            let groups = sAction.dataGet(`view/customData/productData/groups`);
            if (groups) groups = groups.toJS();

            sAction.dsClear();
            groups.forEachObject((value, key) => {
                sAction.dsAdd('set', `view/customData/productData/groups/${key}/lines`, []);
            });

            sAction.dsAdd('set', `${data.prefix}/fields/as_company/value`, newValue);
            sAction.dsAdd('set', `${data.prefix}/changes/fields/as_company`, newValue);
            sAction.dsProcess();

        }, () => {
            sAction.popupHide();
        }, "high");

    }

    updateQProductsDefaultFilter(sAction, data){
        let getAsCompany = sAction.dataGet(data.prefix + "/fields/as_company/value")
        let prodDefaultFilter = null;
        if (getAsCompany === "eleman") {
            prodDefaultFilter = [
                {
                    module: "ProductTemplates",
                    field: "as_company",
                    value: "eleman",
                    type: "eq",
                },
            ];
        } else if (getAsCompany === "famatel") {
            prodDefaultFilter = [
                {
                    module: "ProductTemplates",
                    field: "as_company",
                    value: "famatel",
                    type: "eq",
                },
            ];
        } else {
            prodDefaultFilter = null;
        }

        sAction.dsClear();
        sAction.dsAdd('set', 'view/customData/productData/defaultFilter', prodDefaultFilter);

        let productData = sAction.dataGet(data.prefix + "/customData/productData/groups")

        productData.forEach((group, gr_index) => {
            group.lines.forEach((line, ln_index) => {
                sAction.dsAdd('set', `view/customData/productData/groups/${gr_index}/lines/${ln_index}/defaultFilter`, prodDefaultFilter);
            })
        })
        sAction.dsProcess();
    }

    updateQProductsDiscount(sAction, data) {
        let accountId = sAction.dataGet(data.prefix + "/fields/billing_account_name/def/id_value");
        let as_company = sAction.dataGet(data.prefix + "/fields/as_company/value");
        const groups = sAction.dataGet(data.prefix + "/customData/productData/groups");

        let ways = [];
        let groupsData = [];
        let group_i = 0;
        for (let group of groups) {
            groupsData.push(sAction.dataGet("view/customData/productData/groups/" + group_i + "/lines"));
            ways.push('view/customData/productData/groups/' + group_i + '/lines');
            group_i++;
        }

        sAction.updateQProducts(groupsData, 'prodDiscount', ways, accountId, as_company);
    }

    updateCompanyDiscount(sAction, data) {
        const as_company = sAction.dataGet(data.prefix + "/fields/as_company/value")
        let searchFor = "discount_e"
        if (as_company == "famatel") searchFor = "discount_f"
        let accountId = sAction.dataGet(data.prefix + "/fields/billing_account_name/def/id_value");
        if(!accountId) accountId = data.value.id;
        const searchData = {
            module: 'Accounts',
            fields: ["id"],
            getFields: [searchFor, "id"],
            value: accountId,
            limit: 1,
        };
        if (searchData.value){
            sAction.quickSearch(searchData, data => {
                if (Array.isArray(data) && data.length >= 1) {
                    let companyDiscount = parseFloat(data[0][searchFor]);

                    if (parseFloat(sAction.dataGet("view/fields/default_discount/value")) !== companyDiscount && !isNaN(companyDiscount)) {
                        sAction.dsClear();
                        sAction.dsAdd('set', 'view/fields/default_discount/value', companyDiscount);
                        sAction.dsAdd('set', 'view/changes/fields/default_discount', companyDiscount);
                        sAction.dsProcess();
                    }
                }
            });
        }
    }

    changeCurrencyValues(sAction, data) {
        // prepocitej produkty v nabidce podle zadaneho kurzu...

        if (!data.value && data.prevValue === "-99" || data.value === "-99" && !data.prevValue) {
            return;
        }

        if (!data.value) {
            data.value = "-99";
        }

        let kurz = 0;
        let kurz_field_value = sAction.dataGet(data.prefix + "/fields/kurz/value");
        if (kurz_field_value && parseFloat(kurz_field_value)) {
            kurz = kurz_field_value;
        }

        sAction.dataSet(data.prefix+"/customData/productData/currency_id", data.value);

        const groups = sAction.dataGet(data.prefix + "/customData/productData/groups");
        sAction.dsClear();
        let group_i = 0;
        for (let group of groups) {
            let line_i = 0;
            for (let line of group.lines) {

                let list_price = sAction.currencyConvert(line.list_price, data.prevValue, data.value, kurz);
                let cost_price = sAction.currencyConvert(line.cost_price, data.prevValue, data.value, kurz);
                let discount_amount = sAction.currencyConvert(line.discount_amount, data.prevValue, data.value, kurz);

                sAction.dsAdd("set", data.prefix + "/customData/productData/groups/" + group_i + "/lines/" + line_i + "/list_price", list_price);
                sAction.dsAdd("set", data.prefix + "/customData/productData/groups/" + group_i + "/lines/" + line_i + "/cost_price", cost_price);
                if (line.discount_select == 0) {
                    sAction.dsAdd("set", data.prefix + "/customData/productData/groups/" + group_i + "/lines/" + line_i + "/discount_amount", discount_amount);
                }
                line_i++;
            }
            group_i++;
        }
        sAction.dsProcess();
    }


    checkProducts(sAction, data){
        let groups = sAction.dataGet(data.prefix + '/customData/productData/groups');
        let userDiscountEnabled = sAction.dataGet('conf/user/add_discount_enable');
        let userDiscount = parseFloat(sAction.dataGet('conf/user/additional_discount'));

        if (groups) groups = groups.toJS();

        if (sAction.deviceType == "mobile") {
            return true;
        }

        let hasProducts = false;
        let allowSaveDiscount = true;
        groups.forEachObject((value, key) => {
            value['lines'].forEachObject((line, lKey) => {
                if (line['name']) {
                    hasProducts = true;
                }
                if (userDiscountEnabled == "1" && line['discount_amount'] > userDiscount) {
                    allowSaveDiscount = false;
                }
            });
        });

        return {
            hasProducts: hasProducts,
            allowSaveDiscount: allowSaveDiscount
        };
    }

    isNewVersion(sAction){
        let quoteNum = sAction.dataGet('view/fields/quote_num/value');
        if(!quoteNum || quoteNum.length <= 8) return false;
        quoteNum = quoteNum.split('-');

        if (quoteNum.length === 2 && quoteNum[0].length >= 8) {
            return true;
        }
        return false;
    }

    // Kontrola před uložením
    // Musí být vyplněn alespoň jeden produkt, nelze uložit nabídku bez produktu(textový, katalog)

    beforeSave(sAction, data) {
        const checkStock = new QProductStock();
        checkStock.checkProductAvailability();
        
        if (sAction.deviceType == "mobile") {
            return true;
        }

        let { hasProducts, allowSaveDiscount } = this.checkProducts(sAction, data);
        let allowSave = hasProducts;

        if (!allowSave) {
            sAction.error(sAction.translate('LBL_SAVE_PREVENTED_MISSING_PRODUCT'));
        }
        if (!allowSaveDiscount) {
            sAction.error(sAction.translate("LBL_INVALID_DISCOUNT_MSG", "Quotes"));
            allowSave = false;
        }

        return allowSave;
    }

    // pridat na hook on load a pokud se zmeni data tak prepsat a pridat do changes ... -> tlacitko vyvtorit nabidku z firmy ..  
    updateOzAssignedTo(sAction, data){
        // get assigned_user_id from account selected in billing_account_id
        let accountId = sAction.dataGet(data.prefix + "/fields/billing_account_name/def/id_value");
        if (!accountId && data?.value?.id) {
            accountId = data?.value?.id;
        }
        if (!accountId) {
            return;
        }
        const searchData = {
            module: 'Accounts',
            fields: ["id"],
            getFields: ["assigned_user_id", "id", "assigned_user_name"],
            value: accountId,
            limit: 1,
        };
        sAction.quickSearch(searchData, resp => {
            if (Array.isArray(resp) && resp.length >= 1) {
                let assignedTo = resp[0]["assigned_user_id"];
                let assignedToName = resp[0]["assigned_user_name"];
                if (assignedTo) {

                    // check if assigned user is different than current one
                    let currentAssignedTo = sAction.dataGet(data.prefix + "/fields/assigned_user_name/def/id_value");
                    if (currentAssignedTo && currentAssignedTo === assignedTo) {
                        return;
                    }

                    sAction.dsClear();
                    sAction.dsAdd('set', data.prefix + '/fields/assigned_user_id/value', assignedTo);
                    sAction.dsAdd('set', data.prefix + '/changes/fields/assigned_user_id', assignedTo);
                    sAction.dsAdd('set', data.prefix + '/fields/assigned_user_name/value', assignedToName);
                    sAction.dsAdd('set', data.prefix + '/changes/fields/assigned_user_name', assignedToName);
                    sAction.dsProcess();
                }
            }
        });
    }
}
